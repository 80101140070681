export default {
  geral: {
    app: 'Sponte',
    currency: 'R$',
    percent: '%',
    currency_code: 'BRL',
    date_mask: 'DD/MM/YYYY',
    date_format_describe: 'dd/mm/aaaa',
    time_mask: 'HH:mm:ss',
    time_format_describe: '0:00h',
    datetime_mask: '$t(geral:date_mask) $t(geral:time_mask)',
    currency_formatter: '$t(geral:currency) {{value, currency|BRL}}',
    percent_formatter: '{{value, percent|BRL}}',
    date_formatter: '{{date, date|DD/MM/YYYY}}',
    time_formatter: '{{date, date|HH:mm}}',
    time_with_seconds_formatter: '{{date, date|HH:mm:ss}}',
    datetime_formatter: '{{date, date|DD/MM/YYYY HH:mm:ss}}',
    numberRange_formatter: '{{start}} de {{end}}',
    atuaisNovas: 'Atuais e novas',
    apenasNovas: 'Apenas novas',
    numeroRecibo: 'Número do recibo',
    reabrir: 'Reabrir',
    Administradores: 'Administradores',
    FinanceiroMarketplace: 'Financeiro / Marketplace',
    contaBancariaOperacaoRealizada: 'Conta bancária da operação realizada',
    nenhumValorInformado: 'Nenhum valor informado',
    pagamentoCondicaoAlterada: 'Pagamento com condição alterada',
    salvar: 'Salvar',
    salvo: 'Salvo',
    sacado: 'Sacado',
    dataRepasse: 'Data de repasse',
    valorCreditado: 'Valor creditado',
    salvando: 'Salvando',
    salvarIncluir: 'Salvar e incluir',
    salvarLocalEstoquePadrao: 'Deseja realmente trocar o local de estoque padrão?',
    continuar: 'Continuar',
    cancelar: 'Cancelar',
    cancelarTituloBoleto: 'Título não pode ser cancelado. Forma de Pagamento Boleto',
    cancelarTituloSponte: 'Título não pode ser cancelado. Forma de Pagamento Sponte Pay',
    cancelarTituloSemMotivoData: 'Motivo e Data são obrigatório',
    cancelamento: 'Cancelamento',
    pesquisa: 'Pesquisa',
    situacaoTituloAlterada: 'Situação do Título Alterada',
    situacaoContratoAlterada: 'Situação do contrato alterada com sucesso!',
    clienteNumeroContrato: 'Cliente ou número do contrato',
    deletar: 'Deletar',
    situacaoContrato: 'Situação do contrato',
    situacaoAlteradaSucesso: 'Situacao alterada com sucesso!',
    visualizarMovimentacao: 'Visualizar movimentação',
    situacaoData: 'Situação / Data',
    condicaoPagamento: 'Condição de pagamento',
    configuracaoFiltragemRecibo: 'Configurações de filtragem de recibo',
    emitente: 'Emitente',
    tipoEmitente: 'Tipo de emitente',
    filtrarEmissaoRecibos: 'Filtrar emissão de recibos',
    gerarRecibo: 'Gerar recibo',
    formaPagamento: 'Forma de pagamento',
    responsavelCondicao: 'Responsável pala condição',
    verMovimentacao: 'ver movimentação',
    movimentacao: 'Movimentação',
    cancelarParcelas: 'cancelar parcelas',
    cancelarTituloSpontePay: 'Título não pode ser cancelado. Forma de Pagamento Sponte Pay',
    historicoAlteradoComSucesso: 'Histórico alterado com sucesso!',
    reativarPaciente: 'Deseja reativar o paciente?',
    reativarFuncionario: 'Deseja reativar o profissional de saúde?',
    remover: 'Remover',
    removerCadastro: 'Remover cadastro',
    removerPermanentemente: 'Remover permanentemente',
    desejaRemover: 'Deseja realmente remover?',
    desejaRemoverComAulasRelacionadas:
      'Se existirem aulas no diário relacionadas a esta configuração, elas serão deletadas. Deseja realmente remover?',
    desejaEstornar: 'Deseja realmente estornar?',
    estornar: 'Estornar',
    desejaGerarTitulos: 'Deseja realmente gerar títulos?',
    naoReceberSms: 'Não desejo receber SMS',
    naoReceberEmail: 'Não desejo receber e-mail',
    tentarNovamente: 'Tentar novamente',
    novo: 'Novo',
    nova: 'Nova',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    rg: 'RG',
    registro: 'Registro',
    registroGeral: 'Registro geral',
    certidaoDeNascimento: 'Certidão de nascimento',
    numeroRegistroCertidaoNascimento: 'Número do registro',
    cartorioRegistroCertidaoNascimento: 'Cartório de registro',
    livroRegistroCertidaoNascimento: 'Livro de registro',
    folhaRegistroCertidaoNascimento: 'Folha de registro',
    termoRegistroCertidaoNascimento: 'Termo de registro',
    dataRegistroCertidaoNascimento: 'Data de registro',
    carteiraTrabalho: 'Carteira de trabalho',
    nome: 'Nome',
    nomeAlunoResponsavel: 'Nome do aluno ou responsável',
    tipo: 'Tipo',
    nomeTipo: '$t(geral:nome) / $t(geral:tipo)',
    nomeCodigo: '$t(geral:nome) / $t(geral:codigo)',
    pessoa: 'Pessoa',
    sim: 'Sim',
    nao: 'Não',
    fisica: 'Física',
    juridica: 'Jurídica',
    pessoaFisica: 'Pessoa física',
    pessoaJuridica: 'Pessoa jurídica',
    nomeCompleto: 'Nome completo',
    razaoSocial: 'Razão social',
    nomeFantasia: 'Nome Fantasia',
    nomeDependente: 'Nome do dependente',
    aposAula: 'Após a aula',
    nomeAtividade: 'Nome da atividade',
    apelido: 'Apelido',
    baseNacionalComum: 'Base nacional comum',
    baseDiversificada: 'Base diversificada',
    itinerariosFormativos: 'Itinerários formativos',
    dataBase: 'Data base',
    dataFim: 'Data final',
    dataAbertura: 'Data de abertura',
    dataNascimento: 'Data de nascimento',
    dataLancamento: 'Data de lançamento',
    nomeCnpjCpf: '$t(geral:nome) / $t(geral:cnpj) ou $t(geral:cpf)',
    cpfCnpj: '$t(geral:cpf) / $t(geral:cnpj)',
    nomeMae: 'Nome da mãe',
    nomePai: 'Nome do pai',
    inscricaoMunicipal: 'Inscrição municipal',
    inscricaoEstadual: 'Inscrição estadual',
    regimeTributario: 'Regime tributário',
    emissor: 'Emissor',
    orgaoEmissor: 'Órgão emissor',
    dataEmissao: 'Data de emissão',
    dataEmissaoInicial: 'Data de emissão inicial',
    dataEmissaoFinal: 'Data de emissão final',
    dataSaidaInicial: 'Data de saída inicial',
    dataSaidaFinal: 'Data de saída final',
    dataCompra: 'Data da compra',
    dataOrcamento: 'Data do orçamento',
    dataSaida: 'Data de saída',
    cidadeNascimento: 'Cidade de nascimento',
    nacionalidade: 'Nacionalidade',
    tituloEleitor: 'Título de eleitor',
    zonaEleitoral: 'Zona eleitoral',
    secao: 'Seção',
    passaporte: 'Passaporte',
    dataEntrada: 'Data de entrada',
    pisPasep: 'PIS / PASEP',
    ctps: 'CTPS',
    uf: 'UF',
    sexo: 'Sexo',
    estadoCivil: 'Estado Civil',
    profissao: 'Profissão',
    renda: 'Renda',
    etnia: 'Etnia',
    turma: 'Turma',
    turmas: 'Turmas',
    escolaridade: 'Escolaridade',
    frequentaEscola: 'Frequenta a escola?',
    nomeResponsavel: 'Nome do responsável',
    primeiroAcesso: 'Primeiro acesso',
    tipoResponsavel: 'Tipo de responsável',
    tipoResponsavelServico: 'Tipo de responsável serviço',
    tipoResponsavelContaBancaria: 'Tipo de responsável por conta bancária',
    novoResponsavel: 'Novo responsável',
    editarResponsavel: 'Editar responsável',
    novoDependente: 'Novo dependente',
    editarDependente: 'Editar dependente',
    responsavel: 'Responsável',
    responsaveis: 'Responsáveis',
    responsaveisPeloProcedimento: 'Responsáveis pelo procedimento',
    parentesco: 'Parentesco',
    dependente: 'Dependente',
    dependentes: 'Dependentes',
    tipoDependente: 'Tipo do dependente',
    telefone: 'Telefone',
    email: 'E-mail',
    nomeEmail: '$t(geral:nome) / $t(geral:email)',
    emailAdicional: 'E-mail adicional',
    telefoneFixo: 'Telefone fixo',
    telefoneCelular: 'Telefone celular',
    telefoneAdicional: 'Telefone adicional',
    operadora: 'Operadora',
    telefoneEmail: '$t(geral:telefone) / $t(geral:email)',
    endereco: 'Endereço',
    celular: 'Celular',
    redeSocial: 'Rede Social',
    linkPerfil: 'Link do Perfil',
    meuPerfil: 'Meu perfil',
    link: 'Link',
    social: 'Social',
    perfil: 'Perfil',
    lead: 'Lead',
    cliente: 'Cliente',
    suspect: 'Suspect',
    prospect: 'Prospect',
    situacaoTitulo: 'Situação do título',
    situacao: 'Situação',
    ativo: 'Ativo',
    ativos: 'Ativos',
    inativo: 'Inativo',
    inativos: 'Inativos',
    bloqueado: 'Bloqueado',
    incompleto: 'Incompleto',
    outros: 'Outros',
    necessidadesEspeciais: 'Necessidades especiais',
    necessidade: 'Necessidade',
    autismo: 'Autismo',
    gestacao: 'Gestação',
    obesidade: 'Obesidade',
    idosidade: 'Idosidade',
    deficienciaVisual: 'Deficiência visual',
    deficienciaFisica: 'Deficiência física',
    porteCriancaPequena: 'Porte de criança pequena',
    senhaArquivo: 'Senha do arquivo',
    item: 'Item',
    quantidade: 'Quantidade',
    qtdeCartao: 'Qtde. cartão',
    qtdeBoleto: 'Qtde. boleto',
    ambienteEmissão: 'Ambiente de emissão',
    codigoRegimeTributario: 'Código do regime tributário',
    numeroSerieRps: 'N° série RPS',
    localPrestacaoServico: 'Local de prestação do serviço',
    situacaoIntegracao: 'Situação da integração',
    qntd: 'Qntd',
    subTotalItem: 'Subtotal do item',
    selecioneTipoCfop: 'Selecione um tipo de CFOP',
    itens: 'Itens',
    totalItens: 'Total dos itens',
    detalhes: 'Detalhes',
    notasParciais: 'Notas parciais',
    recuperacao: 'Recuperação',
    exameFinal: 'Exame final',
    modelosAvaliacao: 'Modelos de avaliação',
    nomeItem: 'Nome do item',
    itemQuantidade: 'Item / Quantidade',
    valorCondicao: 'Valor / Condição',
    valorAcrescimo: 'Valor do Acréscimo',
    valorMultaReal: 'R$ Multa',
    valorMulta: '% Multa',
    jurosMulta: 'Juros e Multa',
    juros: 'Juros',
    jurosDiarios: 'Juros diários',
    multaPorAtraso: 'Multa por atraso',
    multa: 'Multa',
    custos: 'Custos',
    siglaQuantidade: 'Qtd',
    valorUnitario: 'Valor unitário',
    subtotal: 'Subtotal',
    venda: 'Venda',
    custo: 'Custo',
    custoLabel: 'Tipo de custo',
    custoDigitado: 'Custo digitado',
    custoUltimaCompra: 'Custo da última compra',
    custoMedio: 'Custo médio',
    unidadeDeMedida: 'Unidade de Medida',
    unidadeDeMedidaPadrao: 'Unidade de medida padrão',
    unidadeTipo: 'Unidade / Tipo',
    finalidade: 'Finalidade',
    ncm: 'NCM',
    precificacao: 'Precificação',
    localRealizacao: 'Local de realização',
    cbo: 'CBO',
    cidade: 'Cidade',
    estado: 'Estado',
    pais: 'País',
    opEntrada: 'Operação de entrada',
    opSaida: 'Operação de saída',
    opEntradaSaida: 'Entrada e saída',
    descricao: 'Descrição',
    nomeDescricao: '$t(geral:nome) / $t(geral:descricao)',
    contaPrincipal: 'Conta Principal',
    codigo: 'Código',
    empresa: 'Empresa',
    numeroAbreviado: 'N°',
    numeroDeFiliais: '$t(geral:numeroAbreviado) de filiais',
    siteEnderecoDigital: 'Site / Endereço digital',
    complemento: 'Complemento',
    ruaAvenida: 'Rua / Avenida',
    cep: 'CEP',
    optanteSimplesNacional: 'Optante pelo Simples Nacional',
    naturalidade: 'Naturalidade',
    preco: 'Preço',
    precoPadrao: 'Preço padrão',
    precoSimplificado: 'Preço simplificado',
    novoPreco: 'Novo Preço',
    semConsumo: 'Sem consumo',
    categoria: 'Categoria',
    subcategorias: 'Subcategorias',
    precoCategoria: '$t(geral:preco) / $t(geral:categoria)',
    precoTabela: '$t(geral:preco) / $t(geral:tabela)',
    tags: 'Tags',
    vagas: 'Vagas',
    vaga: 'Vaga',
    bancoTipo: 'Banco / Tipo da conta',
    bancoConta: 'Banco / Conta',
    conta: 'Conta',
    agencia: 'Agência',
    contaAgencia: '$t(geral:conta) / $t(geral:agencia)',
    forma: 'Forma',
    operacao: 'Operação',
    operacao_plural: 'Operações',
    tipoOperacao: 'Tipo de operação',
    configuracoesSpontePay: 'Configurações Sponte Pay',
    configuracoesMedPay: 'Configurações MedPay',
    opcoesPagamento: 'Opções de Pagamento',
    canaisEnvioLink: 'Canais de Envio do Link',
    numMaximoParcelas: 'Nro. Máximo de Parcelas',
    acrescimoParcelamento: 'Acréscimo no Parcelamento',
    percentualAcrescimo: 'Percentual de Acréscimo',
    confirmarDadosClientePagamento: 'Confirmar dados do cliente no pagamento',
    tempoExpiracaoLinkAposVencimento: 'Tempo de expiração do link após o vencimento',
    diasEnvioAntesVencimento: 'Dias de envio antes do vencimento',
    medpay: 'Medpay',
    spontepay: 'Sponte pay',
    gerarLinkPagamento: 'Gerar link de pagamento',
    linkPagamentoGerado: 'Link de pagamento gerado',
    enviarLinkSms: 'Enviar link via SMS',
    enviarLinkEmail: 'Enviar link via Email',
    cancelarLink: 'Cancelar link',
    linkCopiadoAreaTransferencia: 'Link copiado para a área de transferência',
    textoCopiadoAreaTransferencia: 'Texto copiado para a área de transferência',
    desejaCancelarLinkPagamento: 'Deseja cancelar o link de pagamento?',
    linkCanceladoSucesso: 'Link cancelado com sucesso',
    linkGeradoSucesso: 'Link gerado com sucesso',
    linkEnviadoCanalPaciente: 'Link enviado para o {{canalEnvio}} do usuário',
    validade: 'Validade',
    dataValidade: 'Data de validade',
    condicoesPagamentoRecorrentes: 'Condições de pagamento recorrentes',
    alterarCartaoCredito: 'Alterar Cartão',
    assinarRecorrencia: 'Assinar recorrência',
    multiempresa: 'Situação',
    fornecedor: 'Fornecedor',
    fornecedores: 'Fornecedores',
    dadosBasicos: 'Dados Básicos',
    dadosParaIntegracao: 'Dados para integração',
    dadosCriacaoContaIntegracao: 'Dados para a criação da conta de Integração',
    dadosSeraoEnviadosViaApi: 'Os dados inseridos nessa seção serão enviados via API',
    liberacao: 'Liberação',
    liberacaoPorEmpresas: 'Liberação por empresas',
    documento: 'Documento',
    documentos: 'Documentos',
    criarDocumento: 'Criar documento',
    contato: 'Contato',
    parentesco1: 'Parentesco 1',
    parentesco2: 'Parentesco 2',
    cadastroClientes: 'Cadastro de clientes',
    tipoEvento: 'Tipo de evento',
    banners: 'Banners',
    financeiro: 'Financeiro',
    dadosFinanceiro: 'Dados Financeiros',
    taxasDadosFinanceiros: 'Taxas e Dados Financeiros',
    produtosIntegrados: 'Produtos integrados',
    integracaoConfigurada: 'Integração configurada!',
    prosseguimentoCliente: 'Agora é possível dar prosseguimento com o cliente',
    integrarCartao: 'Integrar cartão',
    integrarBoleto: 'Integrar boleto',
    comercial: 'Comercial',
    personalizado: 'Personalizado',
    acesso: 'Acesso',
    anexo: 'Anexo',
    anexos: 'Anexos',
    orientacoes: 'Orientações',
    dadosClinicos: 'Dados clinicos',
    historico: 'Histórico',
    historicoMovimentacoesComentarios: 'Histórico de movimentações e comentários',
    comentario: 'Comentário',
    dados: 'Dados',
    composicao: 'Composição',
    tributacao: 'Tributação',
    numeroNFSe: 'Nº NFS-e',
    rps: 'RPS',
    situacaoNF: 'Situação da NF',
    dataCompetencia: 'Data Competência',
    autorizados: 'Autorizados',
    aguardando: 'Aguardando',
    rejeitados: 'Rejeitados',
    cfop: 'CFOP',
    cnae: 'CNAE',
    iest: 'IEST',
    selecioneUmaCnae: 'Selecione uma Classificação Nacional de Atividades Econômicas',
    selecioneUmCliente: 'Selecione um cliente',
    aliquotas: 'Alíquotas',
    outrasAliquotas: 'Outras alíquotas',
    codigoFiscalDePrestacaoDeServicos: 'Código fiscal de prestação de serviços',
    tipoTributacao: 'Tipo de tributação',
    estoque: 'Estoque',
    sigla: 'Sigla',
    observacoes: 'Observações',
    sinteseSistemaAvaliacao: 'Sintese sistema avaliação',
    observacao: 'Observação',
    versao: 'Versão',
    tipoDaGuia: 'Tipo da Guia',
    numeroLote: 'Número Lote',
    quantidadeGuias: 'Quantidade de Guias',
    cadastradoEm: 'Cadastrado em',
    editadoEm: 'Editado em',
    salvoEm: 'Salvo em',
    editadoPor: 'Editado por',
    ultimaAlteracao: 'Última alteração do cadastro',
    anexadoPor: 'Anexado por',
    preenchido: 'Preenchido',
    armazenamento: 'Armazenamento',
    tamanho: 'Tamanho',
    hoje: 'Hoje',
    solteArquivos: 'Solte os seus arquivos aqui',
    escolhaArquivo: 'Escolha um arquivo',
    escolhaArquivoOuArraste: 'Escolha um arquivo ou arraste aqui',
    solteArquivosTextoSecundario: 'ou use o botão "$t(geral:escolhaArquivoOuArraste)"',
    solteImagemDeDestaque: 'Solte aqui a imagem de destaque',
    selecioneItem: 'Selecione um item',
    itemPrincipal: 'Item principal',
    rateio: 'Rateio',
    aplicativos: 'Aplicativos',
    centroAjuda: 'Centro de ajuda',
    sobre: 'Sobre',
    informacoes: 'Informações',
    informacoesAdicionais: 'Informações adicionais',
    informeContato: 'Informe o contato principal',
    tagsCadastro: 'Tags do cadastro',
    adicionarTags: 'Adicionar tags',
    adicionarDocumento: 'Adicionar documento',
    horarioInicial: 'Horário inicial',
    horarioFinal: 'Horário final',
    horarioInicio: 'Horário de início',
    horarioFim: 'Horário de encerramento',
    horarioContato: 'Melhor horário para contato',
    horarioInicioContato: 'Melhor horário para iniciar o contato',
    horarioTerminoContato: 'Melhor horário para encerrar contato',
    selecioneResponsavel: 'Selecione um responsável para vincular ao cadastro',
    selecioneDependente: 'Selecione um dependente para vincular ao cadastro',
    definaMultiempresa: 'Defina uma situação para este cadastro e em quais empresas serão exibidas',
    possuiNecessidadesEspeciais: 'Possui alguma necessidade especial?',
    custoAquisicao: 'Valor de compra',
    custoComposicao: 'Custo da composição',
    custoAdicional: 'Custos adicionais',
    custoTotal: 'Custo total do item',
    parcela: 'Parcela',
    lucroDesejado: 'Lucro desejado',
    precoSugerido: 'Preço sugerido',
    precoSugeridoVenda: 'Preço sugerido de venda',
    indisponivel: 'Indisponível no portal',
    semResultados: 'Sem Resultados',
    fator: 'Fator',
    fatorConversao: 'Fator de conversão',
    saida: 'Saída',
    saidas: 'Saídas',
    entrada: 'Entrada',
    entradas: 'Entradas',
    itemPadrao: 'Item padrão',
    controlaEstoque: 'Controla estoque',
    movimentaFinanceiro: 'Movimenta financeiro',
    novaEmbalagem: 'Nova embalagem',
    editarEmbalagem: 'Editar embalagem',
    embalagem: 'Embalagem',
    embalagem_plural: 'Embalagens',
    embalagemEntrada: 'Embalagem de entrada',
    embalagemSaida: 'Embalagem de saída',
    embalagemControleEstoque: 'Embalagem de controle de estoque',
    selecioneGrupoAdicionar: 'Selecione um grupo para adicionar',
    selecioneNovoGrupoContato: 'Selecione um novo grupo de contato',
    novoEndereco: 'Novo endereço',
    editarEndereco: 'Editar endereço',
    informeEndereco: 'Informe o endereço principal',
    codigoBarras: 'Código de barras',
    responsaveisCurso: 'Responsáveis pelo curso',
    tributacaoEntrada: 'Tributação de entrada',
    tributacaoSaida: 'Tributação de saída',
    selecioneTributacao: 'Selecione a tributação',
    custosFixos: 'Custos fixos',
    custoFixoEmpresa: 'Custo fixo da empresa',
    custoVariavel: 'Custo variável',
    custosVariaveis: 'Custos variáveis',
    lucroPercentual: 'Lucro percentual',
    pesquisar: 'Pesquisar',
    appBarPesquisa: 'Pesquisa',
    pesquisarMenus: 'Pesquise por Menus',
    pesquisarMenusClientes: 'Pesquise por Menus ou Clientes',
    pesquisarNomeCpf: 'Pesquise por Nome e CPF',
    pesquisarNomeCpfCnpj: 'Pesquise por Nome, CPF ou CNPJ',
    pesquisarNomeCpfDataNascimento: 'Pesquise por nome, CPF ou data de nascimento',
    emDesenvolvimento: 'Em desenvolvimento',
    favoritos: 'Favoritos',
    lucro: 'Lucro',
    lucroEm: 'Lucro em',
    lucroEmPorcentagem: 'Lucro em %',
    lucroEmReais: 'Lucro em R$',
    tipoConta: 'Tipo de conta',
    instituicaoBancaria: 'Instituição bancária',
    naoPermiteLancamentos: 'Não permite lançamentos',
    nomeContatoBanco: 'Nome do contato no banco',
    digito: 'Dígito',
    selecioneExecutor: 'Selecione um executor para vincular',
    consulta: 'Consulta',
    restricoes: 'Restrições',
    comissoes: 'Comissões',
    tipoComissoes: 'Tipo de comissões',
    origemComissao: 'Origem da comissão',
    equipePadrao: 'Equipe padrão',
    cargo: 'Cargo',
    horarioAcesso: 'Configuração de horários de acesso',
    grupo: 'Grupo',
    cargoGrupo: '$t(geral:cargo) / $t(geral:grupo)',
    percentual: 'Percentual',
    chanceConversao: '% chance de conversão',
    descricaoExemplo: 'Descrição exemplo',
    interessado: 'Interessado',
    grauConversao: 'Grau de conversão',
    adicionarItem: 'Adicionar item',
    adicionarAvaliacao: 'Adicionar avaliação',
    periodo: 'Período',
    periodoPedagogico: 'Período Pedagógico',
    de: 'De',
    ate: 'Até',
    para: 'Para',
    geral: 'Geral',
    automatica: 'Automática',
    automatico: 'Automático',
    manual: 'Manual',
    tipoCampo: 'Tipo do campo',
    status: 'Status',
    statusOrcamento: 'Status / Orçamento',
    statusNumeroOrcamento: 'Status / Nº orçamento',
    numeroOrcamento: 'Nº orçamento',
    codigoTitulo: 'Código titulo',
    cicloCalculo: 'Ciclo / Cálculo',
    condicaoComissaoem: 'Condição / Comissão em',
    descontarCancelamentosDevolucoes: 'Descontar cancelamentos e devoluções',
    considerarDescontoCascata: 'Considerar desconto cascata',
    cicloDePagamento: 'Ciclo de pagamento',
    cicloDePagamentoComissao: 'Ciclo de pagamento da comissão',
    baseCalculo: 'Base de cálculo',
    basesAtivas: 'Bases ativas',
    funcionalidadePadrao: 'Funcionalidade padrão',
    novaMeta: 'Nova meta',
    duracao: 'Duração',
    dataInicio: 'Data de início',
    dataTermino: 'Data de término',
    dataInicioTermino: 'Data de Inicio e Término',
    dataInicioTurma: 'Data de início da turma',
    dataTerminoTurma: 'Data de término da turma',
    dataVencimento: 'Data de vencimento',
    dataGeracao: 'Data geração',
    dataEncerramento: 'Data de encerramento',
    dataRecebimento: 'Data de recebimento',
    dataRecebimentoInicial: 'Data de recebimento inicial',
    dataRecebimentoFinal: 'Data de recebimento final',
    numeroFatura: 'Nº da fatura',
    natureza: 'Natureza',
    naturezaParcelas: 'Natureza / Parcelas',
    restricaoAcrescimo: 'Restrição / Acréscimo',
    bandeiraCredenciadora: 'Bandeira / Credenciadora',
    formaCondicao: 'Forma / Condição',
    taxaPrazo: 'Taxa / Prazo',
    contaCompensacao: 'Conta bancária / Compensação',
    documentoFornecedor: 'Documento / Fornecedor',
    origemDocumentoCliente: 'Origem documento / Cliente',
    emissaoVencimento: 'Emissão / Vencimento',
    emissaoEntrada: 'Emissão / Entrada',
    totalQuantidade: 'Total / Quantidade',
    totalQuantidadeItems: 'Total / Quantidade de itens',
    operacaoObservacoes: 'Operação / Observações',
    AVista: 'À vista',
    APrazo: 'À prazo',
    restricao: 'Restrição',
    selecioneRestricao: 'Selecione restrição',
    selecioneTipoCalculoComissao: 'Selecione o tipo de cálculo usado na comissão',
    acrescimo: 'Acréscimo',
    emMoeda: 'Em $t(geral:currency)',
    emPorcentagem: 'Em %',
    porcentagem: 'Porcentagem',
    vencimento: 'Vencimento {{number}}',
    porcentagemDaParcela: 'Porcentagem da parcela',
    mensal: 'Mensal',
    semanal: 'Semanal',
    quinzenal: 'Quinzenal',
    diario: 'Diário',
    parcelas: 'Parcelas',
    numeroParcelas: 'Número de parcelas',
    valorMeta: 'Valor da meta',
    valorComissao: 'Valor da comissão',
    valorDaParcela: 'Valor da Parcela',
    conciliarManualmente: 'Conciliar manualmente',
    creditarManualmente: 'Creditar manualmente',
    visualizarDetalhes: 'Visualizar detalhes',
    informacaoDePagamento: 'Informações de Pagamento',
    utilizaValorVencimentoFixo: 'Utilizar valor fixo e vencimento fixo',
    valorVencimentoVaiSerDefinido: 'Valor e vencimento serão definidos ao utilizar esta condição de pagamento',
    diaVencimento: 'Dia do vencimento ',
    diasAposVencimento: 'Dias após o vencimento',
    pagamento: 'Pagamento',
    recebimento: 'Recebimento',
    ambos: 'Ambos',
    dia: 'Dia',
    porDia: 'Por Dia',
    porMes: 'Por Mês',
    dia_plural: 'Dias',
    enviarFoto: 'Enviar foto',
    disponivelEm: 'Disponível em',
    modulosDisponiveis: 'Módulos disponíveis',
    moduloPadrao: 'Tornar o módulo padrão',
    situacaoModuloAtiva: 'Tornar situação do módulo ativa',
    selecioneModuloRelacionar: 'Selecione um módulo para relacionar',
    contaDebito: 'Conta débito',
    contaCredito: 'Conta crédito',
    tipoDeContato: 'Tipo de contato',
    tipoDeCalculo: 'Tipo de cálculo',
    selecioneContato: 'Selecione um contato',
    produtosEServicos: 'Produtos e serviços',
    produtosServicos: 'Produtos / Serviços',
    origem: 'Origem',
    origemOportunidade: 'Origem da oportunidade',
    quemIndicou: 'Quem indicou?',
    novaTarefa: 'Nova tarefa',
    indicadoPor: 'Indicado por',
    equipe: 'Equipe',
    selecioneTarefa: 'Selecione uma tarefa',
    grauDeInteresse: 'Grau de interesse',
    tipoDeCompromisso: 'Tipo de compromisso',
    motivo: 'Motivo',
    desconto: 'Desconto',
    descontos: 'Descontos',
    motivoComercial: 'Motivo comercial',
    descontoConcedido: 'Desconto Concedido',
    valorDesconto: 'Valor do desconto',
    valorOriginal: 'Valor original',
    valorBolsa: 'Valor da bolsa',
    bolsa: 'Bolsa',
    politica: 'Políticas',
    usuarioOportunidade: 'Usuário: ',
    usuariosResponsaveis: 'Usuários responsáveis',
    selecioneUsuarios: 'Selecione usuários',
    selecioneUsuario: 'Selecione um usuário',
    comentarios: 'Comentários',
    possibilidades: 'Possibilidades',
    escrevaUmComentario: 'Escreva um comentário',
    gerenciarWorkflow: 'Gerenciar funil',
    novoTipoDeContato: 'Novo tipo de contato',
    restringirA: 'Restringir à',
    restringirAFormaDePagamento: 'Restringir à forma de pagamento',
    novoCadastro: 'Novo cadastro',
    disponivel: 'Disponível',
    disponivelNoPortal: 'Disponível no portal',
    responsavelOportunidade: 'Responsável pela oportunidade',
    principal: 'Principal',
    selecionarEmpresa: 'Selecionar Empresa',
    selecionarModelo: 'Selecionar modelo',
    situacaoAtualizadaSucesso: 'Situação atualizada com sucesso',
    empresaAdicionadaSucesso: 'Empresa adicionada com sucesso',
    sucessoComentario: 'Comentário adicionada com sucesso',
    editarSituacao: 'Editar situação',
    selecioneEmpresaRelacionar: 'Selecione uma empresa para relacionar',
    bandeira: 'Bandeira',
    bandeiras: 'Bandeiras',
    credenciadora: 'Credenciadora',
    credenciadoras: 'Credenciadoras',
    cartoes: 'Cartões',
    formaDePagamento: 'Forma de pagamento',
    taxa: 'Taxa',
    prazo: 'Prazo',
    valorMaximo: 'Valor máximo',
    limitePacientes: 'Limite de pacientes',
    numeroSequencial: 'Número sequencial',
    compensaVencimento: 'Compensa no vencimento',
    selecioneContaBancaria: 'Selecione uma instituição bancária',
    conciliacao: 'Conciliação',
    conciliacaoAutomatica: 'Conciliação automática',
    orcamento: 'Orçamento',
    orcamentos: 'Orçamentos',
    valor: 'Valor',
    valorPrimeiraParcela: 'Valor 1ª parcela',
    vencimentoPrimeiraParcela: 'Vencimento 1ª parcela',
    orcamentoData: '$t(geral:orcamento) / $t(geral:data)',
    ano: 'Ano',
    anual: 'Anual',
    serieAno: 'Serie ou Curso / Ano',
    valorParcelas: '$t(geral:valor) / $t(geral:parcelas)',
    situacaoTipo: '$t(geral:situacao) / $t(geral:tipo)',
    fornecedorDataVencimento: 'Fornecedor / Data de vencimento',
    statusDataPagamento: 'Status / Data de pagamento',
    caixaEmpresa: 'Nº caixa / Empresa',
    valorParcela: 'Valor / Parcela',
    parcelaVencimento: 'Parcela / Vencimento',
    nomeCep: 'Nome / CEP',
    cidadeEstado: 'Cidade / Estado',
    tipoEndereco: 'Tipo de endereço',
    logradouro: 'Logradouro',
    logradouroNumero: 'Logradouro / Nº',
    tipoLogradouro: 'Tipo de logradouro',
    bairro: 'Bairro',
    numero: 'Número',
    operador: 'Operador',
    aberto: 'Aberto',
    consolidado: 'Consolidado',
    naoConsolidado: 'Não consolidado',
    efetivado: 'Efetivado',
    cancelado: 'Cancelado',
    cancelados: 'Cancelados',
    pendente: 'Pendente',
    enviado: 'Enviado',
    enviados: 'Enviados',
    processando: 'Processando',
    fechado: 'Fechado',
    finalizado: 'Finalizado',
    abertura: 'Abertura',
    fechamento: 'Fechamento',
    retirar: 'Retirar',
    suprimento: 'Suprimento',
    numeroDoContrato: 'Número do contrato',
    codigoDoContrato: 'Código do contrato',
    quitado: 'Quitado',
    vencido: 'Vencido',
    atendimentos: 'Atendimentos',
    evolucao: 'Evolução',
    dataEHora: 'Data e hora',
    data: 'Data',
    hora: 'Hora',
    subTotal: 'Subtotal',
    intervaloVencimentoParcelas: 'Intervalo do vencimento das parcelas',
    acrescimoDesconto: 'Acréscimo / Desconto',
    notas: 'Notas',
    situacaoFinanceira: 'Situação financeira',
    acoes: 'Ações',
    total: 'Total',
    media: 'Média',
    frequencia: 'Frequência',
    nomeFrequencia: 'Nome / Frequência',
    reposicao: 'Reposição',
    horario: 'Horário',
    sala: 'Sala',
    salas: 'Salas',
    horarioSala: '$t(geral:horario) / $t(geral:sala)',
    maisContatos: 'Mais contatos',
    nomeDetalhes: 'Nome / Detalhes',
    inicioTermino: 'Início / Término',
    tipoDeProduto: 'Tipo de produto',
    textoRespostaCurta: 'Texto de resposta curta',
    textoRespostaLonga: 'Texto de resposta longa',
    digiteSuaPergunta: 'Digite aqui sua pergunta',
    tempoAgendamento: 'Tempo para agendar antes da consulta',
    tempoDuracaoAtividade: 'Tempo de duração da atividade',
    intervaloRecreio: 'Intervalo - recreio',
    configuracoes: 'Configurações',
    configuracoesDeVigencia: 'Configurações de vigência',
    selecaoCanal: 'Seleção canal',
    portalAluno: 'Portal Aluno',
    aplicativo: 'Aplicativo',
    configuracaoDaEmpresa: 'Configuração da empresa',
    tipoPagamento: 'Tipo de pagamento',
    inicio: 'Início',
    termino: 'Término',
    horarios: 'Horários',
    diarioClasse: 'Diário de Classe',
    selecioneFormaDePagamentoVincular: 'Selecione uma forma de pagamento para vincular',
    peso: 'Peso',
    altura: 'Altura',
    pesoOrdem: 'Peso / Ordem',
    nomeTemplate: 'Nome / Template',
    atendimentoOnline: 'Atendimento Online',
    adicionarOpcoes: 'Adicionar opções',
    adicionarEmpresa: 'Adicionar empresa',
    visualizar: 'Visualizar',
    selecioneCaixa: 'Seleciona uma caixa',
    condicaoDePagamento: 'Condição de pagamento',
    condicoesDePagamento: 'Condições de pagamento',
    gerarTitulos: 'Gerar títulos',
    estornarLancamentos: 'Estornar lançamentos',
    funcao: 'Função',
    especialidade: 'Especialidade',
    subEspecialidade: 'Subespecialidade',
    somarHonorario: 'Somar honorário',
    honorario: 'Honorário',
    honorarios: 'Honorários',
    tipoFalta: 'Tipo de Falta',
    funcaoTipo: '$t(geral:funcao) / $t(geral:tipo)',
    valorEmpresa: '$t(geral:valor) / $t(geral:empresa)',
    dataAdmissao: 'Data de admissão',
    dataDemissao: 'Data de demissão',
    contasBancarias: 'Contas bancárias',
    pedidoCompra: 'Pedido de compra',
    pedidoCompras: 'Pedido de compras',
    countUnidade: '{{count}} unidade',
    countUnidade_plural: '{{count}} unidades',
    countItem: '{{count}} item',
    countItem_plural: '{{count}} itens',
    valorTotal: 'Valor total',
    valorPorParcela: 'Valor por parcela',
    valorLucro: 'Valor do lucro',
    repetir: 'Repetir',
    novoItem: 'Novo item',
    editarItem: 'Editar item',
    selecioneFase: 'Selecione uma fase',
    faseDoNegocio: 'Fase do negócio',
    editar: 'Editar',
    enviar: 'Enviar',
    verRespostas: 'Ver respostas',
    resposta: 'Resposta',
    resposta_plural: 'Respostas',
    compartilhar: 'Compartilhar',
    baixarCSV: 'Baixar CSV',
    novoContato: 'Novo contato',
    recorrencia: 'Recorrência',
    tarefas: 'Tarefas',
    ultimaTarefa: 'Ultima Tarefa',
    prestador: 'Prestador',
    sistema: 'Sistema',
    clientes: 'Clientes',
    clienteOuFornecedor: 'Cliente ou fornecedor',
    clienteFornecedorOperador: 'Cliente, fornecedor ou operador',
    clienteFornecedorFuncionario: 'Cliente, fornecedor ou funcionário',
    contaBancaria: 'Conta bancária',
    adicionar: 'Adicionar',
    adicionarValor: 'Adicionar valor',
    novaCondicao: 'Nova condição',
    adicionarCondicao: 'Adicionar condição',
    editarCondicao: 'Editar condição',
    gerenciarCondicoes: 'Gerenciar condições',
    novoValor: 'Novo valor',
    abrirCaixa: 'Abrir caixa',
    fecharCaixa: 'Fechar caixa',
    suprirCaixa: 'Suprir caixa',
    retirarCaixa: 'Retirar do caixa',
    justificativa: 'Justificativa',
    origemDocumento: 'Origem do documento',
    vendedor: 'Vendedor',
    vendidoPor: 'Vendido por',
    totalFechamento: 'Total do fechamento',
    saldoFechamento: 'Saldo do fechamento',
    totalSuprimento: 'Total do suprimento',
    saldoSuprimento: 'Saldo do suprimento',
    totalRetirada: 'Total da retirada',
    saldoRetirada: 'Saldo da retirada',
    saldoAtual: 'Saldo atual',
    valorRetirada: 'Valor da retirada',
    valorFechamento: 'Valor do fechamento',
    caixa: 'Caixa',
    dataPagamento: 'Data de pagamento',
    profissional: 'Profissional',
    profissionais: 'Profissionais',
    adicionarProfissional: 'Adicionar Profissional',
    selecioneProfissionalParaRelacionar: 'Selecione um profissional para relacionar',
    selecionePrestadorParaRelacionar: 'Selecione um prestador para relacionar',
    equipamentos: 'Equipamentos',
    servicos: 'Servicos',
    intervalo: 'Intervalo',
    diasDaSemana: 'Dias da semana',
    diaUtil: 'Dia útil',
    diaAposVencimento: 'Dias após o vencimento',
    feriado: 'Feriado',
    faixaEtaria: 'Faixa etária',
    falta: 'Falta',
    presente: 'Presente',
    limiteFaltas: 'Limite de faltas',
    limiteRetornos: 'Limite de retornos',
    selecionarSabados: 'Selecionar sábados',
    selecionarDomingos: 'Selecionar domingos',
    tipoPeriodo: 'Tipo de período',
    imprimirLegenda: 'Imprimir legenda',
    mostrarImpresso: 'Mostrar no impresso',
    eventoVoltadoResponsaveis: 'Evento voltado para responsáveis',
    considerarSabados: 'Considerar sábados',
    considerarDomingos: 'Considerar domingos',
    horarioFuncionamento: 'Horário de funcionamento',
    acessoOutrosProfissionais: 'Acesso de outros profissionais',
    notificarLimiteFaltas: 'Notificar limite de faltas',
    adicionarDependencia: 'Adicionar Dependência',
    dadosDaMatricula: 'Dados da Matrícula',
    dependencias: 'Dependências',
    opcoes: 'Opções',
    calendario: 'Calendário',
    movimento: 'Movimento',
    'contas-a-pagar': 'Contas a pagar',
    'contas-a-receber': 'Contas a receber',
    carregarMais: 'Carregar mais',
    tempo: 'Tempo',
    novoIntegrante: 'Novo integrante',
    modalidadeTurma: 'Modalidade de turma',
    novaSituacao: 'Nova situação',
    novoProdutoServico: 'Novo produto / serviço',
    especie: 'Espécie',
    cartao: 'Cartão',
    cheque: 'Cheque',
    funcionario: 'Funcionário',
    respondidoEm: 'Respondido em',
    respondidoPor: 'Respondido por',
    estrela: 'Estrela',
    estrela_plural: 'Estrelas',
    pesquisePorResposta: 'Pesquise por uma resposta...',
    emocao: 'Emoção',
    enviarPesquisa: 'Enviar pesquisa',
    envieLink: 'Envie o link',
    copiarLink: 'Copiar link',
    copiarCodigo: 'Copiar código',
    reenviarSms: 'Reenviar SMS',
    reenviarEmail: 'Reenviar e-mail',
    linkCopiado: 'Link copiado',
    valorPago: 'Valor pago',
    valorPendente: 'Valor pendente',
    valorItemServico: 'Valor do item / serviço',
    preDefinidos: 'Pré-definidos',
    novaSecao: 'Nova seção',
    adicionarCampo: 'Adicionar campo',
    novoCampo: 'Novo campo',
    editarCampo: 'Editar campo',
    tipoDoCampo: 'Tipo do campo',
    tamanhoDoCampo: 'Tamanho do campo',
    valorPadrao: 'Valor padrão',
    muito_pequeno: 'Muito pequeno',
    pequeno: 'Pequeno',
    medio: 'Médio',
    grande: 'Grande',
    textoCurto: 'Texto curto',
    termo: 'Termo',
    laboratorio: 'Laboratório',
    estaSalaEUmLaboratorio: 'Essa sala é um laboratório?',
    locacao: 'Locação',
    permiteLocacao: 'Permite locação?',
    tipoDeSala: 'Tipo de sala',
    equipamentoFixo: 'Equipamento fixo?',
    capacidade: 'Capacidade',
    capacidadeMaxima: 'Capacidade máxima',
    capacidadeMinima: 'Capacidade mínima',
    verPerfil: 'Ver perfil',
    assinarDigitalmente: 'Assinar digitalmente',
    nomeSocial: 'Nome social',
    serChamadoPeloNomeSocial: 'Ser chamado pelo nome social',
    profissaoResponsavel: 'Profissão do responsável',
    idadeResponsavel: 'Idade do responsável',
    midia: 'Mídia',
    plano: 'Plano',
    tipoCusto: 'Tipo de custo',
    valorCusto: 'Valor de custo',
    qtdProfissionais: 'Qtd. profissionais',
    necessidadeEspecial: 'Necessidade especial',
    unidade: 'Unidade',
    unidades: 'Unidades',
    subUnidade: 'Sub-unidade',
    optativo: 'Optativo',
    obrigatorio: 'Obrigatório',
    numeroDocumento: '$t(geral:numeroAbreviado) documento',
    nomeDocumento: 'Nome do documento',
    nomeDocumentoObrigatorio: 'O nome do documento é obrigatório',
    indicacao: 'Indicação',
    modelos: 'Modelos',
    solicitante: 'Solicitante',
    executante: 'Executante',
    imprimir: 'Imprimir',
    imprimirApenasDescricao: 'Imprimir apenas descrição',
    imprimirProfissionais: 'Imprimir profissionais',
    disponibilizarAssinatura: 'Disponibilizar assinatura',
    digiteAqui: 'Digite aqui',
    exame: 'Exame',
    exame_plural: 'Exames',
    origemExame: 'Origem do exame',
    selecionarExame: 'Selecionar exame',
    adicionarExame: 'Adicionar exame',
    novoProtocolo: 'Novo protocolo',
    tabela: 'Tabela',
    saldoConta: 'Saldo da conta',
    tipoProtocolo: 'Tipo de protocolo',
    selecionarProtocolo: 'Selecionar protocolo',
    efetivados: 'Efetivados',
    pendentes: 'Pendentes',
    finalizados: 'Finalizados',
    tipoDeConversao: 'Tipo de conversão',
    configuracao: 'Configuração',
    permiteCompra: 'Permite compra',
    permiteVenda: 'Permite venda',
    anosFaixa: 'Anos / Faixas',
    competenciaEspecifica: 'Competencia específica',
    novoUsuario: 'Novo usuário',
    patrimonio: 'Patrimônio',
    identificacaoPatrimonio: 'Identificação do Patrimônio',
    idadeMinima: 'Idade mínima',
    idadeMaxima: 'Idade máxima',
    executor: 'Executor',
    orientacao: 'Orientação',
    precaucao: 'Precaução',
    alergia: 'Alergia',
    alergiasGerais: 'Alergias gerais',
    alergiasMedicamentosas: 'Alergias medicamentosas',
    tipoSanguineo: 'Tipo sanguíneo',
    classificacao: 'Classificação',
    url: 'URL',
    videoUrl: 'URL do vídeo',
    secoesDisponiveis: 'Seções disponíveis',
    finalizarImprimir: 'Finalizar e imprimir',
    naoInformado: 'Não informado',
    naoInformada: 'Não informada',
    emissao: 'Emissão',
    codigoTabelaTuss: 'Código Tabela TUSS',
    codigoTuss: 'Código TUSS',
    filtroAvancado: 'Filtro Avançado',
    alunos: 'Alunos',
    valorAtual: 'Valor atual',
    itemQtd: 'Item / Qtd',
    valorAnual: 'Valor anual',
    filtros: 'Filtros',
    camposRelatorio: 'Campos do relatório',
    limiteCamposRelatorio: 'Permitido selecionar somente 6 itens por relatório',
    verBoleto: 'Ver boleto',
    copiarCodigoDeBarra: 'Copiar código de barras',
    gerarBoleto: 'Gerar boleto',
    baixarPdf: 'Baixar boleto em PDF',
    receber: 'Receber',
    efetivar: 'Efetivar',
    gerado: 'Gerado',
    liquidado: 'Liquidado',
    falhou: 'Falhou',
    baixado: 'Baixado',
    maisInformacoes: 'Mais informações',
    criadoPeloSistema: 'Criado pelo sistema',
    criadoPelaConciliacao: 'Criado pela conciliação bancária',
    liquidadoManualmente: 'Liquidado manualmente',
    liquidadoPelaConciliacao: 'Liquidado pela conciliação bancária',
    quitadoPeloSistema: 'Quitado pelo sistema',
    falhouLiquidacao: 'Falhou na liquidação',
    rejeitado: 'Rejeitado',
    baixadoManualmente: 'Baixado manualmente',
    geradoExternamente: 'Gerado externamente',
    cancelamentoSolicitado: 'Cancelamento solicitado',
    cancelamentosolicitado: 'Cancelamento solicitado',
    solicitacaoCancelamentoFalhou: 'Solicitação de cancelamento falhou',
    cancelamentofalhou: 'Cancelamento falhou',
    novaEspecialidade: 'Nova especialidade',
    prazoMaximoAtendimento: 'Prazo máximo de atendimento',
    tributacaoPadrao: 'Tributação padrão',
    selecionar: 'Selecionar',
    pedidoVenda: 'Pedido de venda',
    acessarPedido: 'Acessar pedido',
    acessarTitulo: 'Acessar título',
    fecharCaixaAberto: 'Fechar caixa aberto',
    ultimosNMeses: 'Últimos {{qtdeMeses}} meses',
    contasAPagar: 'Contas a pagar',
    contasAReceber: 'Contas a receber',
    movimentacoes: 'Movimentações',
    saldoTotal: 'Saldo total',
    recebido: 'Recebido',
    aReceber: 'A receber',
    pago: 'Pago',
    aPagar: 'A pagar',
    saldoInicial: 'Saldo inicial',
    saldoDaConta: 'Saldo da conta',
    historicoDeMovimentacoes: 'Histórico de movimentações',
    motivoDoDesfecho: 'Motivo do desfecho',
    tipoMotivoDesfecho: 'Tipo do motivo do desfecho',
    situacaoMatricula: 'Situação da matrícula',
    tipoMatricula: 'Tipo da matrícula',
    avaliacaoRealizada: 'Avaliação realizada',
    grauNecessidadeEspecial: 'Grau de necessidade especial',
    filial: 'Filial',
    matriz: 'Matriz',
    trocarEmpresa: 'Trocar Empresa',
    senha: 'Senha',
    senhaIncorreta: 'Senha incorreta.',
    senhaAtual: 'Senha atual',
    novaSenha: 'Nova senha',
    confirmacaoSenha: 'Confirmação da senha',
    emailAcesso: 'E-mail de acesso',
    solicitarNovaSenha: 'Solicitar nova senha após',
    permitirAcesso: 'Permitir acesso',
    manha: 'Manhã',
    tarde: 'Tarde',
    noite: 'Noite',
    adicionarLocal: 'Adicionar local',
    enderecoIP: 'Endereço de IP',
    recurso: 'Recurso',
    tipoNecessidadeEspecial: 'Tipo de necessidade especial',
    horariosLivres: 'Horários livres',
    chegada: 'Chegada',
    cor: 'Cor',
    cor_plural: 'Cores',
    selecioneCor: 'Selecione uma cor',
    adicionarRecurso: 'Adicionar recurso',
    faixaEtariaInicial: 'Faixa etária inicial',
    faixaEtariaFinal: 'Faixa etária final',
    modeloPadrao: 'Modelo padrão',
    modeloPadraoSerie: 'Modelo padrão da série',
    modelo: 'Modelo',
    habilitarTodos: 'Habilitar todos',
    limiteReposicoes: 'Limite de reposições',
    prazoParaReposicao: 'Prazo para reposição',
    notificarLimiteFaltasExcedido: 'Notificar limite de faltas excedido',
    verCadastro: 'Ver cadastro',
    bloquear: 'Bloquear',
    excluir: 'Excluir',
    personalizarLayout: 'Personalizar layout',
    topo: 'Topo',
    rodape: 'Rodapé',
    produto: 'Produto',
    impressao: 'Impressão',
    iniciar: 'Iniciar',
    escolhaUmModelo: 'Escolha um modelo',
    anexarArquivo: 'Anexar arquivo',
    exibirCabecalho: 'Exibir cabeçalho',
    alinhamentoDoCabecalho: 'Alinhamento do cabeçalho',
    fonte: 'Fonte',
    texto: 'Texto',
    espacamento: 'Espaçamento',
    mostrarData: 'Mostrar data',
    estornos: 'Estornos',
    valorACreditar: 'Valor a creditar',
    valorTotalTransacao: 'Valor total da transação',
    mensagem: 'Mensagem',
    mensagemPadrao: 'Mensagem padrão',
    subespecialidade: 'Subespecialidade',
    clienteDesde: 'Cliente desde',
    ticketMedio: 'Ticket médio',
    tipoSituacaoCheque: 'Status',
    tipoConciliacao: 'Tipo de conciliação',
    compensado: 'Compensado',
    devolvido: 'Devolvido',
    em_aberto: 'Em aberto',
    dataExpiracao: 'Data de expiração',
    ambiente: 'Ambiente',
    horaData: 'Hora e Data',
    ativa: 'Ativa',
    inativa: 'Inativa',
    formaFisica: 'Forma física',
    formula: 'Fórmula',
    diasDisponiveis: 'Dias disponíveis',
    diasLetivos: 'Dias letivos',
    totalDiasLetivos: 'Total de dias letivos',
    xDiasLetivos: '{{value}} dias letivos',
    anoLetivo: 'Ano letivo',
    anosLetivos: 'Anos letivos',
    xAnosLetivos: '{{value}} anos letivos',
    adicionarEvento: 'Adicionar evento',
    responsavelPelaCondicao: 'Responsável pela condição',
    clientesAtivos: 'Clientes ativos',
    faltosos: 'Faltosos',
    modalidades: 'Modalidades',
    verTodos: 'Ver todos',
    verTodas: 'Ver todas',
    particular: 'Particular',
    dataAtualizacao: 'Data de atualização',
    enviarSms: 'Enviar SMS',
    template: 'Template',
    quantidadeItens: 'Quantidade de itens',
    quantidadeItensInicial: 'Quantidade de itens inicial',
    quantidadeItensFinal: 'Quantidade de itens final',
    mes: 'Mês',
    procedimentos: 'Procedimentos',
    procurarPorAlunoResponsavelEmail: 'Aluno, Responsavel ou Email',
    procurarPorPacienteResponsavelEmail: 'Paciente, Responsavel ou Email',
    tempoAgendamentoAntesConsulta: 'Tempo para agendar antes da consulta',
    verAgenda: 'Ver agenda',
    formulas: 'Fórmulas',
    permite: 'Permite',
    prestadoresEmissores: 'Prestadores e emissores',
    exibirRodape: 'Exibir rodapé',
    rodapeCarimbo: 'Rodapé e carimbo',
    exibirRodapeCarimbo: 'Exibir rodapé e carimbo',
    alinhamentoDoRodape: 'Alinhamento do rodapé',
    idade: 'Idade',
    adicionarSecao: 'Adicionar seção',
    secaoRemovidoSucesso: 'Seção removida com sucesso',
    impressaoReceita: 'Impressão de Receita',
    removerSecao: 'Remover seção',
    replicarHorarios: 'Replicar horários',
    replicar: 'Replicar',
    diaSemana: 'Dia da semana',
    as: 'às',
    integracao: 'Integração',
    novoCheque: 'Novo cheque',
    novoLancamentoCartao: 'Novo lançamento de cartão',
    nsu: 'NSU',
    diferencaConsolidacao: 'Diferença consolidação',
    diferencaFechamento: 'Diferença fechamento',
    contaContabilCredito: 'Conta contábil para crédito',
    contaContabilDebito: 'Conta contábil para débito',
    contaContabilDebitoHelper: 'Uma conta contábil de débito, é a conta de destino do valor, bem, e ou serviço.',
    contaContabilCreditoHelper: 'Uma conta contábil de crédito, é a conta de origem do valor, bem, e ou serviço.',
    tipoOperacaoHelper:
      'Como todo movimento que irá resultar em representação financeira no DRE deve obrigatoriamente conter uma operação associada com uma conta contábil de origem  dos recursos e uma conta contábil para destino dos recursos',
    funil: 'Funil',
    selecioneFunil: 'Selecione um Funil',
    calculo: 'Cálculo',
    grupoDeDocumentos: 'Grupo de documentos',
    conteudo: 'Conteúdo',
    marcarTodos: 'Marcar todos',
    desmarcarTodos: 'Desmarcar todos',
    professores: 'Professores',
    funcionarios: 'Funcionários',
    aberturaCaixa: 'Abertura de caixa',
    condicao: 'Condição',
    condicao_plural: 'Condições',
    variavel: 'Variável',
    variavel_plural: 'Variáveis',
    parametro: 'Parâmetro',
    parametro_plural: 'Parâmetros',
    tenteNovamente: 'Tente novamente',
    notFound: 'Não foi possível carregar a página.',
    conclusao: 'Conclusão',
    dataConclusao: 'Data de conclusão',
    dataEnvio: 'Data de envio',
    periodos: 'Períodos',
    listaPeriodos: 'Lista de períodos',
    adicionarFormula: 'Adicionar fórmula',
    adicionarPeriodo: 'Adicionar período',
    isento: 'Isento',
    isenta: 'Isenta',
    nomeTurma: 'Nome da turma',
    verParcelas: 'Ver parcelas',
    dataContrato: 'Data do contrato',
    prezado_prezada: 'Prezado(a)',
    recomendacao: 'Recomendação',
    recomendacao_plural: 'Recomendações',
    anonimo: 'Anônimo',
    camera: 'Câmera',
    tirarFoto: 'Tirar foto',
    tirarOutraFoto: 'Tirar outra foto',
    cameraDesligada: 'Câmera desligada',
    enviarEmail: 'Enviar e-mail',
    selecioneClienteOuFornecedor: 'Selecione um cliente ou fornecedor',
    selecioneClienteFornecedorOuFuncionario: 'Selecione um cliente, fornecedor ou funcionário',
    condicaoTaxa: 'Condição / Taxa',
    prazoCompensaVencimento: 'Prazo / Compensa no vencimento',
    quitarConta: 'Quitar conta',
    quitarLote: 'Quitar contas em lote',
    consolidacaoLote: 'Consolidação de caixas em lote',
    desconsolidacaoLote: 'Desconsolidação de caixas em lote',
    consolidacaoTitulosLote: 'Consolidação de títulos em lote',
    desconsolidacaoTitulosLote: 'Desconsolidação de títulos em lote',
    titulosSomando: 'títulos somando',
    lancarBaixa: 'Lançar baixa',
    tipoAcrescimoDesconto: 'Tipo acréscimo / desconto',
    agendadoEm: 'Agendado em',
    acessarTeleconsulta: 'Acessar teleconsulta',
    notificacoes: 'Notificações',
    dataFixa: 'Data fixa',
    valorFixo: 'Valor fixo',
    data_hora: 'Data / hora',
    aceitarTermos: 'Aceitar os termos',
    dataVencimentoInicial: 'Data de vencimento inicial',
    dataVencimentoFinal: 'Data de vencimento final',
    agrupamento: 'Agrupamento',
    semAgrupamento: 'Sem Agrupamento',
    agruparVencimento: 'Agrupar por Vencimento',
    novoPeriodo: 'Novo período',
    editarPeriodo: 'Editar período',
    novoRecurso: 'Novo recurso',
    prazoParaRetorno: 'Prazo para retorno',
    disponivelAgendamentoOnline: 'Disponível no agendamento online',
    disponivelPortalAluno: 'Disponível no portal do aluno',
    diasSemana: 'Dias da semana',
    categoriaAgendamento: 'Categoria de agendamento',
    novoFuncionario: 'Novo funcionário',
    editarFuncionario: 'Editar funcionário',
    historicoChat: 'Histórico do chat',
    campos: 'Campos',
    local: 'Local',
    nenhumRecursoCadastrado: 'Nenhum recurso cadastrado',
    relatorios: 'Relatórios',
    gerarOrcamento: 'Gerar orçamento',
    gerarVenda: 'Gerar venda',
    padrao: 'Padrão',
    visualizacao: 'Visualização',
    visualizacaoEmLista: 'Visualização em lista',
    visualizacaoPorCard: 'Visualização por card',
    download: 'Download',
    downloadPdf: 'Download PDF',
    nomeMantenedora: 'Nome da mantenedora',
    representanteLegal: 'Representante legal',
    limit50MB: 'de 50MB',
    arquivoRemovido: 'Arquivo removido com sucesso',
    arquivoAtualizado: 'Arquivo atualizado com sucesso',
    detalhesAnexo: 'Detalhes do anexo',
    visualizarBaixar: 'Visualizar / Baixar',
    alergias: 'Alergias',
    medicamentosUsoContinuo: 'Medicamentos de uso contínuo',
    precaucoes: 'Precauções',
    agenda: 'Agenda',
    pesquiseAqui: 'Pesquise aqui...',
    funcionarioPermitido: 'Funcionário permitido',
    publica: 'Pública',
    termosDeAceite: 'Termos de aceite',
    aceito: 'Aceito',
    naoAceito: 'Não aceito',
    adendo: 'Adendo',
    assinar: 'Assinar',
    planos: 'Planos',
    atributoAnatomico: 'Atributo anatômico',
    convenios: 'Convênios',
    tipoSecao: 'Tipo de seção',
    inserirImagem: 'Inserir imagem',
    mostrarX: 'Mostrar "{{name}}"',
    titulo: 'Título',
    idTitulo: 'ID do título',
    clientId: 'Cliente ID',
    id: 'ID',
    tamanhoFolha: 'Tamanho da folha',
    alinhamentoCarimbo: 'Alinhamento do carimbo',
    imprimirCarimboSomenteUltimaPagina: 'Imprimir carimbo somente na última página',
    modeloImpressao: 'Modelo de impressão',
    modeloReceituario: 'Modelo de receituário',
    publico: 'Público',
    privado: 'Privado',
    termosUso: 'Termos de uso',
    portalPrivacidade: 'Portal de privacidade',
    dataAceite: 'Data do aceite',
    documentacao: 'Personalizado',
    modeloDeDocumentos: 'Modelo de documentos',
    dataInicioVigencia: 'Data início da vigência',
    dataTerminoVigencia: 'Data término da vigência',
    tipoContrato: 'Tipo de contrato',
    tipoAgendamento: 'Tipo do Agendamento',
    retornoExames: 'Retorno de exames',
    cabecalho: 'Cabeçalho',
    titulos: 'Títulos',
    vendas: 'Vendas',
    registradoEm: 'Registrado em',
    cargaHorariaTotal: 'Carga horária total',
    adicionarDesconto: 'Adicionar desconto',
    utilizarCondicaoPagamentoItem: 'Utilizar condição de pagamento do item',
    dadosDoContrato: 'Dados do contrato',
    referencia: 'Referência',
    dadosDaParcela: 'Dados da parcela',
    nossoNumero: 'Nosso número',
    valorDoDocumento: 'Valor do documento',
    valorLiquido: 'Valor líquido',
    valorBruto: 'Valor bruto',
    dadosDoPagamento: 'Dados do pagamento',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    horaInicial: 'Hora inicial',
    horaFinal: 'Hora final',
    considerarDiaLetivo: 'Considerar como dia letivo',
    mostrarCalendarioImpresso: 'Mostrar no calendário impresso',
    periodoAvaliativo: 'Período avaliativo',
    nomePeriodo: 'Nome do período',
    copiarCalendario: 'Copiar calendário',
    criarCopia: 'Criar cópia',
    desejaRealmenteCriarCopiaRegistro: 'Deseja realmente criar uma cópia deste registro?',
    disponiveisAno: 'Disponíveis no ano',
    letivosAno: 'Letivos no ano',
    naoLetivos: 'Não Letivos',
    feriados: 'Feriados',
    dilatacao: 'Dilatação',
    imprimirReceita: 'Imprimir receita',
    apenasComTitulos: 'Apenas com títulos',
    totalCondicao: 'Total da condição',
    verDetalhes: 'Ver detalhes',
    simNao: 'Sim ou Não',
    inativarLink: 'Inativar link',
    manter: 'Manter',
    voltarDepois: 'Voltar depois',
    debugs: 'Debugs',
    falhas: 'Falhas',
    relacionamento: 'Relacionamento',
    hooks: 'Hooks',
    mensalidade: 'Mensalidade',
    usuarios: 'Usuários',
    smsEnviados: 'SMS enviados',
    emailsEnviados: 'Emails enviados',
    boletosEmitidos: 'Boletos emitidos',
    bemVindo: 'Bem vindo',
    por: 'por',
    totalDoCaixa: 'Total do caixa',
    abertos: 'Abertos',
    verContrato: 'Ver contrato',
    filtrosAvancados: 'Filtros avançados',
    limparFiltros: 'Limpar filtros',
    maisFiltros: 'Mais filtros',
    dataCadastroInicial: 'Data de Cadastro Inicial',
    dataCadastroFinal: 'Data de Cadastro Final',
    dataEdicaoInicial: 'Data de Edição inicial',
    dataEdicaoFinal: 'Data de Edição final',
    codigoCliente: 'Código do cliente',
    criado: 'Criado',
    editado: 'Editado',
    ativas: 'Ativas',
    inativas: 'Inativas',
    interno: 'Interno',
    externo: 'Externo',
    todos: 'Todos',
    totalGeral: 'Total Geral',
    totalExterno: 'Total de Externos',
    totalInterno: 'Total de Internos',
    totalAtivas: 'Total de Ativos',
    totalInativas: 'Total de Inativos',
    confirmar: 'Confirmar',
    fechados: 'Fechados',
    consolidar: 'Consolidar',
    desconsolidar: 'Desconsolidar',
    saldo: 'Saldo',
    restamXDias: 'Restam {{dias}} dias!',
    ultimoDia: 'Último dia!',
    totalFormaPagamento: 'Total da forma de pagamento',
    saldoFormaPagamento: 'Saldo da forma de pagamento',
    operacaoMovimento: 'Operação do movimento',
    diferenca: 'Diferença',
    valorDeAbertura: 'Valor de abertura',
    usuarioHabilitado: 'Usuário habilitado',
    valorDeFechamento: 'Valor de fechamento',
    valorConsolidado: 'Valor consolidado',
    valorDaDiferenca: 'Valor da diferença',
    situacaoDoCaixa: 'Situação do caixa',
    escrevaUmaJustificativa: 'Escreva uma justificativa',
    excluirBaixa: 'Excluir baixa',
    dataDeFechamento: 'Data de fechamento',
    baixar: 'Baixar',
    filtrar: 'Filtrar',
    comparar: 'Comparar',
    compararImagens: 'Comparar imagens',
    toolbar: 'Toolbar',
    tipoDeArquivo: 'Tipo de arquivo',
    modulos: 'Módulos',
    usuario: 'Usuário',
    selecionarSecao: 'Selecionar uma seção de outro modelo para importar',
    titularConta: 'Titular da conta',
    contaCorrente: 'Conta corrente',
    contaPoupanca: 'Conta poupança',
    banco: 'Banco',
    titular: 'Titular',
    site: 'Site',
    diaDaTransferencia: 'Dia da transferência',
    margens: 'Margens',
    previsualizacao: 'Pré-visualização',
    alinhamento: 'Alinhamento',
    adicionarLinhaAcima: 'Adicionar linha acima',
    removerLinha: 'Remover linha',
    removerColuna: 'Remover coluna',
    adicionarLinhaAbaixo: 'Adicionar linha abaixo',
    adicionarColunaAEsquerda: 'Adicionar coluna à esquerda',
    adicionarColunaADireita: 'Adicionar coluna à direita',
    adicionarTag: 'Adicionar tag',
    esquerda: 'Esquerda',
    direita: 'Direita',
    visualizarPedido: 'Visualizar pedido',
    composicoes: 'Composições',
    adicionarComposicao: 'Adicionar composição',
    quantidadeMinimaItens: 'Quantidade mínima de itens',
    quantidadeMaximaItens: 'Quantidade máxima de itens',
    dataEntradaInicial: 'Data entrada inicial',
    dataEntradaFinal: 'Data entrada final',
    totalComposicao: 'Total das composições',
    outrosFiltros: 'Outros filtros',
    imprimeNaGuia: 'Imprime na guia',
    terminologiaDeCodigoDaDespesa: 'Terminologia de código da despesa',
    utilizarFormulas: 'Utilizar fórmulas',
    numeroCaixa: 'Número do caixa',
    dataLancamentoInicial: 'Data lançamento inicial',
    dataLancamentoFinal: 'Data lançamento final',
    verMais: 'Ver mais',
    dataPagamentoInicial: 'Data pagamento inicial',
    dataPagamentoFinal: 'Data pagamento final',
    opcaoPagamento: 'Opção de pagamento',
    verMenos: 'Ver menos',
    novoPrestadorEmissor: 'Novo prestador ou emissor',
    certificado: 'Certificado',
    issRetido: 'ISS Retido',
    usaNfse: 'Usa NFS-e',
    usaNfe: 'Usa NF-e',
    numeroSerieNfse: 'N° série NFS-e',
    responsavelIss: 'Responsável ISS',
    indicadorLocalServico: 'Indicador de local do serviço',
    regimeEspecialTributacao: 'Regime especial de tributação',
    naturezaOperacao: 'Natureza da operação',
    operacaoTributacao: 'Operação de tributação (DSFNET)',
    numeroUltimoRps: 'N° do último RPS',
    codigoUnidadeMedida: 'Código da unidade de medida',
    ocultarPisCofinsExibirApenasObservacoesNota: 'Ocultar PIS e COFINS e exibir apenas nas observações da nota',
    formatarAliquotaIss: 'Formatar Alíquota ISS (Tag ValAliqISS)',
    br: 'BR',
    validarCpfCnpj: 'Validar CPF / CNPJ',
    validarDadosCadastrais: 'Validar dados cadastrais',
    sempreValorParcela: 'Sempre o valor da parcela',
    valorParcelaPendenteValorPagoQuitado: 'Valor da parcela se pendente e valor pago se quitado',
    deduzirJuros: 'Deduzir juros',
    jurosComposto: 'Juros Composto',
    jurosSimples: 'Juros Simples',
    discriminarDesconto: 'Discriminar descontos',
    condicionado: 'Condicionado',
    incondicionado: 'Incondicionado',
    paisTomador: 'País do tomador',
    pagarme: 'Pagar.me',
    taxasAtualizadasSucesso: 'Taxas atualizadas com sucesso!',
    atualizarTaxas: 'Atualizar taxas',
    ativeIntegracaoAcessarAba: 'Ative a integração para ter acesso a essa aba',
    nenhumaTarifaEncontrada: 'Nenhuma tarifa encontrada :(',
    tenteAtualizarTarifasAbaixo: 'Tente atualizar as tarifas abaixo',
    arquivos: 'Arquivos',
    agendar: 'Agendar',
    ted: 'TED',
    movimentar: 'Movimentar',
    tomador: 'Tomador',
    situacaoNfsE: 'Situação da NFS-E',
    numeroDaNfsE: 'Nº da NFS-e',
    numeroDoRps: 'Nº do RPS',
    selecioneTipoData: 'Selecione um tipo de data',
    selecioneTipoRecebimento: 'Selecione um tipo de recebimento',
    selecioneUmaSituacao: 'Selecione uma situação',
    selecioneUmaOperacao: 'Selecione uma operação',
    selecioneUmResponsavel: 'Selecione um responsável',
    situacaoDataHora: 'Situação / Data e hora',
    selecioneUmPrestador: 'Selecione um prestador',
    selecioneUmMotivo: 'Selecione um motivo',
    selecioneUmTomador: 'Selecione um tomador',
    valorPagamento: 'Valor Do Pagamento',
    codigoDeBarras: 'Código de barras',
    boletoInavalido: 'Boleto inválido',
    vigenciaContrato: 'Vigência do contrato',
    contratante: 'Contratante',
    visualizarDocumento: 'Visualizar documento',
    reenviar: 'Reenviar',
    adicionarContratante: 'Adicionar contratante',
    adicionarTestemunha: 'Adicionar testemunha',
    adicionarContratado: 'Adicionar contratado',
    cpfOuCnpj: 'CPF ou CNPJ',
    vigenciaAssinatura: 'Vigência da assinatura',
    contratadosPadrao: 'Contratados padrão',
    testemunhasPadrao: 'Testemunhas padrão',
    assinado: 'Assinado',
    dataAssinatura: 'Data da assinatura',
    dataContratacaoInicial: 'Data de contratação inicial',
    dataContratacaoFinal: 'Data de contratação final',
    padraoNfseChave: 'Padrão de chave NFS-e',
    chaveAcesso: 'Chave de acesso',
    gerarNf: 'Gerar NF',
    emissaoNotaFiscal: 'Emissão de nota fiscal',
    prestadorEmissor: 'Prestador / Emissor',
    aguardando_envio: 'Aguardando envio',
    aguardando_contratantes: 'Aguardando contratante(s)',
    aguardando_testemunhas: 'Aguardando testemunha(s)',
    aguardando_contratados: 'Aguardando contratado(s)',
    assinaturaPendente: 'Assinatura pendente',
    editarDocumento: 'Editar documento',
    solicitarAssinatura: 'Solicitar assinatura',
    contratoMatricula: 'Contrato / Matrícula',
    intervaloNotificacaoDias: 'Intervalo da notificação em dias',
    rsaKey: 'Rsa key',
    chaveIntegracaoExterna: 'Chave de integracão externa',
    detalhesContratado: 'Detalhes do contratado',
    detalhesTestemunha: 'Detalhes da testemunha',
    tipoUsuarioTarifado: 'Tipo de usuário tarifado',
    movimentacaoProfissionaisNoSistema: 'Movimentação de profissionais no sistema',
    movimentacaoProfissionaisNoRetaguarda: 'Movimentação de profissionais no retaguarda',
    tiposAcesso: 'Tipos de acesso',
    ola: 'Olá',
    tipoAutenticacaoAssinatura: 'Tipo de autenticação para assinatura',
    confirmarDados: 'Confirmar dados',
    triggerParaPalavrasChaves: 'Utilizar {{trigger}} para palavras-chaves',
    horarioFuncionamentoDisponibilidade: 'Horário de funcionamento / Disponibilidade',
    horarioExistente: 'Horário já existente!',
    salaHabilitadaAulaLivre: 'Sala habilitada para aula livre',
    motivoOperacao: 'Motivo da operação',
    quadroHorarios: 'Quadro de horários',
    selecioneDiaSemana: 'Selecione o dia da semana',
    professorSemHorarioConfigurado:
      'Este professor não possui o horário desta aula configurado em seus horários. Deseja continuar?',
    selecioneUmaAvaliacao: 'Selecione uma avaliação',
    selecioneUmGrupoAvaliacao: 'Selecione um grupo/avaliação',
    selecionePeriodoExame: 'Selecione período/exame',
    avaliacoesExcluidasSucesso: 'Avaliações excluídas com sucesso',
    excluirAvaliacoesConfirmacao:
      'Todas as avaliações cadastradas neste período serão excluídas. Deseja realmente continuar?',
    excluirAvaliacoes: 'Excluir avaliações',
    pesquisePorNome: 'Pesquise por Nome',
    notas0A: 'Notas de 0 a',
    faltas0A: 'Faltas de 0 a',
    notaAlterada: 'Esta nota foi alterada manualmente. <br> Dia {{data}} às {{horario}} por {{nome}}.',
    notaRemovida: 'Esta nota foi removida. <br> Dia {{data}} às {{horario}} por {{nome}}.',
    situacaoLancadaSucesso: 'Situação lançada com sucesso',
    cpfResponsavel: 'CPF do responsável',
    telefoneResidencial: 'Telefone residencial',
    matriculaInterno: 'Matrícula interno',
    ra: 'RA',
    enderecoNumero: 'Nº endereço',
    openBanking: 'Open banking',
    rsaPrivateKey: 'Rsa private key',
    rsaPrivateKeyCadastrada: 'Chave rsa cadastrada com sucesso',
    rsaPrivateKeyAtualizada: 'Chave rsa atualizada com sucesso',
    urlPostback: 'Url postback',
    parcial: 'Parcial',
    final: 'Final',
    configuracaoDeGrupo: 'Configuração de grupo',
    nomeGrupo: 'Nome grupo',
    detalhesAvaliacao: 'Detalhes avaliação',
    pesoAvaliacao: 'Peso avaliação',
    recParcial: 'Rec parcial',
    mediaAposRecParcial: 'Média após Rec parcial',
    replicarPeriodos: 'Replicar para todos os períodos',
    selecionarGrupo: 'Selecionar grupo',
    replicarConfiguracoesParaTodosPeriodos: 'Replicar configurações para todos os períodos',
    nAvaliacoes: 'Nº de avaliações',
    nGrupos: 'Nº de grupos',
    grupoRelacionado: 'Grupo relacionado',
    selecioneOPeriodo: 'Selecione o período!',
    segChamada: '2ª chamada',
    formas: 'Formas',
    formasDePagamento: 'Formas de pagamento',
    definirComoPadrao: 'Definir como padrão',
    primeiraParcelaFixa: 'Primeira parcela fixa',
    responsavelFinanceiro: 'Responsável financeiro',
    jurosMultas: 'Juros e multas',
    verItens: 'Ver itens',
    quitar: 'Quitar',
    popUpsBloqueados: 'Ops! Pop-ups bloqueados!',
    aPartirContasReceber: 'À partir de contas a receber',
    aPartirOrcamentos: 'À partir de orçamentos',
    financeiroPadrao: 'Financeiro padrão',
    responsavelFinanceiroPadrao: 'Responsável financeiro padrão',
    alterarResponsavelFinanceiro: 'Alterar responsável financeiro',
    jaExisteResponsavelFinanceiroPadrao:
      'Você já possui um responsável financeiro padrão. Deseja realmente continuar com a alteração?',
    comDesconto: 'Com desconto',
    semDesconto: 'Sem desconto',
    descontosAcrescimos: 'Descontos e acréscimos',
    renegociar: 'Renegociar',
    renegociacao: 'Renegociação',
    valorCalculado: 'Valor calculado',
    'R$ou%': 'R$ ou %',
    avulso: 'Avulso',
    itensServiços: 'Itens e serviços',
    alterarPrecoPadraoParaRemover:
      'É necessário alterar o preço padrão para que o preço selecionado possa ser removido.',
    removerAnexo: 'Remover anexo',
    confirmacaoRemoverAnexo: 'Deseja remover o anexo?',
    acompanharStatus: 'Acompanhar status',
    zoop: 'Zoop',
    reenviarBoleto: 'Reenviar boleto',
    cancelarBoleto: 'Cancelar boleto',
    desejaContinuar: 'Deseja continuar?',
    desejaRealmenteCancelar: 'Deseja realmente cancelar?',
    registroCanceladoSucesso: 'Registro cancelado com sucesso!',
    enviarWhatsApp: 'Enviar por WhatsApp',
    enviarMensagemViaWhatsApp: 'Enviar mensagem via WhatsApp',

    meses: {
      janeiro: 'Janeiro',
      janeiro_abrev: 'JAN',
      fevereiro: 'Fevereiro',
      fevereiro_abrev: 'FEV',
      marco: 'Março',
      marco_abrev: 'MAR',
      abril: 'Abril',
      abril_abrev: 'ABR',
      maio: 'Maio',
      maio_abrev: 'MAI',
      junho: 'Junho',
      junho_abrev: 'JUN',
      julho: 'Julho',
      julho_abrev: 'JUL',
      agosto: 'Agosto',
      agosto_abrev: 'AGO',
      setembro: 'Setembro',
      setembro_abrev: 'SET',
      outubro: 'Outubro',
      outubro_abrev: 'OUT',
      novembro: 'Novembro',
      novembro_abrev: 'NOV',
      dezembro: 'Dezembro',
      dezembro_abrev: 'DEZ'
    },

    weekDays: {
      domingo: 'Domingo',
      segundaFeira: 'Segunda-Feira',
      tercaFeira: 'Terça-Feira',
      quartaFeira: 'Quarta-Feira',
      quintaFeira: 'Quinta-Feira',
      sextaFeira: 'Sexta-Feira',
      sabado: 'Sábado',
      domingoAbr: 'Dom',
      segundaFeiraAbr: 'Seg',
      tercaFeiraAbr: 'Ter',
      quartaFeiraAbr: 'Qua',
      quintaFeiraAbr: 'Qui',
      sextaFeiraAbr: 'Sex',
      sabadoAbr: 'Sab'
    },

    dateTime: {
      segundo: 'segundo',
      segundo_plural: 'segundos',
      minuto: 'minuto',
      minuto_plural: 'minutos',
      hora: 'hora',
      hora_plural: 'horas',
      dia: 'dia',
      dia_plural: 'dias',
      mes: 'mês',
      mes_plural: 'meses',
      ano: 'ano',
      ano_plural: 'anos'
    },

    feedbacks: {
      desejaContinuar: 'As alteração feitas não serão salvas, deseja continuar?',
      removerPermanentemente: 'Remover este cadastro permanentemente',
      algoInesperado: 'Algo inesperado aconteceu',
      emailAdicionadoSucesso: 'E-mail adicionado com sucesso!',
      emailRemovidoSucesso: 'E-mail removido com sucesso!',
      enderecoAdicionadoSucesso: 'Endereço adicionado com sucesso!',
      enderecoAtualizadoSucesso: 'Endereço atualizado com sucesso!',
      enderecoRemovidoSucesso: 'Endereço removido com sucesso!',
      emailNaoExiste: 'E-mail informado não existe!',
      emailRecuperacaoEnviadoSucesso: 'E-mail de recuperação enviado com sucesso!',
      senhaAlteradaSucesso: 'Senha alterada com sucesso!',
      redeSocialAdicionadaSucesso: 'Rede social adicionada com sucesso!',
      redeSocialRemovidaSucesso: 'Rede social removida com sucesso!',
      telefoneAdicionadoSucesso: 'Telefone adicionado com sucesso!',
      telefoneRemovidoSucesso: 'Telefone removido com sucesso!',
      documentoAtualizadoSucesso: 'Documentos atualizados com sucesso!',
      dependenteAdicionadoSucesso: 'Dependente atualizado com sucesso!',
      dependenteRemovidoSucesso: 'Dependente removido com sucesso!',
      responsavelAdicionadoSucesso: 'Responsável adicionado com sucesso!',
      responsavelAtualizadoSucesso: 'Responsável atualizado com sucesso!',
      responsavelRemovidoSucesso: 'Responsável removido com sucesso!',
      dependenteAtualizadoSucesso: 'Dependente atualizado com sucesso!',
      itemIncluidoSucesso: 'Item incluído com sucesso!',
      itemAdicionadoSucesso: 'Item adicionado com sucesso!',
      itemAtualizadoSucesso: 'Item atualizado com sucesso!',
      itemRemovidoSucesso: 'Item removido com sucesso!',
      itemJaCadastrado: 'Item já cadastrado!',
      cadastroSucesso: 'Cadastrado realizado com sucesso',
      nenhumRegistro: 'Nenhum registro encontrado',
      primeiroCadastro: 'Faça seu primeiro cadastro clicando em',
      avatarAdicionadoSucesso: 'Avatar adicionado com sucesso!',
      avatarRemovidoSucesso: 'Avatar removido com sucesso!',
      logotipoAdicionadoSucesso: 'Logotipo adicionado com sucesso!',
      logotipoRemovidoSucesso: 'Logotipo removido com sucesso!',
      brasaoEstadoAdicionadoSucesso: 'Brasão do Estado adicionado com sucesso!',
      brasaoEstadoRemovidoSucesso: 'Brasão do Estado removido com sucesso!',
      nenhumKanban: 'Nenhum kanban para exibir',
      primeiraConfiguracaoWorkflow: 'Configure um funil e comece a gerenciar suas oportunidades',
      checklistAtualizadoSucesso: 'Checklist atualizado com sucesso!',
      checklistCadastradoSucesso: 'Item cadastrado no checklist com sucesso!',
      checklistRemovidoSucesso: 'Item removido do checklist com sucesso!',
      adicionadoSucesso: 'Adicionado com sucesso!',
      cadastradoSucesso: 'Cadastrado com sucesso!',
      atualizadoSucesso: 'Atualizado com sucesso!',
      removidoSucesso: 'Removido com sucesso!',
      adicionadaSucesso: 'Adicionada com sucesso!',
      cadastradaSucesso: 'Cadastrada com sucesso!',
      atualizadaSucesso: 'Atualizada com sucesso!',
      removidaSucesso: 'Removida com sucesso!',
      tarefaAdicionadaSucesso: 'Tarefa adicionada com sucesso!',
      tarefaAtualizadaSucesso: 'Tarefa atualizada com sucesso!',
      tarefaRemovidaSucesso: 'Tarefa removida com sucesso!',
      situacaoCadastradaSucesso: 'Situação cadastrada com sucesso',
      situacaoAtualizadaSucesso: 'Situação atualizada com sucesso!',
      situacaoRemovidaSucesso: 'Situação removida com sucesso!',
      respostaEnviada: 'Sua resposta foi enviada',
      obrigadoContribuirConosco: 'Obrigado por contribuir conosco',
      alteracoesSalvasSucesso: 'Alterações salvas com sucesso',
      itemSemprePreco: 'Item sem preço',
      itemSemPrecoObrigatorio: 'Não é possível salvar um item sem preço!',
      itemPrecoSelecioneUmItem: 'Selecione um item para carregar seus preços',
      contaNaoMovimentada: 'Essa conta ainda não foi movimentada!',
      semEstornosNoPeriodo: 'Não houveram estornos durante esse período',
      semContratosVigentesUltimosXMeses: 'Sem contratos vigentes nos últimos {{meses}} meses',
      smsRequisicaoEnvioCadastrada: 'Envio de SMS requisitado com sucesso',
      emailRequisicaoEnvioCadastrada: 'Envio de Email requisitado com sucesso',
      usuarioAdicionadoSucesso: 'Usuário adicionado com sucesso!',
      baixaLancadaSucesso: 'Baixa lançada com sucesso!',
      baixaListaLancadaSucesso: 'Baixa em lote lançada com sucesso!',
      baixaRemovidaSucesso: 'Baixa removida com sucesso!',
      cartaoLancadoSucesso: 'Lançamento de cartão realizado com sucesso!',
      equipamentoAdicionadoSucesso: 'Equipamento adicionado com sucesso!',
      professorAdicionadoSucesso: 'Professor adicionado com sucesso!',
      salaAdicionadaSucesso: 'Sala adicionada com sucesso!',
      funcionarioAdicionadoSucesso: 'Funcionário adicionado com sucesso!',
      salaRemovidaSucesso: 'Sala removida com sucesso!',
      equipamentoRemovidoSucesso: 'Equipamento removido com sucesso!',
      professorRemovidoSucesso: 'Professor removido com sucesso!',
      funcionarioRemovidoSucesso: 'Funcionário removido com sucesso!',
      procedimentoAdicionadoSucesso: 'Procedimento adicionado com sucesso!',
      procedimentoRemovidoSucesso: 'Procedimento removido com sucesso!',
      dataInicialNaoPodeSerMaiorQueDataFinal: 'Data inicial não pode ser maior que data final',
      agendamentoRemovidoSucesso: 'Agendamento removido com sucesso!',
      nenhumArquivoAnexado: 'Nenhum arquivo anexado',
      nenhumArquivoAnexadoAoAtendimento: 'Nenhum arquivo foi anexado a esse atendimento!',
      precisaFecharCaixaAntesAbrirOutro: 'Você precisa fechar o caixa antes de abrir outro.',
      caixaAbertoSucesso: 'Caixa aberto com sucesso!',
      conteReceberEstornadaSucesso: 'Conta a receber estornada com sucesso',
      periodoAdicionadoSucesso: 'Período adicionado com sucesso!',
      periodoRemovidoSucesso: 'Período removido com sucesso!',
      ocorrenciaNaoAutorizadaErro: 'Ocorrência não autorizada',
      enviarMensagemErro: 'Ocorreu um erro ao enviar a mensagem!',
      baixarMensagemErro: 'Ocorreu um erro ao baixar a mensagem!',
      apenasUmAnexoPorMensagem: 'Escolha apenas um anexo para ser enviado anexo a mensagem!',
      semAcrescimoOuDesconto: 'Sem acréscimo ou desconto',
      horarioFimObrigatorioQuandoTemHorarioInicio: 'Horário de final obrigatório quando tem horário de início',
      horarioInicialObrigarioQuandoTemHorarioFim: 'Horário de início obrigatório quando tem horário de fim',
      nenhumaNotificacao: 'Nenhuma notificação encontrada',
      semNotificacoes: 'Você ainda não possui nenhuma notificação',
      nenhumGrupoDeCampoPersonalizadoVinculado: 'Nenhum grupo de campos personalizados cadastrado',
      cadastreCampoPersonalizado: 'Cadastre um ao menos um grupo de campos personalizados',
      falhaAbrirAtendimentoOnline: 'Ocorreu uma falha ao abrir o atendimento online. Tente novamente!',
      maximoArquivosUpload: 'Máximo de 10 arquivos por carregamento!',
      maximoUmArquivoUpload: 'Só é possível anexar um arquivo!',
      anexadoComSucesso: 'Arquivo anexado com sucesso',
      anexoRemovidoSucesso: 'Anexo removido com sucesso',
      justificativaSalvaSucesso: 'Justificativa salva com sucesso!',
      justificativaRemovidaSucesso: 'Justificativa removida com sucesso!',
      recursoRemovidoSucesso: 'Recurso removido com sucesso',
      boletoCanceladoSucesso: 'Boleto cancelado com sucesso',
      nenhumaSecaoDisponivelParaAtendimento: 'Nenhuma seção disponível para esse atendimento',
      nenhumAnexoEncontrado: 'Nenhum anexo encontrado!',
      nenhumResultadoEncontrado: 'Nenhum resultado encontrado!',
      nenhumResultadoComAFraseEncontrado: 'Nenhum resultado com a frase "{{frase}}" foi encontrado!',
      nenhumFuncionarioCadastrado: 'Nenhum funcionário cadastrado',
      nenhumFuncionarioEncontrado: 'Nenhum funcionário encontrado',
      nenhumUsuarioAssociado: 'Nenhum usuário associado',
      nenhumAtendimentoClientePossuiDesfechoPublico: 'Nenhum atendimento desse cliente possui desfecho público!',
      tituloJaQuitado: 'Título já quitado.',
      voceNaoTemAcessoEsseRecurso: 'Você não tem acesso a esse recurso!',
      voceDeseja: 'Você deseja?',
      voceNaoTemPermissaoParaRealizarEssaAcao: 'Você não tem permissão para realizar essa ação!',
      trocaPCondicaoItem:
        'Ao confirmar, você estará usando as condições de pagamento do item e os descontos por condição.',
      trocaPCondicaoPedido:
        'Ao confirmar, você estará usando a condição de pagamento do pedido e os descontos são aplicados no item.',
      contaBancariaNaoEncontrada: 'Conta bancária não encontrada!',
      erroAoRealizarUploadImagensTenteNovamente: 'Ocorreu um erro ao realizar o upload das imagens, tente novamente!',
      trocaStatusHabilitado: 'Ao habilitar todas as features do módulo, você está habilitando o módulo para empresas:',
      trocaStatusDesabilitado:
        'Ao desabilitar todas as features do módulo, você está desabilitando o módulo para empresas:',
      trocaStatusFeatureHabilitado: 'Ao habilitar a feature, você está habilitando a feature para empresas:',
      trocaStatusFeatureDesabilitado: 'Ao desabilitar a feature, você está desabilitando a feature para empresas:',
      transacaoAutorizada: 'Transação autorizada!',
      transacaoNaoAutorizada: 'Transação não autorizada!',
      porFavorAguarde: 'Por favor, aguarde...',
      casoNaoExistirInformarX: 'Caso não existir, informar {{string}}.',
      vocePossuiCaixaEmAberto: 'Você possui caixa em aberto de dias anteriores',
      voceNaoPossuiCaixaEmAberto: 'Você não possui caixa em aberto',
      valorVerificacaoIncorreto: 'Valor incorreto na verificação.',
      contaExistenteInativada: 'Existe usuário inativo com este e-mail',
      contaExistenteInativadaMensagem:
        'O usuário {{usuario}} já está com este e-mail registrado em seu acesso e está inativado. Reative o usuário para o acesso.',
      emailJaCadastrado: 'Este e-mail já está cadastrado',
      agendamentoNaoPodeSerConcluidoPacienteInativo:
        'Agendamento não pode ser concluído pois o paciente não foi reativado, por favor selecione outro paciente.',
      agendamentoNaoPodeSerConcluidoFuncionarioInativo:
        'Agendamento não pode ser concluído pois o profissional de saúde não foi reativado, por favor selecione outro profissional de saúde.',
      atendimentoNaoPodeSerIniciadoPacienteInativo:
        'Atendimento não pode ser iniciado pois o paciente não foi reativado, por favor selecione outro paciente.',
      verifiqueItens:
        'Verifique se existem alunos cadastrados para o diário de classe ou se os alunos matriculados estão dentro do período vigente do diário de classe.',
      valorParcelaObrigatorio: 'Deve ser informado o valor da parcela',
      valorMaiorQue: 'Valor deve ser maior que {{value}}'
    },

    cores: {
      neutral: 'Neutra',
      white: 'Branco',
      lightGrey: 'Cinza claro',
      mediumGrey: 'Cinza',
      darkGrey: 'Cinza escuro',
      almostBlack: 'Quase preto',
      black: 'Preto',
      primary: 'Primária',
      primaryLight: 'Primária claro',
      primaryDark: 'Primária escuro',
      secondary: 'Secundária',
      secondaryLight: 'Secundária claro',
      secondaryDark: 'Secundária escuro',
      info: 'Informatia',
      infoLight: 'Informatia claro',
      infoDark: 'Informatia escuro',
      success: 'Sucesso',
      successLight: 'Sucesso claro',
      successDark: 'Sucesso escuro',
      warning: 'Alerta',
      warningLight: 'Alerta claro',
      warningDark: 'Alerta escuro',
      error: 'Erro',
      errorLight: 'Erro claro',
      errorDark: 'Erro escuro',
      red: 'Vermelho',
      redLight: 'Vermelho claro',
      redDark: 'Vermelho escuro',
      pink: 'Rosa',
      pinkLight: 'Rosa claro',
      pinkDark: 'Rosa escuro',
      purple: 'Roxo',
      purpleLight: 'Roxo claro',
      purpleDark: 'Roxo escuro',
      deepPurple: 'Roxo profundo',
      deepPurpleLight: 'Roxo profundo claro',
      deepPurpleDark: 'Roxo profundo escuro',
      indigo: 'Índigo',
      indigoLight: 'Índigo claro',
      indigoDark: 'Índigo escuro',
      blue: 'Azul',
      blueLight: 'Azul claro',
      blueDark: 'Azul escuro',
      cyan: 'Ciano',
      cyanLight: 'Ciano claro',
      cyanDark: 'Ciano escuro',
      teal: 'Cerceta',
      tealLight: 'Cerceta claro',
      tealDark: 'Cerceta escuro',
      green: 'Verde',
      greenLight: 'Verde claro',
      greenDark: 'Verde escuro',
      lime: 'Lima',
      limeLight: 'Lima claro',
      limeDark: 'Lima escuro',
      yellow: 'Amarelo',
      yellowLight: 'Amarelo claro',
      yellowDark: 'Amarelo escuro',
      orange: 'Laranjado',
      orangeLight: 'Laranjado claro',
      orangeDark: 'Laranjado escuro',
      deepOrange: 'Laranjado profundo',
      deepOrangeLight: 'Laranjado profundo claro',
      deepOrangeDark: 'Laranjado profundo escuro',
      brown: 'Marrom',
      brownLight: 'Marrom claro',
      brownDark: 'Marrom escuro'
    },

    validacoes: {
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      email: 'E-mail inválido',
      numero: 'Campo deve ser numérico',
      separadorDecimal: 'Separadores decimais devem ser pontos',
      dezCaracteres: 'Limite máximo de 10 caracteres',
      numeroMinimo: 'Deve ser maior que ou igual a {{min}}',
      numeroMaximo: 'Deve ser menor que ou igual a {{max}}',
      numeroCartao: 'Número do cartão é inválido',
      valorMinimo: 'Deve ser maior que ou igual a $t(geral:currency) {{value, currency|BRL}}',
      valorMaximo: 'Deve ser menor que ou igual a $t(geral:currency) {{value, currency|BRL}}',
      caracteresMinimos: 'Deve ter ao mínimo {{value}} caracteres',
      caracteresMaximos: 'Deve ter ao máximo {{value}} caracteres',
      caracteresFixos: 'Deve ter {{value}} caracteres',
      quantidadeMininaNumeros: 'Deve ter ${min} ou mais números',
      obrigatorio: 'Campo obrigatório',
      senhaCurta: 'Senha muito curta',
      senhaLonga: 'Senha muito longa',
      senhasDiferentes: 'Senhas diferentes',
      sim: 'Sim',
      nao: 'Não',
      anoInvalido: 'Ano inválido',
      dataInvalida: 'Data inválida',
      dataMaximaHoje: 'Não pode ser maior que hoje',
      dataMinimaHoje: 'Não pode ser menor que hoje',
      dataDeveSerMaiorQue: 'Data deve ser maior que ou igual a {{date, date|DD/MM/YYYY}}',
      dataDeveSerMenorQue: 'Data deve ser menor que ou igual a {{date, date|DD/MM/YYYY}}',
      dataInicialObrigatoria: 'Data inicial obrigatória',
      dataJaCadastrada: 'Data já cadastrada',
      valueDeveSerMaiorQueEstoqueMinimo: 'Valor deve ser maior ou igual ao estoque mínimo',
      valueDeveSerMenorQueEstoqueMaximo: 'Valor deve ser menor ou igual ao estoque máximo',
      urlIncorreta: 'URL incorreta',
      digito: 'Digito inválido',
      senhaDeveConter: 'A senha deve conter',
      minimoCaracteres: 'Mínimo de 8 caracteres',
      conterLetras: 'Ao menos uma letra minúscula',
      conterLetraMaiuscula: 'Ao menos uma letra maiúscula',
      conterNumeros: 'Números',
      conterCaractereEspecial: 'Caracteres especiais',
      valorMaiorQue: 'Deve ser maior que {{value}}',
      valorMenorQue: 'Deve ser menor que {{value}}',
      senhaFraca: 'A senha não atende aos critérios',
      cargaHorarioSemanalMaiorCargaHorarioTotal: 'Carga horária semanal deve ser menor do que a total',
      horaInvalida: 'Hora em formato inválido',
      minutosInvalidos: 'Minutos em formato inválido',
      documentoSemConteudo: 'Modelo de documento sem conteúdo',
      horaDeveSerMaiorQue: 'Hora deve ser maior que ou igual a {{hour, hour|HH:mm}}',
      horaDeveSerMaior: 'Hora deve ser maior que {{hour, hour|HH:mm}}',
      intervaloDiario: 'Dia de transferência precisa ser 0 para intervalo diário',
      registroEspecialidadeObrigatorio: 'Registro de cargo obrigatório',
      capacidadeMaximaDeveSerMaiorQue: 'Capacidade máxima deve ser maior que ou igual a {{cap, cap}}'
    },

    navegacao: {
      concluir: 'Concluir',
      proximo: 'Próximo',
      anterior: 'Anterior',
      inicio: 'Início',
      dashboard: 'Dashboard',
      cadastro: 'Cadastro',
      clientes: 'Clientes',
      fornecedores: 'Fornecedores',
      funcionarios: 'Funcionários',
      'preferencias-do-cadastro': 'Preferências do cadastro',
      catalogo: 'Catálogo',
      produtos: 'Produtos',
      servicos: 'Serviços',
      categoria: 'Categoria',
      categorias: 'Categorias',
      estoque: 'Estoque',
      precificacoes: 'Precificações',
      'tipos-de-produto': 'Tipos de produto',
      'classificacao-de-produtos': 'Classificação de produtos',
      'preferencias-do-catalogo': 'Preferências do catálogo',
      agenda: 'Agenda',
      agendamento: 'Agendamento',
      'preferencias-da-agenda': 'Preferências da agenda',
      calendario: 'Calendário',
      calendarios: 'Calendários',
      'lista-de-espera': 'Lista de espera',
      'liberacao-de-modulos': 'Liberação de Módulos',
      financeiro: 'Financeiro',
      'financeiro-novo': 'Financeiro (Novo)',
      'bancos-e-contas': 'Bancos e contas',
      'caixa-usuario': 'Caixa usuário',
      'formas-de-pagamento': 'Formas de pagamento',
      'preferencias-do-financeiro': 'Preferências do financeiro',
      'planos-financeiros': 'Planos financeiros',
      fiscal: 'Fiscal',
      'plano-de-contas': 'Plano de contas',
      'centro-de-custos': 'Centro de custos',
      'preferencias-do-fiscal': 'Preferências do fiscal',
      relatorios: 'Relatórios',
      finalidades: 'Finalidades',
      preferencias: 'Preferências',
      'locais-de-realizacao': 'Locais de realização',
      'servicos-e-procedimentos': 'Serviços e procedimentos',
      analise: 'Análise',
      oportunidades: 'Oportunidades',
      workflows: 'Funis',
      'pre-tarefas': 'Pré-tarefas',
      contatos: 'Contatos',
      'preferencias-do-comercial': 'Preferências do comercial',
      'detalhes-personalizados': 'Detalhes personalizados',
      'graus-de-interesse': 'Graus de interesse',
      'tipos-de-compromisso': 'Tipos de compromisso',
      campanhas: 'Campanhas',
      'configuracoes-de-comissoes': 'Configurações de comissões',
      'condicoes-de-pagamentos': 'Condições de pagamentos',
      'motivos-comerciais': 'Motivos comerciais',
      'origens-do-contato': 'Origens do contato',
      tags: 'Tags',
      'status-de-exames': 'Status de exames',
      'unidades-de-medida': 'Unidades de medida',
      'usos-de-produtos': 'Usos de produtos',
      'tipos-de-contato': 'Tipos de contato realizados',
      tesouraria: 'Tesouraria',
      'controle-de-cartoes-de-credito': 'Controle de cartões de crédito',
      'controle-de-cheques': 'Controle de cheques',
      'controle-de-cartoes': 'Controle de cartões',
      'configuracoes-de-cartoes': 'Configurações de cartões',
      'configuracoes-de-cheques': 'Configurações de cheques',
      'bandeiras-de-cartoes-de-credito': 'Bandeiras de cartões de crédito',
      'credenciadoras-de-cartoes-de-credito': 'Credenciadoras de cartões de crédito',
      operacoes: 'Operações',
      'configuracoes-de-bolsa': 'Configurações de bolsa',
      contabil: 'Contábil',
      'preferencias-do-contabil': 'Preferências do contábil',
      tributacoes: 'Tributações',
      questionarios: 'Questionários',
      'configuracoes-de-convenios': 'Configurações de convênios',
      'configuracoes-de-bandeiras': 'Configurações de bandeiras',
      'configuracoes-de-credenciadoras': 'Configurações de credenciadoras',
      'configuracoes-de-descontos': 'Configurações de descontos',
      'orcamentos-compra': 'Orçamentos de compra',
      'orcamentos-venda': 'Orçamentos de venda',
      entradas: 'Entradas',
      compras: 'Compras',
      vendas: 'Vendas',
      saidas: 'Saídas',
      'contas-a-pagar': 'Contas a pagar',
      'contas-a-receber': 'Contas a receber',
      configuracoes: 'Configurações',
      empresas: 'Empresas',
      funcoes: 'Funções',
      cargos: 'Cargos',
      caixa: 'Caixa diário',
      'caixa-diario': 'Caixa diário',
      'caixa-geral': 'Caixa geral',
      comercial: 'Comercial',
      boletos: 'Controle de boletos',
      'controle-de-boletos': 'Controle de boletos',
      especialidades: 'Especialidades',
      'dashboard-do-financeiro': 'Dashboard do financeiro',
      'necessidades-especiais': 'Necessidades especiais',
      'configuracoes-de-calendarios': 'Configurações de calendários',
      'configuracao-de-calendario': 'Configuração de calendário',
      'templates-comerciais': 'Templates comerciais',
      templates: 'Templates',
      sms: 'SMS',
      'dashboard-comercial': 'Dashboard',
      salas: 'Salas',
      'configuracoes-de-pagamentos': 'Configurações de pagamentos',
      'salas-ambientes': 'Salas / Ambientes',
      'politicas-de-acesso': 'Políticas de acesso',
      'painel-de-tarefas': 'Painel de tarefas',
      'modelos-de-documentos': 'Modelos de documentos',
      documentos: 'Documentos',
      'grupos-de-documentos': 'Grupos de documentos',
      'tipos-responsavel': 'Tipos de responsáveis',
      notificacoes: 'Notificações',
      email: 'E-mail',
      'grupos-cadastro-personalizado': 'Grupos personalizados',
      'motivos-cancelamento': 'Motivos de cancelamento',
      chat: 'Chat',
      banners: 'Banners',
      dre: 'DRE',
      faturas: 'Faturas',
      banking: 'Banking',
      'dashboard-banking': 'Dashboard do banking',
      movimentos: 'Movimentos',
      extratos: 'Extratos',
      'contatos-bancarios': 'Contatos bancários',
      'assinatura-eletronica': 'Assinatura Eletrônica',
      tipos: 'Tipos',
      gerar: 'Gerar',
      whatsApp: 'WhatsApp'
    }
  },

  testeGratis: {
    estaGostandoMedplus: 'Está gostando do MedPlus?',
    estaGostandoSponte: 'Está gostando do Sponte?',
    contrateAgora: 'Contrate agora!',
    restam: 'Restam',
    xDias: '{{dias}} dias',
    seuPeriodoTeste: 'de seu período de testes.',
    ligamosPraVoce: 'Ligamos para você!',
    ouEntreEmContato: 'ou entre em contato conosco',
    ultimoDia: 'É o último dia',
    seuPeriodoTesteAcabou: 'Seu período de testes chegou ao fim :(',
    paraSeguirUsandoPlano:
      'Para seguir utilizando o MedPlus, é necessária a contratação de um plano. Para isto, ou para mais informações, entre em contato pelos canais abaixo:'
  },

  baseInativa: {
    titulo: 'Sua base está inativa',
    paraReativar:
      'Para reativar o acesso, é necessário o contato com a nossa equipe comercial através dos canais abaixo:'
  },

  chat: {
    canais: 'Canais',
    escolares: 'Escolares',
    diretas: 'Diretas',
    buttonResponder: 'Responder',
    apagar: 'Apagar Mensagem',
    abrirChat: 'Abrir chat',
    conversasDiretas: 'Conversas Diretas',
    mensagens: 'Mensagens',
    membros: 'Membros',
    membro: 'Membro',
    adicionarMembro: 'Adicionar membro',
    removerMembro: 'Remover membros',
    adicionarCanal: 'Adicionar canal',
    administrador: 'Administrador',
    entrar: 'Entrar',
    sair: 'Sair',
    sairCanal: 'Sair deste canal',
    pesquisarConversas: 'Pesquisar Canais/Mensagens',
    pesquisarPessoas: 'Pesquisar pessoas',
    pesquisarFuncionarios: 'Pesquisar Funcionários',
    novoCanal: 'Novo canal',
    novaConversa: 'Nova conversa',
    criarCanal: 'Criar canal',
    canalCriado: 'Canal criado com sucesso',
    canalExcluido: 'Canal removido com sucesso',
    editarCanal: 'Editar canal',
    canaisPublicos: 'Canais Publicos',
    publicoDoCanal: 'Público do canal',
    preferenciasDoCanal: 'Preferências do canal',
    escolhaQuemEnviaMensagem: 'Escolha quem pode enviar mensagens para esse grupo',
    novasMensagens: 'Novas Mensagens',
    novaMensagem: 'Nova mensagem',
    entrouCanal: 'Entrou no canal',
    saiuCanal: 'Saiu do canal',
    erroEntrarCanal: 'Erro ao entrar no canal',
    papelAtualizado: 'Papel atualizado',
    canalExluido: 'Canal excluído',
    canalAtualizado: 'Canal atualizado',
    mensagemApagada: 'Essa mensagem foi apagada.',
    membroAdicionado: 'Membro adicionado com sucesso',
    membroRemovido: 'Membro removido com sucesso',
    verMais: 'Ver mais',
    limparTudo: 'Limpar tudo',
    naoExcluirMensagem: 'Não pode excluir mensagens de outro usuário',
    erroIniciarConversa: 'Erro ao iniciar a conversa',
    usuarioDesativado: 'Desativado',
    usuarioRemovido: 'Removido',
    statusLeitura: 'Status de leitura',
    lidaPor: 'Lida por {{readers}} de {{total}} usuário(s)',
    entreguePara: 'Entregue para {{total}} usuário(s)',
    mensagemLida: 'Mensagem lida por',
    mensagemNaoLida: 'Mensagem ainda não lida por',
    desejaRemoverConversaCom: 'Deseja remover a conversa com ',
    responsavelPor: 'Responsável por',
    canaisInternos: 'Canais internos',
    aplicativo: 'Aplicativo',
    desejaSairDoCanal: 'Deseja sair deste canal?',
    dadosDaMensagem: 'Dados da mensagem',
    statusDeLeituraMensagem: 'Status de leitura de mensagem',
    conteudoApagado: 'Todo o conteúdo do chat foi apagado.',
    chatApagado: 'Chat apagado',
    canalContatosEscolares: 'Canal de contatos escolares',
    contatosEscolares: 'Contatos escolares',
    removerMembros:
      'Você esta prestes a remover {{number}} membros do canal. Deseja realmente continuar com a remoção?',
    removerCanal: 'Esse canal será removido junto com seu histórico, deseja mesmo remove-lo?',
    arquivoTamanhoMaximo: 'Arquivo maior que o padrão suportado de {{size}}, não será possível envia-lo!',
    arquivoTamanhoMaximoWarning: 'Máx. {{size}}',
    placeholders: {
      envioMensagem: 'Escreva uma mensagem...',
      nomeCanal: 'Nome do Canal',
      canalPrivado: 'Canal privado'
    },
    avisoInativarCanal:
      'Ao realizar a inativação, os usuários do aplicativo não conseguirão se comunicar através desse canal! Deseja realmente inativa-lo?',
    avisoReativarCanal:
      'Ao reativar o canal de contato escolar, os usuários do aplicativo poderão se comunicar novamente por esse canal! Deseja realmente reativa-lo?',
    inativarCanal: 'Inativar Canal',
    ativarCanal: 'Ativar Canal',
    ativarInativarCanalLegenda: 'Remover ou inativar / reativar',
    novasTurmasLegenda: 'Os novos usuários terão acesso a todo o histórico de conversa do canal! Deseja continuar?'
  },
  catalogo: {
    servicos: {
      titulo: 'Serviços',
      novo: 'Novo serviço',
      cadastradoSucesso: 'Serviço cadastrado com sucesso!',
      atualizadoSucesso: 'Serviço atualizado com sucesso!',
      removidoSucesso: 'Serviço removido com sucesso!',
      principaisDados: 'Principais Dados do serviço',

      preco: {
        titulo: 'Preço',
        novo: 'Novo preço',
        cadastradoSucesso: 'Preço cadastrado com sucesso!',
        atualizadoSucesso: 'Preço atualizado com sucesso!',
        removidoSucesso: 'Preço removido com sucesso!',

        profissionais: {
          titulo: 'Profissionais',
          novo: 'Novo profissional',
          edit: 'Editar profissional',
          cadastradoSucesso: 'Profissional cadastrada com sucesso!',
          atualizadoSucesso: 'Profissional atualizada com sucesso!',
          removidoSucesso: 'Profissional removida com sucesso!',
          selecioneProfissional: 'Selecione um profissional para relacionar',
          funcaoProfissional: 'Função do profissional'
        },
        condicaoDePagamento: {
          totalPlano: 'Total do plano',
          subTotal: 'Subtotal da condição',
          adicionar: 'Adicionar condição de pagamento',
          relacionar: 'Relacionar condição de pagamento',
          cadastradoSucesso: 'Condições cadastrada com sucesso!',
          saldo: 'Saldo das condições',
          totalCondicoes: 'Total das condições',
          removidoSucesso: 'Condição de pagamento removida com sucesso!',
          precoSujerido: 'Preço sugerido do item'
        }
      },

      composicao: {
        novo: 'Nova composição',
        edit: 'Editar composição',
        cadastradoSucesso: 'Composição cadastrada com sucesso!',
        atualizadoSucesso: 'Composição atualizada com sucesso!',
        removidoSucesso: 'Composição removida com sucesso!',
        utilizarItensDiscriminados: 'Utilizar itens discriminados'
      },

      tributacao: {
        novo: 'Nova tributação',
        edit: 'Editar tributação',
        cadastradoSucesso: 'Tributação cadastrada com sucesso!',
        atualizadoSucesso: 'Tributação atualizada com sucesso!',
        removidoSucesso: 'Tributação removida com sucesso!'
      },

      embalagem: {
        novo: 'Novo controle de embalagem',
        edit: 'Editar controle de embalagem',
        cadastradoSucesso: 'Embalagem cadastrada com sucesso!',
        atualizadoSucesso: 'Embalagem atualizada com sucesso!',
        removidoSucesso: 'Embalagem removida com sucesso!'
      }
    },
    produtos: {
      titulo: 'Produtos',
      novo: 'Novo produto',
      cadastradoSucesso: 'Produto cadastrado com sucesso!',
      atualizadoSucesso: 'Produto atualizado com sucesso!',
      removidoSucesso: 'Produto removido com sucesso!',
      principaisDados: 'Principais Dados do produto',

      preco: {
        titulo: 'Preço',
        novo: 'Novo preço',
        cadastradoSucesso: 'Preço cadastrado com sucesso!',
        atualizadoSucesso: 'Preço atualizado com sucesso!',
        removidoSucesso: 'Preço removido com sucesso!',

        profissionais: {
          titulo: 'Profissionais',
          novo: 'Novo profissional',
          edit: 'Editar profissional',
          cadastradoSucesso: 'Profissional cadastrada com sucesso!',
          atualizadoSucesso: 'Profissional atualizada com sucesso!',
          removidoSucesso: 'Profissional removida com sucesso!',
          selecioneProfissional: 'Selecione um profissional para relacionar',
          funcaoProfissional: 'Função do profissional'
        }
      },

      composicao: {
        novo: 'Nova composição',
        edit: 'Editar composição',
        cadastradoSucesso: 'Composição cadastrada com sucesso!',
        atualizadoSucesso: 'Composição atualizada com sucesso!',
        removidoSucesso: 'Composição removida com sucesso!'
      },

      tributacao: {
        novo: 'Nova tributação',
        edit: 'Editar tributação',
        cadastradoSucesso: 'Tributação cadastrada com sucesso!',
        atualizadoSucesso: 'Tributação atualizada com sucesso!',
        removidoSucesso: 'Tributação removida com sucesso!'
      },

      embalagem: {
        novo: 'Novo controle de embalagem',
        edit: 'Editar controle de embalagem',
        cadastradoSucesso: 'Embalagem cadastrada com sucesso!',
        atualizadoSucesso: 'Embalagem atualizada com sucesso!',
        removidoSucesso: 'Embalagem removida com sucesso!'
      }
    },

    procedimentos: {
      titulo: 'Procedimentos',
      novo: 'Novo procedimento',
      cadastradoSucesso: 'Procedimento cadastrado com sucesso!',
      atualizadoSucesso: 'Procedimento atualizado com sucesso!',
      removidoSucesso: 'Procedimento removido com sucesso!',
      principaisDados: 'Principais Dados do procedimento',

      preco: {
        titulo: 'Preço',
        novo: 'Novo preço',
        cadastradoSucesso: 'Preço cadastrada com sucesso!',
        atualizadoSucesso: 'Preço atualizada com sucesso!',
        removidoSucesso: 'Preço removida com sucesso!',

        profissionais: {
          titulo: 'Profissionais',
          novo: 'Novo profissional',
          edit: 'Editar profissional',
          cadastradoSucesso: 'Profissional cadastrada com sucesso!',
          atualizadoSucesso: 'Profissional atualizada com sucesso!',
          removidoSucesso: 'Profissional removida com sucesso!',
          selecioneProfissional: 'Selecione um profissional para relacionar',
          funcaoProfissional: 'Função do profissional'
        }
      },

      composicao: {
        novo: 'Nova composição',
        edit: 'Editar composição',
        cadastradoSucesso: 'Composição cadastrada com sucesso!',
        atualizadoSucesso: 'Composição atualizada com sucesso!',
        removidoSucesso: 'Composição removida com sucesso!'
      },

      orientacao: {
        novo: 'Nova orientação',
        edit: 'Editar orientação',
        cadastradoSucesso: 'Orientação cadastrada com sucesso!',
        atualizadoSucesso: 'Orientação atualizada com sucesso!',
        removidoSucesso: 'Orientação removida com sucesso!'
      }
    },

    preferencias: {
      categorias: {
        titulo: 'Categorias',
        novo: 'Nova categoria',
        cadastradoSucesso: 'Categoria cadastrada com sucesso!',
        atualizadoSucesso: 'Categoria atualizada com sucesso!',
        removidoSucesso: 'Categoria removida com sucesso!',
        principaisDados: 'Principais dados da categoria',
        nomeDaCategoria: 'Nome da categoria',
        categoriaPai: 'Categoria Pai',
        nomeDaCategoriaPai: 'Nome da categoria pai',

        subcategoria: {
          nome: 'Nome da subcategoria',
          cadastradoSucesso: 'Subcategoria cadastrada com sucesso!',
          removidoSucesso: 'Subcategoria removida com sucesso!'
        }
      },

      classificacaoDeProdutos: {
        titulo: 'Classificação de produtos',
        novo: 'Nova classificação de produto',
        cadastradoSucesso: 'Classificação de produto cadastrada com sucesso!',
        atualizadoSucesso: 'Classificação de produto atualizada com sucesso!',
        removidoSucesso: 'Classificação de produto removida com sucesso!'
      },

      finalidades: {
        titulo: 'Finalidades',
        novo: 'Nova finalidade',
        nome: 'Nome da finalidade',
        principaisDados: 'Principais dados da categoria',
        cadastradoSucesso: 'Finalidade cadastrada com sucesso!',
        atualizadoSucesso: 'Finalidade atualizada com sucesso!',
        removidoSucesso: 'Finalidade removida com sucesso!'
      },

      locaisDeRealizacao: {
        titulo: 'Locais de realização',
        novo: 'Novo local de realização',
        cadastradoSucesso: 'Local de realização cadastrado com sucesso!',
        atualizadoSucesso: 'Local de realização atualizado com sucesso!',
        removidoSucesso: 'Local de realização removido com sucesso!'
      },

      precificacoes: {
        titulo: 'Precificações',
        novo: 'Nova precificação',
        cadastradoSucesso: 'Precificação cadastrada com sucesso!',
        atualizadoSucesso: 'Precificação atualizada com sucesso!',
        removidoSucesso: 'Precificação removida com sucesso!',

        custosVariaveis: {
          titulo: 'Custos Variáveis',
          cadastradoSucesso: 'Custo variável adicionado com sucesso!',
          atualizadoSucesso: 'Custo variável removido com sucesso!',
          removidoSucesso: 'Custo variável removido com sucesso!'
        }
      },

      tiposDeProduto: {
        titulo: 'Tipos de produto',
        novo: 'Novo tipo de produto',
        cadastradoSucesso: 'Tipo de produto cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de produto atualizado com sucesso!',
        removidoSucesso: 'Tipo de produto removido com sucesso!'
      },

      unidadesDeMedida: {
        titulo: 'Unidades de medida',
        novo: 'Nova unidade de medida',
        cadastradoSucesso: 'Unidade de medida cadastrada com sucesso!',
        atualizadoSucesso: 'Unidade de medida atualizada com sucesso!',
        removidoSucesso: 'Unidade de medida removida com sucesso!',
        editar: 'Editar unidade de medida'
      },

      usosDeProdutos: {
        titulo: 'Usos de produtos',
        novo: 'Novo uso de produto',
        cadastradoSucesso: 'Uso de produto cadastrado com sucesso!',
        atualizadoSucesso: 'Uso de produto atualizado com sucesso!',
        removidoSucesso: 'Uso de produto removido com sucesso!',
        editar: 'Editar uso de produto'
      },

      statusDeExames: {
        titulo: 'Status de exames',
        novo: 'Novo status de exame',
        cadastradoSucesso: 'Status de exame cadastrado com sucesso!',
        atualizadoSucesso: 'Status de exame atualizado com sucesso!',
        removidoSucesso: 'Status de exame removido com sucesso!',
        editar: 'Editar status de exame'
      }
    }
  },

  comercial: {
    dashboard: {
      negociosGanhos: 'Negócios ganhos',
      orcamentosGerados: 'Orçamentos gerados',
      orcamentosEfetivados: 'Orçamentos efetivados',
      oportunidadesCriadas: 'Oportunidades criadas',
      negociosPerdidos: 'Negócios perdidos',
      portal: 'Portal',
      nfse: 'NFS-e',
      nfe: 'NF-e',
      tituloFunil: 'Funil',
      tituloDistribuicao: 'Distribuição',
      feedback: 'Nenhum registro para o filtro informado'
    },

    fluxoAutomacao: {
      novo: 'Novo fluxo',
      titulo: 'Fluxos de automação',
      nomeFluxo: 'Nome do fluxo',
      blocosFluxo: 'Blocos de Fluxo',
      arrasteSolteBlocosTela: 'Arraste e solte os blocos na tela',
      executarDatasEspecificas: 'Executar em datas específicas',
      cadastradoSucesso: 'Fluxo de automação cadastrado com sucesso!',
      atualizadoSucesso: 'Fluxo de automação atualizado com sucesso!',
      novaAcao: 'Nova ação',
      novaDecisao: 'Nova bloco de decisão',
      novaCondicao: 'Nova condição',
      nomeBloco: 'Nome do bloco',
      selecioneModuloSistema: 'Selecione um módulo do sistema',
      selecioneSubModulo: 'Selecione o sub-módulo',
      opcoesDecisao: 'Opções de decisão',
      tipoCondicao: 'Tipo de condição',
      quantoTempo: 'Quanto tempo?',
      tipoAcao: 'Tipo de ação',
      quemNotificar: 'Quem notificar?',
      adicionarEmailPersonalizado: 'Adicionar e-mail personalizado',
      mensagemEmail: 'Mensagem do e-mail'
    },

    oportunidades: {
      titulo: 'Oportunidades',
      novo: 'Nova oportunidade',
      cadastradoSucesso: 'Oportunidade cadastrada com sucesso!',
      atualizadoSucesso: 'Oportunidade atualizada com sucesso!',
      removidoSucesso: 'Oportunidade removida com sucesso!',

      financeiro: {
        orcamento: {
          novo: 'Novo orçamento'
        }
      }
    },

    painelDeTarefas: {
      titulo: 'Painel de tarefas',
      novo: 'Nova tarefa',
      cadastradoSucesso: 'Tarefa cadastrada com sucesso!',
      atualizadoSucesso: 'Tarefa atualizada com sucesso!',
      removidoSucesso: 'Tarefa removida com sucesso!',
      informacoes: 'Informações',
      nome: 'Título',
      selecioneTipoTarefa: 'Selecione o tipo da tarefa',
      faseTarefa: 'Fase da tarefa',
      verMais: 'Ver mais',
      horaInicio: 'Hora de início',
      horaTermino: 'Hora de Término',
      descricaoPlaceholder: 'Escreva aqui...',
      checklist: {
        titulo: 'Itens da lista',
        novo: 'Novo item',
        edit: 'Editar item',
        adicionar: 'Adicionar item',
        cadastradoSucesso: 'Item cadastrado com sucesso!',
        atualizadoSucesso: 'Item atualizado com sucesso!',
        removidoSucesso: 'Item removido com sucesso!',
        nome: 'Nome',
        comentario: 'Comentário',
        concluido: 'Concluído'
      },
      chart: {
        aFazer: 'A fazer',
        fazendo: 'Fazendo',
        atrasado: 'Atrasado',
        feito: 'Feito'
      },
      filtro: {
        titulo: 'Título',
        tipoTarefa: 'Tipo de tarefa',
        situacao: 'Situação',
        outro: 'Outros',
        limpar: 'Limpar'
      }
    },

    questionarios: {
      titulo: 'Questionários',
      novo: 'Novo questionário',
      cadastradoSucesso: 'Questionário cadastrado com sucesso!',
      atualizadoSucesso: 'Questionário atualizado com sucesso!',
      removidoSucesso: 'Questionário removido com sucesso!'
    },

    preferencias: {
      preTarefas: {
        titulo: 'Pré-tarefas',
        novo: 'Nova pré-tarefa',
        dados: 'Dados da pré-tarefa',
        tipo: 'Tipo de pré-tarefa',
        adiar: 'Adiar pré-tarefa',
        marcarTarefa: 'Marcar pré-tarefa sem checklist como concluída',
        cadastradoSucesso: 'Pré-tarefa cadastrada com sucesso!',
        atualizadoSucesso: 'Pré-tarefa atualizada com sucesso!',
        removidoSucesso: 'Pré-tarefa removida com sucesso!'
      },
      banners: {
        titulo: 'Banners',
        novo: 'Novo banner',
        cadastradoSucesso: 'Banner cadastrado com sucesso!',
        atualizadoSucesso: 'Banner atualizada com sucesso!',
        removidoSucesso: 'Banner removida com sucesso!',
        imagem: 'Imagem atualizada com sucesso!',
        contador: 'Você tem {{value}} caracteres restantes'
      },
      campanhas: {
        titulo: 'Campanhas',
        novo: 'Nova Campanha',
        cadastradoSucesso: 'Campanha cadastrada com sucesso!',
        atualizadoSucesso: 'Campanha atualizada com sucesso!',
        removidoSucesso: 'Campanha removida com sucesso!'
      },

      tiposDeCompromisso: {
        titulo: 'Tipos de compromisso',
        novo: 'Novo tipo de compromisso',
        cadastradoSucesso: 'Tipo de compromisso cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de compromisso atualizado com sucesso!',
        removidoSucesso: 'Tipo de compromisso removido com sucesso!'
      },

      motivosComerciais: {
        titulo: 'Motivos comerciais',
        novo: 'Novo motivo comercial',
        cadastradoSucesso: 'Motivo comercial cadastrado com sucesso!',
        atualizadoSucesso: 'Motivo comercial atualizado com sucesso!',
        removidoSucesso: 'Motivo comercial removido com sucesso!'
      },

      tiposDeContato: {
        titulo: 'Tipos de contato realizados',
        novo: 'Novo tipo de contato realizado',
        cadastradoSucesso: 'Tipo de contato realizado cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de contato realizado atualizado com sucesso!',
        removidoSucesso: 'Tipo de contato realizado removido com sucesso!'
      },

      grausDeInteresse: {
        titulo: 'Graus de interesse',
        novo: 'Novo grau de interesse',
        cadastradoSucesso: 'Grau de interesse cadastrado com sucesso!',
        atualizadoSucesso: 'Grau de interesse atualizado com sucesso!',
        removidoSucesso: 'Grau de interesse removido com sucesso!'
      },

      origensDoContato: {
        titulo: 'Origens do contato',
        novo: 'Nova Origem',
        cadastradoSucesso: 'Origem cadastrada com sucesso!',
        atualizadoSucesso: 'Origem atualizada com sucesso!',
        removidoSucesso: 'Origem removida com sucesso!'
      },

      workflows: {
        titulo: 'Funis',
        novo: 'Novo funil',
        cadastradoSucesso: 'Funil cadastrado com sucesso!',
        atualizadoSucesso: 'Funil atualizado com sucesso!',
        removidoSucesso: 'Funil removido com sucesso!',

        colunas: {
          novo: 'Nova coluna',
          dados: 'Dados da coluna',
          nome: 'Nome da coluna',
          tarefasGeradasAutomaticamente: 'Tarefas geradas automaticamente',
          selecionarTarefas: 'Selecionar tarefas',
          concluirTarefasMudancaFase: 'Concluir as tarefas para mudança de fase',
          cadastradoSucesso: 'Coluna cadastrada com sucesso!',
          atualizadoSucesso: 'Coluna atualizada com sucesso!',
          removidoSucesso: 'Coluna removida com sucesso!'
        }
      }
    }
  },

  cadastro: {
    modulos: {
      atualizadoSucesso: 'Módulos atualizado com sucesso!'
    },
    fornecedores: {
      titulo: 'Fornecedores',
      novo: 'Novo fornecedor',
      cadastradoSucesso: 'Fornecedor cadastrado com sucesso!',
      atualizadoSucesso: 'Fornecedor atualizado com sucesso!',
      removidoSucesso: 'Fornecedor removido com sucesso!',
      principaisDados: 'Principais dados do fornecedor',

      financeiro: {
        orcamentos: {
          novo: 'Novo orçamento'
        },

        pedidos: {
          novo: 'Novo pedido'
        },

        contasPagar: {
          novo: 'Nova conta a pagar'
        }
      },

      dadosClinicos: {
        precaucoes: 'Precauções',
        alergiaMedicamentosa: 'Alergia medicamentosa',
        medicamentoUsoContinuo: 'Medicamentos de uso contínuo',
        principioAtivo: 'Princípio ativo',
        informePrecaucao: 'Informe uma precaução',
        informeMedicamento: 'Informe um medicamento',
        buscarPrincipioAtivo: 'Buscar um princípio ativo',
        medicamentoCadastradoSucesso: 'Medicamento cadastrado com sucesso!',
        medicamentoRemovidoSucesso: 'Medicamento removido com sucesso!',
        tipoSanguineo: 'Tipo sanguíneo',
        tipoSanguineoCadastradoSucesso: 'Tipo sanguíneo cadastrado com sucesso!',
        tipoSanguineoRemovidoSucesso: 'Tipo sanguíneo removido com sucesso!'
      }
    },

    clientes: {
      titulo: 'Clientes',
      novo: 'Novo cliente',
      cadastradoSucesso: 'Cliente cadastrado com sucesso!',
      atualizadoSucesso: 'Cliente atualizado com sucesso!',
      removidoSucesso: 'Cliente removido com sucesso!',
      principaisDados: 'Principais dados do cliente',

      historico: {
        disciplina: {
          cadastradoSucesso: 'Disciplina cadastrada com sucesso!',
          removidoSucesso: 'Disciplina removida com sucesso!'
        }
      },

      necessidadesEspeciais: {
        titulo: 'Necessidades especiais',
        novo: 'Nova necessidade especial',
        adicionar: 'Adicionar necessidade especial',
        cadastradoSucesso: 'Necessidade especial cadastrada com sucesso!',
        atualizadoSucesso: 'Necessidade especial atualizada com sucesso!',
        removidoSucesso: 'Necessidade especial removida com sucesso!',
        necessidadeTemporaria: 'Necessidade temporária',
        terminoDaNecessidadeTemporaria: 'Término da necessidade',
        selecionarNecessidadeEspecial: 'Selecione ou cadastre uma necessidade'
      },

      dadosClinicos: {
        precaucoes: 'Precauções',
        alergiaMedicamentosa: 'Alergia medicamentosa',
        medicamentoUsoContinuo: 'Medicamentos de uso contínuo',
        principioAtivo: 'Princípio ativo',
        informePrecaucao: 'Informe uma precaução',
        informeMedicamento: 'Informe um medicamento',
        buscarPrincipioAtivo: 'Buscar um princípio ativo',
        medicamentoCadastradoSucesso: 'Medicamento cadastrado com sucesso!',
        medicamentoRemovidoSucesso: 'Medicamento removido com sucesso!',
        tipoSanguineo: 'Tipo sanguíneo',
        tipoSanguineoCadastradoSucesso: 'Tipo sanguíneo cadastrado com sucesso!',
        tipoSanguineoRemovidoSucesso: 'Tipo sanguíneo removido com sucesso!',

        alergiasMedicamentosas: {
          titulo: 'Alergias medicamentosas',
          novo: 'Nova alergia medicamentosa',
          adicionar: 'Adicionar alergia medicamentosa',
          cadastradoSucesso: 'Alergia medicamentosa cadastrada com sucesso!',
          atualizadoSucesso: 'Alergia medicamentosa atualizada com sucesso!',
          removidoSucesso: 'Alergia medicamentosa removida com sucesso!'
        }
      },

      financeiro: {
        orcamento: {
          novo: 'Novo orçamento'
        },

        contrato: {
          novo: 'Novo contrato'
        }
      },

      filtros: {
        campoPesquisa: 'Pesquise por Nome, CPF ou Data de Nascimento',

        textoAjudaPesquisa:
          'Utilize vírgula para buscar por mais de uma informação, por exemplo: "Maria da Silva, 01/01/1988"',
        textoAjudaPesquisaCnpj:
          'Utilize vírgula para buscar por mais de uma informação, por exemplo: "Maria da Silva, 578.257.040-70"'
      },

      importacao: {
        importarPlanilha: 'Importar planilha',
        downloadTemplateAluno: 'Download de template do Aluno',
        downloadTemplatePaciente: 'Download de template do Paciente',
        downloadTemplateResponsavel: 'Download de template Responsável',
        importarCadastros: 'Importar cadastros',
        importarCadastrosInfo: 'A importação dos responsáveis deve ser realizada por primeiro, para ter o vínculo.',
        criadoPor: 'Criado por',
        situacaoCadastros: 'Situação dos cadastros',
        importandoResponsaveis: 'Importando responsáveis',
        importandoAlunos: 'Importando alunos',
        importandoPacientes: 'Importando pacientes',
        cadastrosInvalidos: 'Cadastros inválidos',
        baixarCadastrosInvalidos: 'Baixar cadastros inválidos',
        desejaFecharCadastrosInvalidos: 'Deseja realmente fechar os cadastros inválidos?',
        templateImportacaoAlunos: 'Template de Importação de Alunos',
        templateImportacaoPacientes: 'Template de Importação de Pacientes',
        templateImportacaoResponsaveis: 'Template de Importação de Responsáveis',
        feedbacks: {
          importadosSucesso: 'Cadastros importados com sucesso!',
          importarArquivoErro: 'Erro ao importar arquivo!',
          importadosInvalidos: 'Você tem cadastros que apresentam inconsistência!'
        }
      }
    },

    funcionarios: {
      titulo: 'Funcionários',
      novo: 'Novo funcionário',
      cadastradoSucesso: 'Funcionário cadastrado com sucesso!',
      atualizadoSucesso: 'Funcionário atualizado com sucesso!',
      removidoSucesso: 'Funcionário removido com sucesso!',
      principaisDados: 'Principais dados do funcionário',
      especialidades: {
        titulo: 'Dados da Especialidade',
        deletadoSucesso: 'Especialidade removida com sucesso',
        criadoSucesso: 'Especialidade adicionada com sucesso',
        registro: 'Número do registro',
        novo: 'Nova especialidade',
        adicionar: 'Adicionar especialidade',
        subespecialidades: {
          adicionar: 'Adicionar subespecialidade',
          adicionadaSucesso: 'Subespecialidade adicionada com sucesso',
          removidaSucesso: 'Subespecialidade removida com sucesso'
        }
      },
      necessidadesEspeciais: {
        titulo: 'Necessidades especiais',
        novo: 'Nova necessidade especial',
        adicionar: 'Adicionar necessidade especial',
        cadastradoSucesso: 'Necessidade especial cadastrada com sucesso!',
        atualizadoSucesso: 'Necessidade especial atualizada com sucesso!',
        removidoSucesso: 'Necessidade especial removida com sucesso!',
        necessidadeTemporaria: 'Necessidade temporária',
        terminoDaNecessidadeTemporaria: 'Término da necessidade',
        selecionarNecessidadeEspecial: 'Selecione ou cadastre uma necessidade'
      },

      dadosClinicos: {
        precaucoes: 'Precauções',
        alergiaMedicamentosa: 'Alergia medicamentosa',
        medicamentoUsoContinuo: 'Medicamentos de uso contínuo',
        principioAtivo: 'Princípio ativo',
        informePrecaucao: 'Informe uma precaução',
        informeMedicamento: 'Informe um medicamento',
        buscarPrincipioAtivo: 'Buscar um princípio ativo',
        medicamentoCadastradoSucesso: 'Medicamento cadastrado com sucesso!',
        medicamentoRemovidoSucesso: 'Medicamento removido com sucesso!',
        tipoSanguineo: 'Tipo sanguíneo',
        tipoSanguineoCadastradoSucesso: 'Tipo sanguíneo cadastrado com sucesso!'
      },

      financeiro: {
        honorarios: {
          novo: 'Adicionar honorário'
        },

        contasBancarias: {
          novo: 'Adicionar conta bancária'
        },

        comissoes: {
          nomeConfiguracaoComissao: 'Nome da configuração da comissão',
          tipoCalculoComissao: 'Tipo calculo da comissão',
          tipoPagamentoComissao: 'Tipo do pagamento da comissão',
          tipoVencimentoComissao: 'Tipo de vencimento da comissão',
          nenhumaComissaoParaFuncionario: 'Não há nenhuma comissão calculada para esta configuração e funcionário',
          configuracaoDeComissao: 'Configuração de Comissão',
          configuracoesDeComissao: 'Configurações de Comissão',
          novaConfiguracaoDeComissao: 'Nova configuração de comissão',
          comissaoRemovidaSucesso: 'Configuração de comissão removida com sucesso',
          comissaoCadastradaSucesso: 'Configuração de comissão cadastrada com sucesso'
        }
      },

      termosDeUso: {
        titulo: 'Termo de uso da Sponte',
        revogarTermo: 'Revogar termo',
        motivoRevogacao: 'Motivo revogação',
        conteudoTermoAceite:
          'Ao revogar o aceite do termo, você será desconectado do Sponte. Para aceitá-lo novamente basta acessar o sistema. Deseja continuar?',
        concordoTermo: 'Li e concordo com os termos'
      },

      horarios: {
        novo: 'Novo horário',
        editar: 'Editar horário',
        cadastradoSucesso: 'Horário cadastrado com sucesso!',
        atualizadoSucesso: 'Horário atualizado com sucesso!',
        removidoSucesso: 'Horário removido com sucesso!',
        diaRemovidoSucesso: 'Horários do dia removido com sucesso!'
      }
    },

    preferencias: {
      alergias: {
        titulo: 'Alergias gerais',
        novo: 'Nova alergia',
        adicionar: 'Adicionar alergia',
        cadastradoSucesso: 'Alergia cadastrada com sucesso!',
        atualizadoSucesso: 'Alergia atualizada com sucesso!',
        removidoSucesso: 'Alergia removida com sucesso!'
      },

      cargos: {
        titulo: 'Cargos',
        novo: 'Novo cargo',
        cadastradoSucesso: 'Cargo cadastrado com sucesso!',
        atualizadoSucesso: 'Cargo atualizado com sucesso!',
        removidoSucesso: 'Cargo removido com sucesso!'
      },

      detalhesPersonalizados: {
        titulo: 'Detalhes personalizados',
        novo: 'Novo detalhe personalizado',
        personalize: 'Personalize seu calendário',
        cadastradoSucesso: 'Tipo de agendamento cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de agendamento atualizado com sucesso!',
        removidoSucesso: 'Tipo de agendamento removido com sucesso!'
      },

      condicoesDePagamento: {
        assinaturaRealizadaSucesso: 'Assinatura realizada com sucesso',
        assinaturaAlteradaSucesso: 'Assinatura alterada com sucesso',
        alterarCartaoCreditoRecorrencia: 'Alterar cartão de crédito na recorrência',
        relacionar: 'Relacionar condição de pagamento',
        campoPesquisa: 'Pesquisar condição de pagamento',
        relacionadaSucesso: 'Condição de pagamento recorrente relacionada com sucesso',
        deletadaSucesso: 'Condição de pagamento recorrente deletada com sucesso'
      },

      documentos: {
        titulo: 'Documentos',
        novo: 'Novo documento',
        cadastradoSucesso: 'Documento cadastrado com sucesso!',
        cadastradoErro: 'Ocorreu um erro ao cadastrar o documento!',
        atualizadoSucesso: 'Documento atualizado com sucesso!',
        atualizadoErro: 'Ocorreu um erro ao atualizar o documento',
        removidoSucesso: 'Documento removido com sucesso!',
        removidoErro: 'Ocorreu um erro ao remover o documento!',
        novoDocumento: 'Novo documento',
        adicionarCampo: 'Adicionar campo',
        adicionarAnexo: 'Adicionar anexo',
        criadoPor: 'Criado por',
        dataCriacao: 'Data criação',
        alteradoPor: 'Alterado por',
        dataAlteracao: 'Data alteração',
        normal: 'Normal',
        tituloEditor: 'Título',
        subtitulo: 'Subtítulo',
        citacao: 'Citação',
        desejaSalvarAntesDeSair: 'Deseja salvar as alterações antes de sair?',
        impressaoDeExames: 'Impressão de exames',
        receitaOculos: 'Receita de óculos',

        gruposDeDocumentos: {
          titulo: 'Grupos de documentos',
          novo: 'Novo grupo de documentos',
          cadastradoSucesso: 'Grupo de documentos cadastrado com sucesso!',
          cadastradoErro: 'Falha ao cadastrar grupo de documentos!',
          atualizadoSucesso: 'Grupo de documentos atualizado com sucesso!',
          atualizadoErro: 'Falha ao atualizar grupo de documentos!',
          removidoSucesso: 'Grupo de documentos removido com sucesso!',
          removidoErro: 'Falha ao remover grupo de documentos!'
        }
      },

      especialidades: {
        titulo: 'Especialidades',
        novo: 'Nova especialidade',
        nomeDaEspecialidadePai: 'Nome da especialidade pai',
        nomeDaSubespecialidade: 'Nome da subespecialidade',
        cadastradoSucesso: 'Especialidade cadastrada com sucesso!',
        atualizadoSucesso: 'Especialidade atualizada com sucesso!',
        removidoSucesso: 'Especialidade removida com sucesso!',

        subespecialidades: {
          titulo: 'Subespecialidades',
          novo: 'Nova subespecialidade',
          nomeDaSubespecialidade: 'Nome da subespecialidade',
          cadastradoSucesso: 'Subespecialidade cadastrada com sucesso!',
          atualizadoSucesso: 'Subespecialidade atualizada com sucesso!',
          removidoSucesso: 'Subespecialidade removida com sucesso!'
        }
      },

      funcoes: {
        titulo: 'Funções',
        novo: 'Nova função',
        cadastradoSucesso: 'Função cadastrada com sucesso!',
        atualizadoSucesso: 'Função atualizada com sucesso!',
        removidoSucesso: 'Função removida com sucesso!'
      },

      necessidadesEspeciais: {
        titulo: 'Necessidades especiais',
        novo: 'Nova necessidade especial',
        adicionar: 'Adicionar necessidade especial',
        cadastradoSucesso: 'Necessidade especial cadastrada com sucesso!',
        atualizadoSucesso: 'Necessidade especial atualizada com sucesso!',
        removidoSucesso: 'Necessidade especial removida com sucesso!',
        necessidadeTemporaria: 'Necessidade temporária',
        terminoDaNecessidadeTemporaria: 'Término da necessidade',
        selecionarNecessidadeEspecial: 'Selecione ou cadastre uma necessidade',
        tipos: {
          titulo: 'Tipos de necessidade especial',
          novo: 'Novo tipo de necessidade especial',
          cadastradoSucesso: 'Tipo de necessidade especial cadastrada com sucesso!',
          atualizadoSucesso: 'Tipo de necessidade especial atualizada com sucesso!',
          removidoSucesso: 'Tipo de necessidade especial removida com sucesso!'
        },
        recursos: {
          titulo: 'Recursos de necessidade especial',
          novo: 'Novo recurso de necessidade especial',
          cadastradoSucesso: 'Recurso de necessidade especial cadastrado com sucesso!',
          atualizadoSucesso: 'Recurso de necessidade especial atualizado com sucesso!',
          removidoSucesso: 'Recurso de necessidade especial removido com sucesso!'
        }
      },

      precaucoes: {
        titulo: 'Precauções',
        novo: 'Nova precaução',
        adicionar: 'Adicionar precaução',
        cadastradoSucesso: 'Precaução cadastrada com sucesso!',
        atualizadoSucesso: 'Precaução atualizada com sucesso!',
        removidoSucesso: 'Precaução removida com sucesso!'
      },

      tags: {
        titulo: 'Tags',
        novo: 'Nova tag',
        cadastradoSucesso: 'Tag cadastrada com sucesso!',
        atualizadoSucesso: 'Tag atualizada com sucesso!',
        removidoSucesso: 'Tag removida com sucesso!',
        modulosRelacionados: {
          titulo: 'Módulos relacionados',
          novo: 'Novo módulo relacionado',
          cadastradoSucesso: 'Módulo relacionado cadastrado com sucesso!',
          removidoSucesso: 'Módulo relacionado removido com sucesso!'
        }
      },

      tiposResponsavel: {
        titulo: 'Tipos de responsáveis',
        novo: 'Novo tipo de responsável',
        cadastradoSucesso: 'Tipo de responsável cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de responsável atualizado com sucesso!',
        removidoSucesso: 'Tipo de responsável removido com sucesso!'
      },

      gruposCadastroPersonalizado: {
        titulo: 'Grupos personalizados',
        novo: 'Novo',
        cadastradoSucesso: 'Grupo personalizado cadastrado com sucesso',
        atualizadoSucesso: 'Grupo personalizado atualizado com sucesso',
        removidoSucesso: 'Grupo personalizado removido com sucesso',
        disponibilidade: 'Disponibilidade',
        moduloRelacionar: 'Selecione um módulo para relacionar',
        moduloAdicionadoSucesso: 'Módulo adicionado com sucesso',
        moduloRemovidoSucesso: 'Módulo removido com sucesso',
        adicioneCampos: 'Adicione campos',
        tipoCampo: 'Tipo do campo',
        campoAdicionadoSucesso: 'Campo adicionado com sucesso',
        campoRemovidoSucesso: 'Campo removido com sucesso',
        adicionarOpcoes: 'Adicionar opções',
        adicionarItem: 'Adicionar item',
        itemAdicionadoSucesso: 'Item adicionado com sucesso',
        itemRemovidoSucesso: 'Item removido com sucesso'
      },
      motivosCancelamento: {
        titulo: 'Motivos de cancelamento da parcela',
        novo: 'Novo motivo',
        editar: 'Editar motivo',
        disponibilidade: 'Disponibilidade',
        disponivelEm: 'Disponível em',
        cadastradoSucesso: 'Motivo cadastrado com sucesso!',
        atualizadoSucesso: 'Motivo atualizado com sucesso!',
        removidoSucesso: 'Motivo removido com sucesso!'
      }
    },

    pessoa: {
      comercial: {
        oportunidades: 'Oportunidades',
        funil: 'Funil',
        fase: 'Fase',
        proximoContato: 'Próximo contato',
        ultimoContato: 'Último contato',
        novaOportunidade: 'Nova oportunidade',
        acompanhamentos: 'Acompanhamentos',
        novoAcompanhamento: 'Novo acompanhamento',
        comentario: 'Comentário',
        usuario: 'Usuário',
        data: 'Data',
        hora: 'Hora',
        tarefa: 'Tarefa',
        tipo: 'Tipo'
      }
    },
    liberacaoDeModulos: {
      titulo: 'Liberação de Módulos',
      atualizadoSucesso: 'Liberação de modulo atualizada com sucesso!'
    }
  },

  agenda: {
    calendario: {
      titulo: 'Calendários',
      novo: 'Novo calendário',
      cadastradoSucesso: 'Calendário cadastrado com sucesso!',
      atualizadoSucesso: 'Calendário atualizado com sucesso!',
      removidoSucesso: 'Calendário removido com sucesso!',
      selecionarCor: 'Selecione uma cor',
      selecionarCorEQuantidadeDeModulos: 'Selecione uma cor e a quantidade de módulos',
      corSeraAplicadaEmTodosOsPeriodos: 'A cor será aplicada em todos os períodos',
      quantidadeDeModulosASeremGerados: 'Quantidade de módulos a serem gerados'
    },

    preferencias: {
      configuracoesDeCalendarios: {
        titulo: 'Configurações de calendários',
        novo: 'Nova configuração de calendário',
        cadastradoSucesso: 'Configuração de calendário cadastrada com sucesso!',
        atualizadoSucesso: 'Configuração de calendário atualizada com sucesso!',
        removidoSucesso: 'Configuração de calendário removida com sucesso!',
        copiadoSucesso: 'Configuração de calendário copiada com sucesso!',
        calendario: 'Calendário',
        feriadosEEventos: 'Feriados e eventos',
        duracaoDeEventos: 'Duração de eventos',
        personalize: 'Personalize seu calendário',
        informacoes: 'Informações do calendário',
        usarCalendarioPadrao: 'Usar calendário padrão',
        informePeriodo: 'Informe ao menos 1 período',
        alterarCalendario: 'Alterar calendário',
        confirmarAlterarCalendario:
          'A alteração do Tipo de Período afetará diretamente nas configurações na aba de Período, deseja confirmar?',
        localEvento: 'Local do evento',
        custoEvento: 'Custo do evento',
        atualizarConfiguracoesDeAula: 'Atualizar configurações de aula',
        atualizarParaAnoLetivo: 'Atualizar para o ano letivo',
        atualizarRegistrosPosterioresADataAtual: 'Atualizar registros posteriores a data atual',
        naoPodeReplicarConfiguracaoCalendario:
          'Configurações de horário não podem ser alteradas por conter turmas com frequência lançadas e/ou aulas confirmadas',
        replicarConfiguracaoCalendarioTooltip:
          'Atenção: As aulas cadastradas no diário de classe são removidas ao atualizar as configurações de horário',
        confirmaReplicaoConfiguracaoCalendario:
          'As alterações realizadas no calendário serão aplicadas em todos os calendários vinculados às turmas selecionadas. Deseja realmente continuar com a alteração?',
        alteracaoNoCalendario: 'Alteração no calendário',
        calendarioReplicadoSucesso: 'Calendário replicado com sucesso!',

        duracoes: {
          nova: 'Nova duração',
          cadastradoSucesso: 'Duração cadastrada com sucesso!',
          atualizadoSucesso: 'Duração atualizada com sucesso!',
          removidoSucesso: 'Duração removida com sucesso!'
        },

        feriados: {
          novo: 'Novo evento',
          cadastradoSucesso: 'Evento cadastrado com sucesso!',
          atualizadoSucesso: 'Evento atualizado com sucesso!',
          removidoSucesso: 'Evento removido com sucesso!',

          periodos: {
            titulo: 'Período',
            novo: 'Adicionar período',
            editar: 'Editar período',
            cadastradoSucesso: 'Período cadastrado com sucesso!',
            atualizadoSucesso: 'Período atualizado com sucesso!',
            removidoSucesso: 'Período removido com sucesso!'
          }
        },

        horariosDeFuncionamento: {
          titulo: 'Horários de funcionamento',
          novo: 'Novo horário de funcionamento',
          cadastradoSucesso: 'Horário de funcionamento cadastrado com sucesso!',
          atualizadoSucesso: 'Horário de funcionamento atualizado com sucesso!',
          removidoSucesso: 'Horário de funcionamento removido com sucesso!',

          periodos: {
            titulo: 'Configurações de horários',
            novo: 'Nova configuração de horário',
            editar: 'Editar configuração de horário',
            cadastradoSucesso: 'Configuração de horário cadastrada com sucesso!',
            atualizadoSucesso: 'Configuração de horário atualizada com sucesso!',
            removidoSucesso: 'Configuração de horário removida com sucesso!',
            tipoFaltaDisciplina: 'Tipo de falta por disciplina',
            tipoFaltaDia: 'Tipo de falta por dia',
            intervalos: {
              cadastradoSucesso: 'Intervalo cadastrado com sucesso!',
              atualizadoSucesso: 'Intervalo atualizado com sucesso!',
              removidoSucesso: 'Intervalo removido com sucesso!',
              titulo: 'Intervalo',
              novo: 'Novo Intervalo',
              adicionar: 'Adicionar intervalo'
            }
          }
        },

        periodos: {
          titulo: 'Configurações de aula',
          tituloTurma: 'Configurações de horários da turma',
          selecionarHorarios: 'Selecionar configurações de horário',
          novo: 'Nova configuração de aula',
          editar: 'Editar configuração de aula',
          nomeConfiguracao: 'Nome da configuração',
          diasSemanaConfigurados: 'Dias da semana configurados',
          horaInicioFim: 'Hora de início e fim',
          vigencia: 'Vigência',
          numeroPeriodos: 'Número de períodos',
          aulaCadastradaSucesso: 'Aula cadastrada com sucesso!',
          aulaRemovidaSucesso: 'Aula removida com sucesso!',
          cadastradoSucesso: 'Configuração de aula cadastrada com sucesso!',
          atualizadoSucesso: 'Configuração de aula atualizada com sucesso!',
          removidoSucesso: 'Configuração de aula removida com sucesso!',
          configuracaoValidaDe: 'Configuração válida de',
          configuracaoValidaAte: 'Configuração válida até',
          configurarAula: 'Configurar aula',
          listaHorarios: 'Lista de horários',
          configDuracaoAula: 'Configuração de duração da aula',
          selecionarTipo: 'Selecionar o tipo'
        },

        recursos: {
          novoRecurso: 'Novo recurso',
          adicionarRecursos: 'Adicionar recursos',
          professor: 'Professor',
          equipamento: 'Equipamento'
        }
      }
    }
  },

  contabil: {
    centroDeCustos: {
      titulo: 'Centro de Custos',
      novo: 'Novo centro de custos',
      cadastradoSucesso: 'Centro de custos cadastrado com sucesso!',
      atualizadoSucesso: 'Centro de custos atualizado com sucesso!',
      removidoSucesso: 'Centro de custos removido com sucesso!',
      editarCentroDeCustos: 'Editar centro de custos',
      selecionePlanoDeContas: 'Selecione um plano de contas',
      nome: 'Nome do centro de custo'
    },

    dre: {
      receitasVendasBrutas: 'Receitas de vendas brutas',
      gastosDespesas: 'Gastos e despesas',
      resultadoOperacional: 'Resultado Operacional',
      divisaoReceitas: 'Divisão de receitas',
      divisaoDespesas: 'Divisão de despesas',
      resultadoExercicio: 'Resultado do exercício',
      estrutura: 'Estrutura',
      planejado: 'Planejado',
      realizado: 'Realizado',
      variacao: 'Variação',
      competencia: 'Competência',
      caixa: 'Caixa',
      regime: 'Regime',
      preDefinido: 'Pré-definido',
      colunas: 'Colunas',
      aplicar: 'Aplicar',
      periodo: 'Período',
      de: 'De',
      ate: 'Até',
      exibeRegimePor: 'Exibe regime por:',
      feedbacks: {
        naoTemLancamentoPlanoConta: 'Não tem nada lançado nesse plano conta.',
        semDadosNessePeriodo: 'Sem dados nesse período'
      }
    },

    planoDeContas: {
      titulo: 'Planos de Contas',
      novo: 'Novo plano de contas',
      cadastradoSucesso: 'Plano de contas cadastrado com sucesso!',
      atualizadoSucesso: 'Plano de contas atualizado com sucesso!',
      removidoSucesso: 'Plano de contas removido com sucesso!',
      editarPlanoDeContas: 'Editar plano de contas'
    }
  },

  financeiro: {
    descontosEAcrescimos: 'Descontos e acréscimos',
    edicaoParcelas: 'Edição de parcelas',
    descontosDaFormaDePagamento: 'Descontos da forma de pagamento',
    aoEditarParcelas: 'Ao editar, todas as parcelas serão geradas novamente. Deseja realmente continuar com a edição?',
    configuracaoDescontoAvulso: 'Configurações de descontos e descontos avulsos',
    acrescimoJurosAposVencimento: 'Acréscimos e juros após vencimento',
    valorComJurosEMulta: 'Valor total com juros e multas',
    renegociarUmaParcela:
      'Você esta prestes a renegociar uma parcela de acordo com o valor e data definidos. Deseja realmente continuar com a alteração?',
    vendaAvulsa: 'Venda avulsa',

    servicos: {
      servicoJaCadastradoSerieInfo: 'Existe um serviço cadastrado para a série "{{value}}". Deseja cadastrar um novo?'
    },

    caixaUsuario: {
      titulo: 'Caixa usuário',
      novo: 'Novo lançamento',
      editar: 'Editar lançamento',
      cadastradoSucesso: 'Lançamento cadastrado com sucesso!',
      atualizadoSucesso: 'Lançamento atualizado com sucesso!',
      removidoSucesso: 'Lançamento removido com sucesso!',
      reaberturaCaixas: 'Reabertura de caixas',
      novoCaixa: 'Novo Caixa',
      fechamentoCaixa: 'Fechamento de caixa',
      recebimentoDireto: 'Recebimento direto',
      lancarRecebimento: 'Lançar recebimento',
      lancarRetirada: 'Lançar retirada',
      totalRecebimento: 'Total do recebimento',
      saldoRecebimento: 'Saldo do recebimento',
      retiradaCaixa: 'Retirada de caixa',
      totalRetirada: 'Total da retirada',
      saldoRetirada: 'Saldo da retirada',
      valorRecebimento: 'Valor do recebimento',
      saldoAbertura: 'Saldo de abertura',
      saldoTotalCaixa: 'Saldo total do caixa',
      adicoesRetiradas: 'Adições ou Retiradas',
      dataAdicaoRetirada: 'Data da adição / retirada',
      horaAdicaoRetirada: 'Horário da adição / retirada',
      subtotalMulta: 'Subtotal da multa',
      subtotalDesconto: 'Subtotal do desconto',
      dataFechamentoCaixa: 'Data de fechamento do caixa',
      caixaReabertoSucesso: 'Caixa reaberto com sucesso!',
      naoPossuiSaldoRetirada: 'Não possui saldo para lançar retirada',
      naoPossuiSaldo: 'Não possui saldo'
    },

    caixa: {
      titulo: 'Caixa diário',
      novo: 'Novo lançamento',
      editar: 'Editar lançamento',
      cadastradoSucesso: 'Lançamento cadastrado com sucesso!',
      atualizadoSucesso: 'Lançamento atualizado com sucesso!',
      removidoSucesso: 'Lançamento removido com sucesso!',

      tipoMovimento: {
        SANGRIA: 'Retirada',
        ABERTURA: 'Abertura',
        FECHAMENTO: 'Fechamento',
        SUPRIMENTO: 'Suprimento',
        TITULO_PAGAR: 'Pagamento',
        TITULO_RECEBER: 'Recebimento',
        ESTORNO_TITULO_PAGAR: 'Estorno de pagamento',
        ESTORNO_TITULO_RECEBER: 'Estorno de recebimento',
        CONSOLIDACAO: 'Consolidação',
        ESTORNO_CONSOLIDACAO: 'Estorno de consolidação'
      }
    },

    caixaGeral: {
      titulo: 'Caixa geral',
      semRegistros: 'Abra um caixa para que ele seja exibido aqui.',
      semMovimentos: 'Realize um movimento para que ele seja exibido aqui.',
      desfazerConsolidacao: 'Desfazer consolidação',
      desfazerConsolidacaoPermanentemente: 'Desfazer esta consolidação permanentemente',
      desfazer: 'Desfazer',
      desejaDesfazer: 'Deseja realmente desfazer?',
      caixaConsolidadoSucesso: 'Caixa consolidado com sucesso!',
      movimentoConsolidadoSucesso: 'Movimento consolidado com sucesso!',
      consolidacaoDesfeitaSucesso: 'Consolidação desfeita com sucesso!',
      contaDestino: 'Conta destino',
      caixaFechadoSucesso: 'Caixa fechado com sucesso!',
      caixaRetiradoSucesso: 'Retirada realizada com sucesso!',
      caixaSupridoSucesso: 'Caixa suprido com sucesso!',
      caixasComFechamentoDe: 'Caixas com fechamento de',
      movimentosComValorDe: 'Movimentos com valor de',
      formasPagamentoFechamentoCaixa: 'Formas de pagamento do fechamento do caixa',
      caixaNaoPossuiMovimento: 'O caixa não possui nenhum movimento!',
      caixasNaoPossuemMovimento: 'Os caixas não possuem nenhum movimento!',
      numeroDoCaixa: 'N° do caixa',
      dataMovimentacao: 'Data de movimentação',
      fecamentosConsolidados: 'Fechamentos consolidados',
      fecamentosNaoConsolidados: 'Fechamentos não consolidados',
      resumoFechamento: 'Resumo do fechamento',
      valorInicialCaixa: 'Valor inicial do caixa',
      valorTotalCaixa: 'Valor total do caixa',
      valorTotalEntradas: 'Valor total de entradas',
      valorTotalRetiradas: 'Valor total de retiradas',
      confirmaDesfazerConsolidacao:
        'Você esta prestes a desfazer uma consolidação de um caixa já fechado. Deseja realmente desfazer o caixa?',
      confirmaDesfazerConsolidacaoJustificativa:
        'Para concluir o desfazimento do caixa, nos conte por que ele foi desfeito.',
      somenteCaixasMesmoStatus: 'Somente caixas com mesmo status podem ser selecionados',
      detalhesConsolidacao: 'Detalhes da consolidação',

      feedbacks: {
        somenteTituloPodemSerSelecionados: 'Somente títulos podem ser selecionados!',
        somenteTitulosStatusConsolidacaoPodemSerSelecionado:
          'Somente títulos com status de consolidação podem ser selecionados!',
        somenteTitulosStatusDiferenteConsolidacaoPodemSerSelecionados:
          'Somente títulos com status diferente de consolidação podem ser selecionados!',
        estornosTitulosNaoPodemSerConsolidados: 'Estornos de títulos não podem ser consolidados!',
        movimentacoesEstornadasNaoPodemSerConsolidadas: 'Movimentações estornadas não podem ser consolidadas',
        nenhumaContaBancariaPadraoConfigurada: 'Nenhuma conta bancária padrão configurada',
        configureContaBancariaPadraoRealizarConsolidacao:
          'Configure uma conta bancária padrão para realizar uma consolidação'
      },

      contaBancaria: {
        titulo: 'Contas bancárias',
        novo: 'Nova conta bancária',
        adicionarContaPadrao: 'Adicionar conta padrão',
        filtros: {
          campoPesquisa: 'Pesquise por Nome'
        }
      }
    },

    compras: {
      orcamentos: {
        titulo: 'Orçamentos de compra',
        novo: 'Novo orçamento de compra',
        editar: 'Editar orçamento',
        cadastradoSucesso: 'Orçamento cadastrado com sucesso!',
        atualizadoSucesso: 'Orçamento atualizado com sucesso!',
        removidoSucesso: 'Orçamento removido com sucesso!',
        registradoComSucesso: 'Orçamento efetivado com sucesso!',
        desejaEfetivarOrcamento: 'Deseja efetivar este orçamento?',
        efetivarOrcamento: 'Efetivar orçamento',
        efetivados: 'Efetivados',
        pendentes: 'Pendentes',
        nenhumCadastro: 'Nenhum orçamento encontrado!'
      },

      entradas: {
        titulo: 'Entradas',
        novo: 'Nova entrada',
        editar: 'Editar entrada',
        cadastradoSucesso: 'Entrada cadastrada com sucesso!',
        atualizadoSucesso: 'Entrada atualizada com sucesso!',
        removidoSucesso: 'Entrada removida com sucesso!',
        tituloGeradoComSucesso: 'Título(s) gerado(s) com sucesso!',
        estornadoSucesso: 'Entrada estornada com sucesso!',
        nenhumCadastro: 'Nenhuma entrada encontrada!'
      },

      contasAPagar: {
        titulo: 'Contas a pagar',
        novo: 'Nova conta a pagar',
        editar: 'Editar conta a pagar',
        cadastradoSucesso: 'Conta cadastrada com sucesso!',
        atualizadoSucesso: 'Conta atualizada com sucesso!',
        removidoSucesso: 'Conta removida com sucesso!',
        quitar: 'Quitar conta',
        estornar: 'Estornar conta',
        subTotalAdiantamento: 'Subtotal do adiantamento',
        subTotalAcrescimo: 'Subtotal do acréscimo',
        subTotalDesconto: 'Subtotal de desconto',
        saldoConta: 'Saldo da conta',
        estornadoSucesso: 'Conta a pagar estornada com sucesso!',
        nenhumCadastro: 'Nenhuma conta a pagar encontrada!',
        charts: {
          quitadas: 'Quitadas',
          pendentes: 'Pendentes',
          vencidas: 'Vencidas',
          canceladas: 'Canceladas'
        }
      }
    },

    vendas: {
      orcamentos: {
        titulo: 'Orçamentos de venda',
        novo: 'Novo orçamento de venda',
        editar: 'Editar orçamento',
        cadastradoSucesso: 'Orçamento cadastrado com sucesso!',
        atualizadoSucesso: 'Orçamento atualizado com sucesso!',
        removidoSucesso: 'Orçamento removido com sucesso!',
        registradoComSucesso: 'Orçamento efetivado com sucesso!',
        desejaEfetivarOrcamento: 'Deseja efetivar este orçamento?',
        efetivarOrcamento: 'Efetivar orçamento',
        canceladoComSucesso: 'Orçamento cancelado com sucesso!',
        cancelarOrcamento: 'Cancelar orçamento',
        nenhumCadastro: 'Nenhum orçamento encontrado!',
        efetivados: 'Efetivados',
        pendentes: 'Pendentes',
        quitarEmLote: 'Quitar em lote',

        itens: {
          cadastradoSucesso: 'Item adicionado com sucesso!',
          atualizadoSucesso: 'Item atualizado com sucesso!',
          removidoSucesso: 'Item removido com sucesso!',
          itensAdicionados: 'Itens adicionados',
          selecionarProdutoServico: 'Selecionar produto/serviço',
          quantidadeProdutoServico: 'Quantidade do produto/serviço',
          selecionarTipoPreco: 'Selecionar tipo de preço',
          valorPrimeiraParcelaFixa: 'Valor da primeira parcela fixa',
          diaVencimentoPadrao: 'Dia de vencimento padrão',
          valorTotalSemDesconto: 'Valor total sem desconto',
          valorDesconto: 'Valor desconto'
        },

        composicao: {
          titulo: 'Composição',
          novo: 'Nova composição',
          editar: 'Editar composição',
          cadastradoSucesso: 'Composição cadastrada com sucesso!',
          atualizadoSucesso: 'Composição atualizada com sucesso!',
          removidoSucesso: 'Composição removida com sucesso!',
          item: 'Item',
          subTotalComCondicoes: 'Subtotal com as condições',
          totalComposicaoComCondicao: 'Total composição com condição',
          totalComposicao: 'Total composição',

          condicoes: {
            titulo: 'Condições de pagamento',
            novo: 'Adicionar condição',
            editar: 'Editar condição',
            cadastradoSucesso: 'Condição de pagamento cadastrado com sucesso!',
            atualizadoSucesso: 'Condições de Pagamento atualizado com sucesso!',
            removidoSucesso: 'Condições de Pagamento removido com sucesso!',
            descontoDeletadoSucesso: 'Desconto deletado com sucesso',
            semDados: 'Item sem condição de pagamento',
            semDadosDescricao: 'Adicione ao menos uma condição de pagamento para o item.',
            descontoAvulso: 'Desconto avulso',
            tipoDesconto: 'Tipo de desconto',
            saldoDasParcelas: 'Saldo das parcelas',
            saldoDoItem: 'Saldo do item',
            saldoTotalParcelas: 'Saldo total das parcelas',
            totalDesconto: 'Total de desconto',
            valorDesconto: 'Valor desconto',
            valorDescontoAvulso: 'Valor desconto avulso',
            totalJuros: 'Total de Juros',
            totalMulta: 'Total de Multa',
            totalDeAcrescimoDaCondicaoPagamento: 'Total de acréscimo da condição de pagamento',
            listaDescontos: 'Lista de descontos',
            descontosAdicionadoSucesso: 'Desconto adicionado com sucesso',
            descontoPontualidade: 'Desconto pontualidade',
            descontoConvenio: 'Desconto de convênio',
            configuracaoConvenio: 'Configuração de convênio',
            configuracaoDesconto: 'Configuração de desconto'
          }
        },

        charts: {
          efetivados: 'Efetivados',
          pendentes: 'Pendentes',
          vencidos: 'Vencidos',
          cancelados: 'Cancelados'
        }
      },

      saidas: {
        titulo: 'Saídas',
        novo: 'Nova saída',
        editar: 'Editar saída',
        cadastradoSucesso: 'Saída cadastrada com sucesso!',
        atualizadoSucesso: 'Saída atualizada com sucesso!',
        removidoSucesso: 'Saída removida com sucesso!',
        registradoComSucesso: 'Título(s) gerado(s) com sucesso!',
        desejaGerarTitulos: 'Deseja gerar títulos?',
        gerarTitulos: 'Gerar títulos',
        nenhumCadastro: 'Nenhuma saída encontrada!',
        nfSucesso: 'Pedido de geração da NF efetuado com sucesso!',
        erroAoGerarTituloSemVendedor: 'É necessário informar o vendedor para gerar títulos!',

        composicao: {
          titulo: 'Composição',
          novo: 'Nova composição',
          editar: 'Editar composição',
          cadastradoSucesso: 'Composição cadastrada com sucesso!',
          atualizadoSucesso: 'Composição atualizada com sucesso!',
          removidoSucesso: 'Composição removida com sucesso!',
          item: 'Item',
          subTotalComCondicoes: 'Subtotal com as condições',

          condicoes: {
            titulo: 'Condições de pagamento',
            novo: 'Gerenciar condições',
            atualizadoSucesso: 'Condição atualizada com sucesso!',
            semDados: 'Item sem condição de pagamento',
            semDadosDescricao: 'Adicione ao menos uma condição de pagamento para o item.',
            cadastradoSucesso: 'Condição cadastrada com sucesso!',
            removidoSucesso: 'Condição removida com sucesso!'
          }
        }
      },

      contasAReceber: {
        titulo: 'Contas a receber',
        novo: 'Nova conta a receber',
        editar: 'Editar conta a receber',
        cadastradoSucesso: 'Conta cadastrada com sucesso!',
        atualizadoSucesso: 'Conta atualizada com sucesso!',
        removidoSucesso: 'Conta removida com sucesso!',
        quitar: 'Quitar conta',
        estornar: 'Estornar conta',
        subTotalAdiantamento: 'Subtotal do adiantamento',
        subTotalAcrescimo: 'Subtotal do acréscimo',
        subTotalDesconto: 'Subtotal de desconto',
        saldoConta: 'Saldo da conta',
        quitarSelecionados: 'Quitar selecionados',
        configuracaoCartao: 'Configuração de cartão',
        nenhumCadastro: 'Nenhuma conta a receber encontrada!',
        gerarNfAviso: 'A conta a receber não possui item ou não utiliza a condição de pagamento do item!',
        nfSucesso: 'Pedido de geração da NF efetuado com sucesso!',
        quitarEmLote: 'Quitar em lote',
        contaPadrao: 'Conta padrão',
        formaCobranca: 'Forma de cobrança',
        acumularParcelas: 'Acumular parcelas',
        finalizarPagamento: 'Finalizar Pagamento',
        jurosDiaAposVencimento: 'Juros de {{value}}% ao dia após vencimento',
        parcelaRenegociadaSucesso: 'Parcela renegociada com sucesso',
        detalhesRenegociacao: 'Detalhes da renegociação',
        renegociacaoFeitaPor: 'Renegociação feita por {{nome}} em $t(geral:date_formatter) às $t(geral:time_formatter)',
        dadosRenegociacao: 'Dados da renegociação',
        parcelaRenegociada: 'Parcela renegociada',
        dadosCartao: 'Dados do cartão',
        numeroCartaoApenasQuatroUltimos: 'Nº do cartão (apenas os 4 últimos)',
        codigoAutorizacao: 'Código de autorização',
        taxaOperadora: 'Taxa da operadora',
        apenasParcelasQuitadas: 'Selecione apenas parcelas quitadas',
        apenasParcelasPendentes: 'Selecione apenas parcelas pendentes',
        jaPossuemBoletosGerados: 'Uma ou mais parcelas já possuem boletos gerados',
        vencimentoParcelas: 'Vencimento de parcelas',
        vencimentoParcelasAtualizado: 'O vencimento das parcelas será atualizado para o mesmo vencimento do boleto.',

        itens: {
          cadastradoSucesso: 'Item adicionado com sucesso!',
          atualizadoSucesso: 'Item atualizado com sucesso!',
          removidoSucesso: 'Item removido com sucesso!',
          itensAdicionados: 'Itens adicionados',
          selecionarProdutoServico: 'Selecionar produto/serviço',
          quantidadeProdutoServico: 'Quantidade do produto/serviço',
          selecionarTipoPreco: 'Selecionar tipo de preço',
          valorPrimeiraParcelaFixa: 'Valor da primeira parcela fixa',
          diaVencimentoPadrao: 'Dia de vencimento padrão',
          valorTotalSemDesconto: 'Valor total sem desconto',
          valorDesconto: 'Valor desconto'
        },

        checkout: {
          ambienteSeguro: 'Ambiente seguro',
          nomeCartaoDeCredito: 'Nome (igual no cartão)',
          cvvCartaoDeCredito: 'CVV',
          medpay: 'Checkout Medpay',
          spontepay: 'Checkout Spontepay',
          totalAPagar: 'Total a pagar'
        },

        charts: {
          quitadas: 'Quitadas',
          pendentes: 'Pendentes',
          vencidas: 'Vencidas',
          canceladas: 'Canceladas'
        }
      }
    },

    tesouraria: {
      boletos: {
        titulo: 'Controle de boletos',
        editar: 'Detalhes do boleto',
        reenviarNotificacaoBoleto: 'Reenviar notificação boleto',
        apresentarItensPagosInstrucao: 'Apresentar itens pagos na instrução',
        escolherTemplateSms: 'Escolher template SMS',
        escolherTemplateEmail: 'Escolher template e-mail',
        numeroBoleto: 'Número do boleto',
        valorBoleto: 'Valor do boleto',
        dataGeracao: 'Data da geração',
        nCodigoDeBarras: 'Nº do código de barras',
        linhaDigitavel: 'Linha digitável',
        dadosBoleto: 'Dados do boleto',

        notificacaoEnviadaSucesso: 'Notificação enviada com sucesso!',
        cadastradoSucesso: 'Boleto gerado com sucesso!',
        atualizadoSucesso: 'Boleto atualizado com sucesso!',
        removidoSucesso: 'Boleto removido com sucesso!',
        feedbackSecundario: 'Gere um boleto a partir de uma conta a receber.',
        boletoCreditadoSucesso: 'Boleto creditado com sucesso!',
        boletoReenviadoSucesso: 'Boleto reenviado com sucesso!',
        boletoCanceladoSucesso: 'Boleto cancelado com sucesso!',
        prestesDesvincularBoletoNumero:
          'Você está prestes a cancelar o boleto nº {{number}} e o mesmo será desvinculado da parcela.',
        desejaCancelar: 'Deseja realmente cancelar o boleto?',

        configuracoes: {
          titulo: 'Configurações de boleto',
          atualizadoSucesso: 'Configurações de boleto atualizada com sucesso!',
          diasVencimento: 'Dias antes do vencimento',
          diasValor: 'Dias para confirmar valor',
          valorTarifa: 'Valor da tarifa',
          lancarValorTarifa: 'Lançar valor da tarifa como saída',
          prazoMaximoRecebimento: 'Prazo máximo em dias para recebimento',
          operacaoPadrao: 'Operação padrão'
        }
      },
      controleDeCheques: {
        titulo: 'Controle de cheques',
        novo: 'Novo',
        editar: 'Editar',
        cadastradoSucesso: 'Cheque cadastrado com sucesso!',
        atualizadoSucesso: 'Cheque atualizado com sucesso!',
        removidoSucesso: 'Cheque removido com sucesso!',
        numeroDoCheque: 'Número do cheque',
        dataCompensacao: 'Data da compensação',
        emitente: 'Nome do emitente',
        dadosDoCliente: 'Dados do cliente'
      },
      controleDeCartoes: {
        pagador: 'Pagador',
        desejaEstornarConciliacaoBaixa: 'Deseja estornar a conciliação desta baixa?',
        conciliacaoSucesso: 'Conciliação realizada com sucesso',
        detalhesConciliacao: 'Detalhes da conciliação',
        estornoSucesso: 'Estorno realizado com sucesso',
        estornar: 'Estornar',
        pesquisar: 'Pesquise por Nome e CPF',
        charts: {
          totalTransacoes: 'Total de Transações',
          conciliados: 'Conciliadas',
          pendentes: 'Pendentes',
          estornos: 'Estornos',
          totalTaxas: 'Total de Taxas',
          saldo: 'Saldo'
        }
      }
    },

    preferencias: {
      configuracoesDePagamentos: {
        formasDePagamento: {
          titulo: 'Formas de pagamento',
          novo: 'Nova forma de pagamento',
          editar: 'Editar forma de pagamento',
          cadastradoSucesso: 'Forma de pagamento cadastrada com sucesso!',
          atualizadoSucesso: 'Forma de pagamento atualizada com sucesso!',
          removidoSucesso: 'Forma de pagamento removida com sucesso!'
        },
        condicoesDePagamentos: {
          titulo: 'Condições de pagamentos',
          novo: 'Nova condição de pagamento',
          cadastradoSucesso: 'Condição de pagamento cadastrada com sucesso!',
          atualizadoSucesso: 'Condição de pagamento atualizada com sucesso!',
          removidoSucesso: 'Condição de pagamento removida com sucesso!',

          restricoes: {
            novo: 'Nova restrição',
            cadastradoSucesso: 'Restrição cadastrada com sucesso!',
            removidoSucesso: 'Restrição removida com sucesso!'
          }
        }
      },

      configuracoesDeCartoes: {
        configuracoesDeCartoes: {
          titulo: 'Configurações de cartões',
          novo: 'Nova configuração',
          editar: 'Editar configuração',
          cadastradoSucesso: 'Configuração de cartão cadastrada com sucesso!',
          atualizadoSucesso: 'Configuração de cartão atualizada com sucesso!',
          removidoSucesso: 'Configuração de cartão removida com sucesso!',
          geraContaPagarTaxa: 'Gera conta para pagar taxa',
          compensaVencimento: 'Compensa no vencimento',
          naoCompensaVencimento: 'Não compensa no vencimento',
          configuracao: {
            titulo: 'Configurações do cartão',
            adicionar: 'Adicionar configuração',
            novo: 'Nova configuração do cartão'
          }
        },

        configuracoesDeBandeiras: {
          titulo: 'Configurações de Bandeiras',
          novo: 'Nova bandeira',
          cadastradoSucesso: 'Bandeira cadastrada com sucesso',
          atualizadoSucesso: 'Bandeira atualizada com sucesso',
          removidoSucesso: 'Bandeira removida com sucesso'
        },

        configuracoesDeCredenciadoras: {
          titulo: 'Configurações de Credenciadoras',
          novo: 'Nova credenciadora',
          cadastradoSucesso: 'Credenciadora cadastrada com sucesso',
          atualizadoSucesso: 'Credenciadora atualizada com sucesso',
          removidoSucesso: 'Credenciadora removida com sucesso'
        }
      },

      configuracoesDeDescontos: {
        configuracoesDeDescontos: {
          titulo: 'Configurações de descontos',
          novo: 'Nova configuração de desconto',
          cadastradoSucesso: 'Configuração de desconto cadastrada com sucesso!',
          atualizadoSucesso: 'Configuração de desconto atualizada com sucesso!',
          removidoSucesso: 'Configuração de desconto removida com sucesso!',

          formaDePagamento: {
            titulo: 'Formas de Pagamento',
            novo: 'Nova forma de pagamento',
            cadastradoSucesso: 'Forma de pagamento cadastrada com sucesso!',
            removidoSucesso: 'Forma de pagamento removida com sucesso!'
          },

          descontos: {
            titulo: 'Descontos',
            novo: 'Novo desconto',
            editar: 'Editar desconto',
            cadastradoSucesso: 'Desconto cadastrado com sucesso!',
            atualizadoSucesso: 'Desconto atualizado com sucesso!',
            removidoSucesso: 'Desconto removido com sucesso!',
            valorDescontoSeraAbatido:
              'O valor de desconto será abatido no valor de cada parcela e não do valor total do plano.'
          }
        },

        configuracoesDeBolsa: {
          titulo: 'Configurações de bolsa',
          novo: 'Nova configuração de bolsa',
          cadastradoSucesso: 'Configuração de bolsa cadastrada com sucesso!',
          atualizadoSucesso: 'Configuração de bolsa atualizada com sucesso!',
          removidoSucesso: 'Configuração de bolsa removida com sucesso!'
        },

        configuracoesDeConvenios: {
          titulo: 'Configurações de convênios',
          novo: 'Nova configuração de convênio',
          cadastradoSucesso: 'Configuração de convênio cadastrada com sucesso!',
          atualizadoSucesso: 'Configuração de convênio atualizada com sucesso!',
          removidoSucesso: 'Configuração de convênio removida com sucesso!',

          formaDePagamento: {
            titulo: 'Formas de Pagamento',
            novo: 'Nova forma de pagamento',
            formaDePagamentoUtilizada: 'Esta forma de pagamento já esta sendo utilizado!',
            cadastradoSucesso: 'Forma de pagamento cadastrada com sucesso!',
            removidoSucesso: 'Forma de pagamento removida com sucesso!'
          },

          desconto: {
            titulo: 'Desconto',
            novo: 'Novo desconto',
            editar: 'Editar desconto',
            cadastradoSucesso: 'Desconto cadastrado com sucesso!',
            atualizadoSucesso: 'Desconto atualizado com sucesso!',
            removidoSucesso: 'Desconto removido com sucesso!'
          }
        }
      },

      operacoes: {
        titulo: 'Operações',
        novo: 'Nova operação',
        editar: 'Editar operação',
        cadastradoSucesso: 'Operação cadastrada com sucesso',
        atualizadoSucesso: 'Operação atualizada com sucesso',
        removidoSucesso: 'Operação removida com sucesso',
        selecioneCentroDeCustos: 'Selecione um centro de custos',
        centroDeCustosUtilizado: 'Este centro de custos já esta sendo utilizado!'
      },

      configuracoesDeComissoes: {
        titulo: 'Configurações de comissões',
        novo: 'Nova configuração de comissão',
        cadastradoSucesso: 'Configuração de comissão cadastrada com sucesso!',
        atualizadoSucesso: 'Configuração de comissão atualizada com sucesso!',
        removidoSucesso: 'Configuração de comissão removida com sucesso!',

        metas: {
          titulo: 'Metas',
          novo: 'Nova meta',
          editar: 'Editar meta',
          cadastradoSucesso: 'Meta cadastrada com sucesso!',
          atualizadoSucesso: 'Meta atualizada com sucesso!',
          removidoSucesso: 'Meta removida com sucesso!'
        }
      },

      bancosEContas: {
        titulo: 'Bancos e contas',
        novo: 'Nova conta',
        cadastradoSucesso: 'Conta bancária cadastrada com sucesso!',
        atualizadoSucesso: 'Conta bancária atualizada com sucesso!',
        removidoSucesso: 'Conta bancária removida com sucesso!',

        responsaveis: {
          adicionarResponsavel: 'Adicionar responsável',
          novoResponsavel: 'Novo responsável',
          nome: 'Nome do responsável',
          tipoResponsavel: 'Tipo do responsável',
          cadastradoSucesso: 'Responsável cadastrado com sucesso!',
          removidoSucesso: 'Responsável removido com sucesso!'
        }
      }
    },

    faturas: {
      dadosDaFatura: 'Dados da Fatura',
      outrosServicos: 'Outros serviços',
      naoCompoeFatura: 'Não compõe a fatura',
      transacoesEfetuadas: 'transações efetuadas no período',
      titulo: 'Faturas',
      codigoCliente: 'Código do cliente',
      situacao: 'Situação',
      mes: 'Mês',
      ano: 'Ano',
      informarAno: 'Informe o ano',
      valorFatura: 'Valor da fatura',
      servicosUtilizados: 'Serviços utilizados',
      atualizadoSucesso: 'Fatura atualizada com sucesso!',
      empresa: 'Empresa',
      servicos: 'Serviços',
      servico: 'Serviço',
      quantidade: 'Qtde utilizada',
      valorTotal: 'Valor total',
      detalhes: 'Detalhes',
      dataEmissao: 'Data de emissão',
      numeroBoleto: 'Nº do boleto',
      numeroFiscal: 'Nº da Nota Fiscal',
      dataQuitacao: 'Data de quitação',
      valorRecebido: 'Valor recebido',
      multa: '% multa',
      tipoRecebimento: 'Tipo do Recebimento',
      conta: 'Conta',
      mensalidade: 'Mensalidade',
      configuradas: 'Licenças configuradas',
      ativas: 'Licenças ativas na base',
      valorLicenca: 'Valor da licença',
      valorMensalidade: 'Valor da mensalidade',
      resumo: 'Resumo',
      valorTotalServicos: 'Valor total dos serviços',
      valorTotalMensalidade: 'Valor total da mensalidade',
      valorTotalFatura: 'Valor total da fatura',
      servicosEmpresa: 'Serviço / Empresa',
      qtdUtilizada: 'Qtde utilizada / Qtde isenção',
      valorTarifa: 'Valor da tarifa / Valor total',
      detalhesFatura: 'Detalhes da Fatura',
      dialogTexto: 'Existe fatura anterior desse cliente em aberto. Deseja realmente fechar esta fatura?',
      naoHaServicosCadastrados: 'Não existem serviços cadastrados para essa fatura',
      fechamentoFatura: 'Fechamento da fatura',
      vencimentoFatura: 'Vencimento da fatura',
      valorServicos: 'Valor dos serviços'
    },

    mensalidades: {
      titulo: 'Mensalidades',
      atualizadoSucesso: 'Mensalidade atualizada com sucesso!',
      tarifasMensalidade: 'Tarifas de mensalidade',
      tarifasArmazenamento: 'Tarifas de armazenamento',
      valorMinimoMensalidade: 'Valor mínimo da mensalidade',
      tarifaPadraoArmazenamento: 'Valor por GB'
    }
  },

  banking: {
    dashboard: {
      contatosBancarios: 'Contatos bancários',
      paraComecarConfigure: 'Para começar, configure sua conta bancária',
      concederPermissao: 'Conceder permissão',
      ultimosXDias: 'Último(s) {{dias}} dias',
      voceNaoTemTransacoesNessePeriodo: 'Você não tem transações nesse período',
      movimentos: 'Movimentos',
      operacaoContato: 'Operação e contato',
      codigoAutenticacao: 'Código da autenticação',
      usadosRecentemente: 'Usados recentemente'
    },

    contatosBancarios: {
      titulo: 'Contatos bancários',
      novo: 'Novo contato',
      novoCadastro: 'Novo cadastro',
      confirmarDados: 'Confirmar dados',
      cadastradoSucesso: 'Contato bancário cadastrado com sucesso!',
      contaBancariaCadastradaSucessoBanking: 'Conta bancária cadastrada com sucesso no banking!'
    },

    extratos: {
      data: 'Data',
      hora: 'Hora',
      tipo: 'Tipo',
      saida: 'Saída',
      conta: 'Conta',
      valor: 'Valor',
      origem: 'Origem',
      titulos: 'Títulos',
      titulo: 'Extratos',
      destino: 'Destino',
      parcela: 'Parcela',
      contato: 'Contato',
      situacao: 'Situação',
      detalhes: 'Detalhes',
      dataHora: 'Data e Hora',
      vencimento: 'Vencimento',
      operacaoContato: 'Operação e Contato',
      codigoAutenticacao: 'Código de Autenticação'
    },

    movimentos: {
      titulo: 'Movimentos',
      novo: 'Novo movimento',
      confirmarMovimento: 'Confirmar movimento',
      desejaRealmenteMovimentarValor: 'Deseja realmente movimentar este valor?',
      selecionarTitulo: 'Selecionar título',
      contatoContaDestino: 'Contato / Conta destino',
      situacaoDataHora: 'Situação / Data e hora',
      valorTitulos: 'Valor / Títulos',
      obrigatorioSelecionarTitulo: 'Obrigatório selecionar pelo menos 1 título'
    }
  },

  fiscal: {
    documentosFiscais: {
      titulo: 'Emissão de Documentos Fiscais',
      baixaManual: 'Baixa manual',
      descricaoItem: 'Descrição do item',
      selecionarDocumentoFiscalAutorizado: 'Você só pode selecionar itens com o status "Autorizada".',

      cancelamento: {
        titulo: 'Cancelamento',
        motivoCancelamento: 'Motivo do Cancelamento',
        selecioneMotivo: 'Selecione um motivo',
        descrevaMotivo: 'Descreva o motivo do cancelamento',
        qtdNotasSelecionadas: 'Quantidade de notas selecionadas',
        canceladaSucesso: 'Nota cancelada com sucesso!'
      },

      filtros: {}
    },
    preferencias: {
      tributacoes: {
        titulo: 'Tributações',
        novo: 'Nova tributação',
        cadastradoSucesso: 'Tributação cadastrada com sucesso!',
        atualizadoSucesso: 'Tributação atualizada com sucesso!',
        removidoSucesso: 'Tributação removida com sucesso!',

        cnae: {
          novo: 'Novo CNAE',
          adicionar: 'Adicionar CNAE',
          codigoServicoPrestado: 'Cód. Serviço prestado',
          codigoServicoMunicipal: 'Cód. Serviço municipal',
          codigoTributacaoMunicipio: 'Cód. Tributação municipal',
          codigoServicoFederal: 'Cód. Serviço federal',
          servPrestado: 'Serv. Prestado',
          servMunicipal: 'Serv. Municipal',
          servFederal: 'Serv. Federal',
          cadastradoSucesso: 'CNAE cadastrado com sucesso!',
          atualizadoSucesso: 'CNAE atualizado com sucesso!',
          removidoSucesso: 'CNAE removido com sucesso!'
        },

        aliquota: {
          aliquotaIss: 'Alíquota ISS',
          aliquotaPis: 'Alíquota PIS',
          aliquotaCofins: 'Alíquota COFINS',
          aliquotaInss: 'Alíquota INSS'
        },

        configuracaoDaEmpresa: {
          aliquotaIr: 'Alíquota IR',
          aliquotaCsll: 'Alíquota CSLL'
        },

        outrasAliquotas: {
          aliquotasFederais: 'Alíquotas Federais',
          aliquotasEstaduais: 'Alíquotas Estaduais',
          aliquotasMunicipais: 'Alíquotas Municipais'
        },

        codigoFiscalDePrestacaoDeServicos: {
          cfpsIntramunicipal: 'CFPS Intramunicipal',
          cfpsIntermunicipal: 'CFPS Intermunicipal'
        },

        cfop: {
          novo: 'Novo CFOP',
          adicionar: 'Adicionar CFOP',
          cadastradoSucesso: 'CFOP cadastrado com sucesso!',
          atualizadoSucesso: 'CFOP atualizado com sucesso!',
          removidoSucesso: 'CFOP removido com sucesso!'
        }
      }
    }
  },

  assinaturaEletronica: {
    titulo: 'Assinatura eletrônica',
    novo: 'Solicitar assinatura',
    confirmarAssinatura: 'Confirmar assinatura',
    desejaAssinarTodos: 'Deseja realmente assinar todos os documentos selecionados?',
    selecionarAguardandoContratados: 'Você só pode selecionar itens com o status "Aguardando contratados".',
    configuracoes: 'Configurações de assinatura',
    detalhes: 'Detalhes da assinatura',
    solicitadaSucesso: 'Assinatura eletrônica solicitada com sucesso!',
    acoes: {
      enviadoSucesso: 'Assinatura eletrônica enviada com sucesso!',
      reenviadoSucesso: 'Assinatura eletrônica reenviada com sucesso!',
      canceladoSucesso: 'Assinatura eletrônica cancelada com sucesso!',
      assinadoSucesso: 'Documento assinado com sucesso!'
    },
    configuracao: {
      salvaSucesso: 'Configuração salva com sucesso!',
      atualizadaSucesso: 'Configuração atualizada com sucesso!',
      contratadoPadrao: {
        cadastradoSucesso: 'Contratado padrão cadastrado com sucesso!',
        removidoSucesso: 'Contratado padrão removido com sucesso!',
        atualizadoSucesso: 'Contratado padrão atualizado com sucesso!'
      },
      testemunhaPadrao: {
        cadastradoSucesso: 'Testemunha padrão cadastrada com sucesso!',
        removidoSucesso: 'Testemunha padrão removida com sucesso!',
        atualizadoSucesso: 'Testemunha padrão atualizada com sucesso!'
      }
    }
  },

  servicosContratacoes: {
    titulo: 'Serviços e contratações'
  },

  configuracoes: {
    empresas: {
      titulo: 'Empresas',
      novo: 'Nova empresa',
      cadastradoSucesso: 'Empresa cadastrada com sucesso!',
      atualizadoSucesso: 'Empresa atualizada com sucesso!',
      removidoSucesso: 'Empresa removida com sucesso!',
      autorizacao: {
        titulo: 'Autorizações',
        novo: 'Nova autorização',
        cadastradoSucesso: 'Autorização cadastrada com sucesso!',
        atualizadoSucesso: 'Autorização atualizada com sucesso!',
        removidoSucesso: 'Autorização removida com sucesso!',
        autorizacaoDeFuncionamento: 'Autorização de funcionamento',
        nucleoRegionalEducacao: 'Núcleo regional de educação',
        fundamentacaoLegal: 'Fundamentação legal',
        parecerCEE: 'Parecer CEE',
        ldben: 'LDBEN',
        dou: 'DOU',
        codigoSEEDCDE: 'Código SEED / CDE'
      },
      prestadoresEmissores: {
        cadastradoSucesso: 'Prestador/emissor cadastrado com sucesso!',
        atualizadoSucesso: 'Prestador/emissor atualizado com sucesso!',
        removidoSucesso: 'Prestador/emissor removido com sucesso!',
        certificado: {
          uploadErro: 'Erro ao enviar certificado!'
        },
        integracao: {
          reenviadoSucesso: 'Reenviado com sucesso para a integração!'
        }
      },
      configuracao: {
        dataContratacao: 'Data da contratação',
        qtdeUsuarios: 'Qtde de usuários',
        valorTarifa: 'Valor da tarifa',
        valorPorAluno: 'Valor por aluno',
        valorPorMedico: 'Valor por médico',
        valorTarifaNegociado: 'Valor de tarifa negociado',
        justificativa: 'Justificativa da alteração da tarifa',
        valorMensalidade: 'Valor da mensalidade',
        valorMinimoMensalidade: 'Valor de mensalidade mínimo',
        situacao: 'Situação',
        atributos: 'Atributos',
        endpoints: 'Endpoints',
        key: 'Key',
        value: 'Value',
        url: 'URL',
        name: 'Name',
        metod: 'Metod',

        integracoes: {
          titulo: 'Integrações',
          novaIntegracao: 'Nova integração',
          tarifa: 'Tarifa',
          qtdeInsencao: 'Qtde isenção',
          transferenciaAutomatica: 'Transferência automática (saque)',
          tarifasStone: 'Tarifas stone',
          tarifasPraticadas: 'Tarifas praticadas no período',
          taxaBoleto: 'Taxa do boleto',
          emailBoletosSemCobranca:
            'E-mails para disparo de boletos não serão cobrados, porém o envio de SMS terá cobrança',

          cadastradoSucesso: 'Integração cadastrada com sucesso!',
          atualizadoSucesso: 'Integração atualizada com sucesso!',
          removidoSucesso: 'Integração removida com sucesso!',

          contasBancarias: {
            titulo: 'Configurações bancárias de recebimento',
            novo: 'Nova configuração bancária de recebimento',
            editar: 'Editar configuração bancária de recebimento',
            cadastradoSucesso: 'Configuração bancária de recebimento cadastrada com sucesso!',
            atualizadoSucesso: 'Configuração bancária de recebimento atualizada com sucesso!',
            removidoSucesso: 'Configuração bancária de recebimento removida com sucesso!',
            mensagem: 'Ao ativar essa conta, a conta anterior será desativada!'
          }
        }
      }
    },

    configuracoesDoFinanceiro: {
      preferenciasGerais: 'Preferências gerais',
      configuracoesFinanceiras: 'Configurações financeiras',
      formaDePagamentoPadrao: 'Forma de pagamento padrão',
      contaBancariaPadrao: 'Conta bancária padrão',
      apresentarOrcamentoNoContas: 'Apresentar orçamento no contas a receber',
      diasVencimentoOrcamentoCompras: 'Dias para vencimento do orçamento de compra',
      diasVencimentoOrcamentoVenda: 'Dias para vencimento do orçamento de venda',
      modeloDeDesconto: 'Modelo de desconto',
      realizarDescontoCascata: 'Realizar desconto de modo cascata',
      periodoFechamentoObrigatorioCaixa: 'Período de fechamento obrigatório do caixa'
    },

    preferencias: {
      titulo: 'Preferências',
      atualizadoSucesso: 'Preferência atualizada com sucesso!',

      aplicativo: {
        corAplicativo: 'Cor do aplicativo',
        corAlteradaSucesso: 'Cor alterada com sucesso',
        logo: 'Logo',
        logoCadastradoSucesso: 'Logo cadastrado com sucesso',
        ativarSincronizacao: 'Ativar sincronização',
        sincronizacaoEmAndamento: 'Sincronização em andamento',
        sincronizadoSucesso: 'Sincronizado com sucesso',
        alterarImagem: 'Alterar Imagem'
      },

      integracoes: {
        titulo: 'Integrações',
        contaBancariaPadrao: 'Conta bancária padrão',
        observacaoComplementar: 'Observação complementar',
        agruparValorParcelas: 'Agrupar o valor das parcelas selecionadas'
      }
    },

    templates: {
      sms: {
        titulo: 'Modelos de SMS',
        novo: 'Novo modelo',
        cadastradoSucesso: 'Modelo de SMS cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de SMS atualizado com sucesso!',
        removidoSucesso: 'Modelo de SMS removido com sucesso!',
        escrevaMensagem: 'Escreva a mensagem',
        adicionarTags: 'Adicionar Tags:',
        mensagemDeTexto: 'Mensagem de Texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidade'
      },
      email: {
        titulo: 'Modelos de e-mail',
        novo: 'Novo modelo',
        novoTitulo: 'Novo modelo de e-mail',
        cadastradoSucesso: 'Modelo de e-mail cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de e-mail atualizado com sucesso!',
        removidoSucesso: 'Modelo de e-mail removido com sucesso!',
        escrevaMensagem: 'Escreva a mensagem',
        adicionarTags: 'Adicionar Tags:',
        mensagemDeTexto: 'Mensagem de Texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidade',
        assunto: 'Assunto'
      },
      whatsApp: {
        titulo: 'Modelos de WhatsApp',
        novo: 'Novo modelo',
        cadastradoSucesso: 'Modelo de WhatsApp cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de WhatsApp atualizado com sucesso!',
        removidoSucesso: 'Modelo de WhatsApp removido com sucesso!',
        escrevaMensagem: 'Escreva a mensagem',
        adicionarTags: 'Adicionar Tags:',
        mensagemDeTexto: 'Mensagem de Texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidade'
      }
    },

    politicasDeAcesso: {
      titulo: 'Políticas de acesso',
      novo: 'Novo perfil',
      cadastradoSucesso: 'Perfil cadastrado com sucesso!',
      atualizadoSucesso: 'Perfil atualizado com sucesso!',
      removidoSucesso: 'Perfil removido com sucesso!',
      acessoAoSistema: 'Acesso ao sistema',

      recursos: {
        titulo: 'Recursos'
      },

      usuarios: {
        titulo: 'Usuários',
        novo: 'Adicionar usuário',
        incluirPorCargo: 'Incluir usuários por cargo',
        incluirPorTipo: 'Incluir usuários por tipo',
        cadastradoSucesso: 'Usuários vinculados ao perfil!',
        removidoSucesso: 'Usuário removido com sucesso!',
        atualizadoSucesso: 'Usuário atualizado com sucesso!',
        jaVinculadoAoPerfil: 'Usuário já está vinculado a este perfil',
        confirmarUsuarioComVinculo:
          'Usuário {{usuarioNome}} já está vinculado ao perfil de acesso {{perfilOrigemNome}}. Deseja alterá-lo para o perfil {{perfilDestinoNome}}?'
      }
    },

    notificacoes: {
      titulo: 'Notificações do sistema',
      novo: 'Nova notificação',
      cadastradoSucesso: 'Notificação cadastrada com sucesso!',
      atualizadoSucesso: 'Notificação atualizada com sucesso!',
      removidoSucesso: 'Notificação removida com sucesso!',
      canaisEnvio: {
        titulo: 'Canais de envio',
        novo: 'Novo canal de envio',
        cadastradoSucesso: 'Canal de envio cadastrado com sucesso!',
        removidoSucesso: 'Canal de envio removido com sucesso!',
        tipoCanal: 'Tipo do canal',
        selecione: 'Selecione o tipo',
        selecioneTemplate: 'Selecione o template',
        nomeTemplate: 'Nome do template'
      },
      condicoesEnvio: {
        titulo: 'Quando enviar',
        novo: 'Nova condição de envio',
        cadastradoSucesso: 'Condição de envio cadastrada com sucesso!',
        removidoSucesso: 'Condição de envio removida com sucesso!',
        selecioneCondicao: 'Selecione uma condição de envio',
        intervalo: {
          hora: 'Intervalo em horas',
          dia: 'Intervalo em dias',
          semana: 'Intervalo em semanas',
          mes: 'Intervalo em meses',
          horaEnvio: 'Hora de envio'
        },
        helperText: {
          ANTES_DO_EVENTO: 'Permite selecionar um período para envio das notificações antes do evento',
          DEPOIS_DO_EVENTO: 'Permite selecionar um período para envio das notificações depois do evento',
          DIA_DO_EVENTO: 'Permite selecionar a hora para envio das notificações no dia do evento',
          CLIENTES: 'As notificações serão enviadas a todos os clientes ativos',
          FORNECEDORES: 'As notificações serão enviadas a todos os fornecedores ativos',
          FUNCIONARIOS: 'As notificações serão enviadas a todos os funcionários ativos',
          INDIVIDUAL: 'Selecione para quem a notificação deverá ser enviada'
        }
      },
      destinatarios: {
        titulo: 'Destinatários',
        novo: 'Novo destinarário ou grupo de destinatários',
        cadastradoSucesso: 'Destinatário adicionado com sucesso!',
        removidoSucesso: 'Destinatário removido com sucesso!',
        selecione: 'Selecione o destinatário',
        informeDestinatario: 'Informe o destinatário'
      }
    }
  },

  restricoes: {
    nova: 'Nova restrição',
    restricaoCadastradaSucesso: 'Restrição cadastrada com sucesso!',
    acessoAoSistema: 'Olá, atualmente seu acesso ao sistema é',
    somenteLeitura: 'Somente Leitura!',
    consulteAtendimento: 'Consulte o atendimento para mais informações.',
    contateNos: 'Contate-nos'
  },

  bandeiras: {
    nova: 'Nova bandeira',
    bandeiraCadastradaSucesso: 'Bandeira cadastrada com sucesso!'
  },

  credenciadoras: {
    nova: 'Nova credenciadora',
    credenciadoraCadastradaSucesso: 'Credenciadora cadastrada com sucesso!'
  },

  auth: {
    login: {
      titulo: 'Bem-vindo ao',
      subtitulo: 'Faça login para continuar.',
      login: 'Login',
      senha: 'Senha',
      button: 'Acessar',
      link: 'Esqueci minha senha',
      sair: 'Sair',
      usuarioNaoEncontrado: 'Usuário não encontrado',
      usuarioNaoEncontradoDialog: 'Entre em contato com o administrador para mais informações.'
    },

    domain: {
      titulo: 'Bem vindo ao',
      subtitulo: 'Para podermos identificá-lo, insira a URL da sua empresa.',
      domain: 'suaempresa',
      button: 'Próximo',
      link: 'Esqueci meu URL'
    },

    changePassword: {
      titulo: 'Alterar Senha',
      tituloPrimeiroAcesso: 'Criar uma senha',
      subtitulo: 'Pense em algo bem fácil de lembrar',
      password: 'Nova Senha',
      passwordCheck: 'Repetir Senha',
      button: 'Alterar',
      link: 'Voltar ao login',
      senhaAlterada: 'Senha alterada com sucesso!',
      linkExpirado: 'O link atual expirou ou não é mais válido!',
      soliciteUmNovoLink: 'Por favor, solicite um novo link.',
      redirecionado: 'Você será redirecionado para tela de login.'
    },

    forgotPassword: {
      titulo: 'Esqueci minha senha',
      subtitulo: 'Um código de recuperação será enviado para seu e-mail.',
      subtituloTipoLink: 'Um e-mail de recuperação de senha será enviado para o e-mail informado.',
      email: 'E-mail',
      button: 'Recuperar',
      link: 'Voltar ao login',
      codigoEnviado: 'Código de recuperação enviado para e-mail informado.',
      linkEnviado: 'Um link de recuperação foi enviado para o e-mail informado.'
    }
  },

  setup: {
    nomeCompleto: 'Nome da empresa',
    verticalMercado: 'Vertical de mercado',
    url: 'URL do cliente',
    cnpj: 'CNPJ',
    codigoContrato: 'Código do contrato',
    email: 'E-mail',
    senha: 'Senha',
    confirmaSenha: 'Confirmar senha',

    licencas: {
      titulo: 'Licenças',
      licenca: 'Tipo de licença',
      quantidade: 'Quantidade'
    },

    modulos: {
      titulo: 'Módulos'
    },

    recursos: {
      titulo: 'Recursos'
    },

    conclusao: {
      titulo: 'Conclusão',
      modulos: 'Módulos',
      licencas: 'Licenças',
      encaminhar: 'Encaminhar',
      email: 'E-mail',
      assunto: 'Assunto',
      enviarEmail: 'Enviar-me e-mail',
      voltar: 'Voltar ao início',
      'ir-para-o-sistema': 'Ir para o sistema'
    }
  },
  impressao: {
    formatoImpressao: 'Formato da impressão',
    orientacaoRelatorio: 'Orientação do relatório',
    tipo: {
      pdf: 'PDF',
      csv: 'CSV',
      html: 'HTML'
    },
    orientacao: {
      retrato: 'RETRATO',
      paisagem: 'PAISAGEM'
    }
  }
}
