export default {
  geral: {
    chartsPainel: {
      titulos: {
        usoSpontAgendaPlus: 'Uso de Sponte Agenda Plus',
        alunosEResponsaveisQueAcessaramAppTitle: '{{alunos}} Estudiantes y {{responsaveis}} Tutores este mes',
        alunosEResponsaveisQueAcessaramApp: 'Estudiantes y tutores que accedieron a la aplicación',
        turmasSincronizadasTitle: '{{turmasSincronizadas}} clases sincronizadas',
        alunosEResponsaveisSincronizadosTitle: '{{alunos}} Estudiantes y {{responsaveis}} Tutores',
        alunosEResponsaveisSincronizados: 'Estudiantes y tutores sincronizados',
        indicadores: '1ra posición - {{indicador}}',
        semIndicadores: 'No hay indicadores, ¡selecciona un filtro!'
      },
      legendasChartItem: {
        quantidadeAlunos: 'Estudiantes',
        quantidadeResponsaveis: 'Tutores',
        quantidadeTurmasSincronizadas: 'Clases sincronizadas',
        quantidadeTurmasEmAndamento: 'Clases en progreso',
        quantidadeTurmasEncerradas: 'Clases cerradas',
        quantidadeAlunosSincronizados: 'Estudiantes sincronizados',
        totalAlunos: 'Estudiantes totales',
        quantidadeResponsaveisSincronizados: 'Tutores sincronizados',
        totalResponsaveis: 'Tutores totales',
        anuncios: 'Comunicados',
        aulasComConteudos: 'Contenido multidisciplinar',
        aulasComConteudosMultidisciplinar: 'Clases con contenido',
        banners: 'Pancartas',
        canais: 'Canales',
        canaisEscolaresDiretos: 'Escolar / Directo',
        diariosInfantis: 'Diario infantil',
        documentos: 'Documentos',
        eventos: 'Eventos',
        muraisFotos: 'Mural de fotos',
        ocorrencias: 'Ocurrencias',
        parcelas: 'Financiero',
        financeiroParcelas: 'Cuotas',
        saidasAntecipadas: 'Salida anticipada',
        tarefasDeCasa: 'Tarea'
      },
      info: {
        padraoPesquisa:
          'Por defecto presentaremos datos del año en curso, si deseas obtener otro resultado solo usa los filtros!',
        padraoPesquisaMes:
          'Por defecto presentaremos datos del mes en curso, si deseas obtener otro resultado solo usa los filtros!',
        padraoPesquisaMesAno:
          'Por defecto presentaremos los datos del mes y año en cuestión, si quieres obtener otro resultado ¡solo usa los filtros! ¡Los datos del mes actual se actualizarán todos los domingos!',
        totalDeAlunosEResponsaveis: 'Número total de estudiantes y tutores: {{total}}'
      }
    },
    app: 'Sponte Educacional SP',
    selecioneAluno: 'Seleccione un estudiante',
    pedagogico: 'Pedagógico',
    contratos: 'Contratos',
    integrantes: 'Miembros',
    matriz: 'Sede',
    diarioAulas: 'Diario de clase',
    notas: 'Los grados',
    nota: 'Nota',
    individual: 'Individual',
    reposicao: 'Reemplazo',
    turno: 'Cambio',
    etapa: 'Escenario',
    curso: 'Curso',
    sala: 'Sala',
    conceitos: 'Conceptos',
    aula: 'Clase',
    serie: 'Serie',
    servicoPedagogico: 'Servicio pedagógico',
    qtdMinima: 'Cantidad mínima',
    anoLetivo: 'Año escolar',
    habilitarPeriodo: 'Habilitar período',
    calendarioPedagogico: 'Calendario de enseñanza',
    calendarioPadrao: 'Calendario estándar',
    tornarCalendarioPadrao: 'Definir calendario estándar',
    calendarioPadraoExistente:
      'Otro calendario ya es el estándar. ¿Desea cambiar el valor estándar a {{novoCalendario}}?',
    calendarioPendencias: 'Calendario y Pendiente',
    aulasPendencias: 'Clases y Pendientes',
    funcaoNaTurma: 'Rol de clase',
    equipePedagogica: 'Equipo docente',
    modalidade: 'Modalidad',
    areaDeConhecimento: 'Área de conocimiento',
    codigoInep: 'Código INEP',
    faixa: 'Distancia',
    anoFaixa: '$t(geral:ano) / $t(geral:faixa)',
    anoFaixaDe: 'En $t(geral:ano) / $t(geral:faixa)',
    anoFaixaAte: 'Hasta que $t(geral:ano) / $t(geral:faixa)',
    datasPosteriores: 'Fechas posteriores',
    matricula: 'Registro',
    matriculaSerie: '$t(geral:matricula) / $t(geral:serie)',
    precoSerie: '$t(geral:preco) / $t(geral:serie)',
    diarioDeAulas: 'Diario de clase',
    diarioDeAula: 'Diario de clase',
    diario: 'Diario',
    conteudo: 'Contenido',
    nMatricula: 'Número de registro',
    nDeAlunos: 'No de estudiantes',
    nChamada: 'No. de llamada',
    integrante: 'Integral',
    mudarDeTurma: 'Cambio de clase',
    disciplina: 'Asignaturas',
    com: ' con ',
    disciplinaSingular: 'Asignatura',
    componente: 'Componente',
    professor: 'Maestro',
    professora: 'Maestro(a)',
    auxiliar: 'Asistente',
    componenteCurricular: 'Componente curricular',
    disciplinaComponente: '$t(geral:disciplina) / $t(geral:componente)',
    selecioneUmAluno: 'Seleccione un estudiante',
    selecionePeriodo: 'Seleccione un período',
    selecioneUmConteudoParaImportar: 'Seleccione un contenido para importar',
    escrevaAqui: 'Escribe aquí...',
    mediaGeral: 'Media general',
    componenteCurricularProfessor: '$t(geral:componenteCurricular) / $t(geral:professor)',
    turmasEmAndamento: 'Clases en curso',
    turmasEncerradas: 'Clases cerradas',
    turmasCanceladas: 'Clases canceladas',
    turmasEmFormacao: 'Clases en formación',
    periodoTurno: 'Periodo / turno',
    mediaFrequencia: 'Promedio / Frecuencia',
    siglaEtapa: 'Acrónimo del paso',
    anoFaixaInicial: 'Año / Rango de inicio',
    anoFaixaFinal: 'Año / Rango final',
    serieInicial: 'Serie inicial',
    serieFinal: 'Serie final',
    posicaoHabilidade: 'Posición de habilidad',
    siglaPosicao: 'Acrónimo de posición',
    unidadeTematica: 'Unidad temática',
    selecioneUnidadeTematica: 'Seleccione una unidad temática',
    objetosDoConhecimento: 'objetos de conocimiento',
    adicionarObjetosDoConhecimento: 'Agregar objeto de conocimiento',
    aluno: 'Alumno',
    'o-que': 'Qué',
    para: 'Para',
    ordem: 'Pedido',
    clienteNumeroContrato: 'Cliente / Contrato No.',
    situacaoTurma: 'Situación / Clase',
    situacaoNumeroContrato: 'Situación / Número de contrato',
    alunoNaoEnsalado: 'El estudiante no está en ninguna habitación',
    situacaoPeriodo: 'Situación y periodo',
    numeroMatricula: 'Número de registro',
    numeroContrato: 'contrato no.',
    itemNumeroParcelas: 'Artículo / Número de cuotas',
    totalValorParcela: 'Monto total / a plazos',
    unica: 'Solamente',
    adicionarTurma: 'Agregar clase',
    totalTurmas: 'Clases totales',
    turmaComponente: 'Clase / Componente',
    turmaMediaAtual: 'Clase / Promedio actual',
    valorCargaHoraria: 'Horas de valor / crédito',
    totalTurma: 'Total de la clase',
    utilizarconfiguracoesDeFormulas: 'Usar la configuración de la fórmula',
    nomeMatricula: 'Nombre / N° Registro',
    nomeMediaAtual: 'Nombre / Promedio actual',
    nomeLegenda: 'Nombre / Título',
    nomeTemplate: 'Nombre / Plantilla',
    pesoOrdem: 'Peso / Orden',
    segundaChamada: 'Segunda llamada',
    numeroContratoSituacao: 'Contrato No. / Estado',
    gradeCurricular: 'Plan de estudios',
    gradeCurricularSistemaAvaliacao: 'Plan de Estudios and Sistema de Evaluación',
    situacaoPedagogica: 'Situación pedagógica',
    numeroChamada: 'No. de llamada',
    maisSobreCampoDeExperiencia: 'Más sobre el campo de la experiencia',
    orientacoesGeraisQuantoAoProcessoPedagogico: 'Lineamientos generales sobre el proceso pedagógico',
    direitoAprendizagem: 'Aprendiendo bien',
    presenca: 'Presencia',
    presencaMedianteConfirmacao: 'Presencia tras la confirmación',
    falta: 'Ausencia',
    faltas: 'Ausencias',
    conceitoNotas: 'Conceptos',
    conceitoAposRec: 'Conceptos después de la recuperación',
    justificativa: 'Justificación',
    visualizarJustificativa: 'Ver justificación',
    selecionetempoDuracaoAtividade: 'Seleccione el tiempo de duración de la actividad',
    campoExperiencia: 'Campo de experiencia',
    numeroAulas: 'Numero de clases',
    tempoDuracaoMinutos: 'Tiempo de ejecución en minutos',
    selecionarGrade: 'Seleccionar cuadrícula',
    selecioneUmaGrade: 'Seleccione una cuadrícula',
    cargaSemanal: 'Carga semanal',
    templateAvaliacao: 'Plantilla de evaluación',
    sistemaDeAvaliacao: 'Sistema de evaluación',
    novaDisciplinaAdicional: 'Nueva disciplina adicional',
    disciplinasAdicionais: 'Asignaturas adicionales',
    serieOuCurso: 'Serie o curso',
    dataDeRealizacao: 'Fecha de realización',
    comercial: 'Relación',
    aoMenosUmAgendamento: 'Al menos una cita',
    agendaCheia: 'Agenda completa',
    diaSelecionado: 'Dia seleccionado',
    diaBloqueado: 'Día bloqueado',
    ano: 'Año',
    mes: 'Mes',
    semana: 'Semana',
    dia: 'Día',
    competencia: 'Competencia',
    customizada: 'Personalizado',
    habilidadeEnsinoMedio: 'Habilidad de la escuela secundaria',
    habilidadeEnsinoFundamental: 'Habilidad de la escuela primaria',
    sistemaAvaliacao: 'Sistema de evaluación',
    atencao: '¡Atención!',
    edicaoConfirmacaoSistemaAvaliacao:
      'Al cambiar el sistema de evaluación, se perderá toda la información de opinión descriptiva ingresada para los miembros',
    edicaoConfirmacaoGradeSistemaAvaliacao:
      'Al cambiar el plan de estudios y el sistema de evaluación, se perderá la información relacionada con el plan de estudios y las opiniones descriptivas',
    confirmaRemocaoSistemaAvaliacao:
      'Sistema de evaluación ya vinculado a clases y/o planes de estudio. Al eliminar, se perderán todas las asociaciones. ¿Realmente quieres eliminarlo?',
    naoRemocaoSistemaAvaliacao:
      'Sistema de evaluación ya vinculado a clases y/o planes de estudio. No es posible eliminar el sistema de evaluación debido a las calificaciones ya publicadas',
    confirmaAlteracaoSistemaAvaliacao:
      'La alteración en el sistema de evaluación se reflejará directamente en las calificaciones ya publicadas. ¿De verdad quieres continuar?',
    alteracaoSistemaAvaliacao: 'Cambio en el sistema de evaluación',
    pesquisarPorTurmas: 'Buscar por clases',
    cargaHorariaSemanal: 'Carga de trabajo semanal',
    cargaHorariaAnual: 'Carga de trabajo anual',
    componenteCurricularOptativo: 'Componente curricular opcional',
    adicionarComponenteCurricular: 'Agregar componente curricular',
    numeroAula: 'Clase No.',
    planoDeEnsino: 'plan de enseñanza',
    parecerDescritivo: 'Opinión descriptiva',
    pareceres: 'Opiniones',
    linhaProgramatica: 'Línea programática',
    metodologia: 'Metodología',
    tipoComponentePai: 'Tipo / componente principal',
    cargaSemanaAnual: 'Carga semanal / anual',
    componenteSistemaAvaliacao: 'Componente / Sistema de evaluación',
    novaAula: 'Nueva clase',
    editarAula: 'Editar clase',
    organizacaoSequencial: 'Organización secuencial del plan de estudios',
    sistemaEnsino: 'Sistema educativo',
    avaliacoes: 'Evaluaciones',
    novaAvaliacao: 'Nueva evaluación',
    configcuracaoDeBolsa: 'Configuración de la bolsa',
    saldoConta: 'Saldo de la cuenta',
    horarioAula: 'Horario de clases',
    dadosClinicos: 'Récord de Salud',
    tempoAgendamentoAntesAula: 'Hora de programar antes de la clase',
    subHabilitacoes: 'Asignaturas',
    habilitacoes: 'Calificaciones',
    habilitacao: 'Calificación',
    subHabilitacao: 'Subcalificación',
    formula: 'Fórmula',
    situacaoAluno: 'Situación del estudiante',
    templateAvaliacaoPadrao: 'Plantilla de evaluación estándar',
    sistemaAvaliacaoPadrao: 'Sistema de clasificación estándar',
    editarFormula: 'Editar fórmula',
    modeloPadrao: 'Plantilla predeterminada',
    adicionarModelos: 'Agregar Plantillas',
    definirModeloAvaliacaoPadrao: 'Definir modelo de evaluación por defecto',
    modeloPadraoExistente:
      'El modelo {{modeloPadrao}} ya es el predeterminado. ¿Desea cambiar el valor predeterminado a {{modeloAtual}}?',
    intervaloAvaliacao: 'Intervalo de evaluación',
    calculoMediaFinal: 'Cálculo de la media final',
    calculoSubDisciplina: 'Cálculo de subdisciplina',
    mediaMinima: 'Promedio mínimo',
    pesoMedia: 'Peso promedio',
    frequenciaMinima: 'Frecuencia mínima',
    utilizarArredondamento: 'Usar redondo',
    arredondamento: 'Redondeo',
    maxPontosAnuais: 'Puntos máximos anuales',
    validarNotaMaximaRecuperacao: 'Validar la puntuación máxima de recuperación',
    utilizaPontosFracionarios: 'Usa puntos fraccionarios',
    utilizaHabilidadesDescritivas: 'Usa habilidades descriptivas',
    utilizaRecuperacaoParcial: 'Usar recuperación parcial',
    utilizarRecuperacaoParcial: 'Usar recuperación parcial',
    utilizaAgrupamentoAvaliacoes: 'Utiliza agrupación de evaluaciones',
    utilizaExameFinal: 'Usar examen final',
    utilizaRecuperacao: 'Usar la recuperación',
    ignorarMenorNotaAgrupamento: 'Ignorar el grado más bajo de agrupación',
    desconsiderarAvaliacaoNotaNaoInformada: 'Ignorar evaluaciones si no se informa la calificación',
    desconsiderarNotaRecuperacaoMenorQue: 'Ignore la puntuación de recuperación si es inferior a',
    desconsiderarNotaRecuperacaoMenorQueMediaMinima:
      'Ignorar el grado de recuperación si es menor que el promedio mínimo',
    desconsiderarNotaRecuperacaoMenorQueNotaPeriodo:
      'Ignore el puntaje de recuperación si es menor que el puntaje del período',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQue: 'Ignore la puntuación de recuperación si es inferior a',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQueNotaPeriodo:
      'Ignorar el grado de recuperación si es inferior al grado del período',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQueZero:
      'Ignore la puntuación de recuperación si el promedio después de la recuperación es inferior a 0',
    desconsiderarNotaExameFinalMenorQueMedia:
      'Ignore la calificación del examen final si es inferior al promedio después del examen final',
    considerarNotaExameFinalMediaSuperiorMediaMinima:
      'Considere la calificación del examen final, incluso si el promedio del estudiante es más alto que el promedio mínimo.',
    ignorarPesosCalcularMediaAposExame: 'Ignore los pesos para calcular el promedio después del examen final',
    configuracaoOuRecuperacaoParcial: 'Configuración o recuperación parcial',
    agrupamentoAvaliacoes: 'Agrupación de evaluaciones',
    mediaNotasParciais: 'Promedio de calificaciones parciales',
    mediasParciaisSaoNotasPeriodo: 'Los promedios parciales son las calificaciones del período',
    calcularMediaEntreNotas: 'Calcular promedio entre calificaciones',
    somarAsNotas: 'Agregar las notas',
    calculoFormulaCliente: 'Cálculo libre (fórmula creada por el usuario)',
    substituirNotaParcialPelaRecuperacao: 'Reemplazar la nota parcial por la de recuperación',
    substituirMediaNotasParciaisPelaRecuperacao:
      'Reemplazar el promedio de los puntajes parciales con el puntaje más alto entre las recuperaciones',
    configuracoesRecuperacao: 'configuración de recuperación',
    seMediaFinalUltrapassar: 'Si la media final supera',
    manterNotaExameFinal: 'Mantener la calificación del examen final',
    manterMaiorNotaEntreMediaAnualNotaExameFinal:
      'Mantener la calificación más alta entre el promedio anual y la calificación del examen final',
    calcularMediaEntreMediaAnualNotaExameFinal:
      'Calcule el promedio entre el promedio anual y la calificación del examen final',
    somarMediaAnualNotaExameFinal: 'Agregue el promedio anual y la calificación del examen final',
    desconsiderarNotaExameFinalSeElaForMenorMediaAposExameFinal:
      'Ignorar la calificación del examen final si es inferior al promedio después del examen final',
    ignorarPesosParaCalcularMediaAposExame: 'Ignorar pesos para calcular el promedio después del examen',
    semRecuperacao: 'Sin recuperación',
    cadaPeriodo: 'Cada periodo',
    cadaSemestre: 'Cada semestre',
    calcularMediaEntreNotaRecuperacao: 'Calcula promedio entre la nota y la recuperación',
    manterMaiorNotaEntreMediaRecuperacao: 'Mantener la puntuación más alta entre la calificación y la recuperación',
    somarMediaBimestreRecuperacao: 'Suma el promedio bimestral y el grado de recuperación',
    mediaAposRecuperacao: 'Promedio después de la recuperación',
    exameFinal: 'Examen final',
    modelosAvaliacao: 'Modelos de evaluación',
    configuracoesExameFinal: 'configuración del examen final',
    maximaDaRecuperacao: 'Recuperación máxima',
    mediaAposExameFinal: 'Promedio después del examen final',
    mediaAposExame: 'Promedio después del examen',
    utilizarConselhoDeClasse: 'Usar consejo de clase',
    maximaDoExameFinal: 'Máximo del examen fina',
    mediaParaExameFinal: 'Promedio del examen final',
    numeroMaximoPontos: 'Núm. máx. de puntos',
    notasParciais: 'Notas parciales',
    mediaParcial: 'Promedio parcial',
    justificativaVinculada: 'Justificación adjunta',
    removerJustificativa: 'Eliminar justificación',
    justificativasEObservacoes: 'Justificaciones y comentarios',
    removerJustificativaVinculada: 'Eliminar justificación vinculada',
    confirmacaoRemoverJustificativa:
      'Está a punto de eliminar una justificación ya vinculada. ¿Realmente desea eliminar la justificación?',
    nomenclatura: 'Nomenclatura',
    peso: 'Peso',
    notaMaxima: 'Marca completa',
    maximoPontos: 'Puntos máximos',
    mediaResultante: 'Promedio resultante',
    tipoAvaliacao: 'Tipo de evaluación',
    mediaEFrequencia: 'Promedio y frecuencia',
    adicionarTemplate: 'Agregar plantilla',
    adicionarModelo: 'Agregar modelo',
    numeroDeAvaliacoes: 'No. de Reseñas',
    definicoesAula: 'Definiciones de clases',
    teorica: 'Teórico',
    recuperacao: 'Recuperación',
    casasDecimais: 'Casas decimales',
    medias: 'Promedios',
    mediasParciais: 'Promedios parciales',
    recuperacoes: 'Recuperaciones',
    podeRetirarDependente: 'Puede retirar dependiente',
    nBimestre: '{{number}}º Dos meses',
    nBim: '{{number}}º Bim',
    nTri: '{{number}}º Trim',
    nSem: '{{number}}º Sem',
    nTrimestre: '{{number}}º Cuarto',
    nSemestre: '{{number}}º Semestre',
    nPeriodo: '{{number}}º Curso del tiempo',
    nModulo: '{{number}}º módulo',
    modulo: 'Módulo',
    selecioneUmaTurma: 'Seleccione una clase',
    conselhoDeClasse: 'Consejo de clase',
    somaDasNotas: 'Suma de notas',
    mediaFinal: 'Medios finales',
    mediaAnual: 'Porcentaje anual',
    emExame: 'En examen',
    exame: 'Examen',
    media: 'Promedio',
    aprovado: 'Aprobado',
    reprovado: 'Desaprobado',
    cursando: 'Estudiando',
    naoDefinida: 'No definida',
    naoDefinido: 'No definido',
    templatesParciais: 'Plantillas parciales',
    templatesFinais: 'Plantillas finales',
    parcialFinal: 'Parcial / Final',
    final: 'Final',
    parcial: 'Parcial',
    planoDeAula: 'Plan de clase',
    objetivo: 'Objetivo',
    conteudos: 'Contenido',
    recurso: 'Recurso',
    recursos: 'Recursos',
    habilidades: 'Habilidades',
    boletimEscolar: 'Boletín escolar',
    totalDeFaltas: 'Ausencias totales',
    frequenciaDoAluno: 'Asistencia de los estudiantes',
    numeroDeFaltas: 'Número de ausencias',
    diretorEscola: 'Director de la escuela',
    secretarioEscola: 'Secretaria de la escuela',
    mediaAtual: 'Promedio actual',
    rescisoes: 'Terminaciones',
    alunosAtivos: 'Estudiantes activos',
    alunosRematriculados: 'Estudiantes reinscritos',
    contratosAtivos: 'Contratos activos',
    aniversariantesDia: 'Cumpleaños del día',
    mediaAlunosTurma: 'Estudiantes promedio/ Clase',
    quantidadeAlunosVigentes: 'Número de estudiantes actuales',
    brasaoDoEstado: 'Escudo de armas del estado',
    mantenedora: 'Mantenedor',
    representante: 'Representante',
    mantenedoraResponsavel: 'Mantenedor / Representante',
    anosFaixa: 'Años / Faixas',
    valorInicial: 'Valor inicial',
    valorFinal: 'Valor final',
    conceito: 'Concepto',
    diaLetivo: 'Días de colegio',
    cargaHoraria: 'Carga horaria',
    estabelecimento: 'Establecimiento',
    cnpjEstabelecimento: 'CNPJ',
    autorizacao: 'Autorización',
    lei: 'Lei',
    resultado: 'Resultado',
    impressos: 'Documentos',
    detalhes: ' Detalles',
    adicionaDisciplina: 'Agregar disciplina',
    novoItem: 'Nuevo artículo',
    frequenciaMedia: 'Frecuencia media',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    situacoesPedagogicas: 'Situaciones pedagógicas',
    bimestral: 'Bimensual',
    trimestral: 'Trimestral',
    semestral: 'Semestre',
    modular: 'Modular',
    dataPrimeiroVencimento: 'Primera fecha de vencimiento',
    notaGeralTurma: 'Calificación general de la clase',
    configuracaoAvaliacaoPadrao: 'Configuración de evaluación predeterminada',
    dataDaAula: 'Fecha de clase',
    verParcelas: 'Ver paquetes',
    itensServiços: 'Artículos y servicios',
    adicionarDisciplinas: 'Agregar cursos',
    calendarioAnual: 'Calendario anual',
    considerarDiasUteis: 'Considere los días hábiles en que vencen las cuotas',
    templateComercial: 'Plantilla de negocio',
    estabelecimentos: 'Establecimientos',
    responsavelPor: 'Responsable por',
    pesquisarResponsavel: 'Buscar por responsable',
    aprovar: 'Aprobar',
    dataInicioEncerramento: 'Fecha de inicio / finalización',
    horaInicioEncerramento: 'Hora de inicio / finalización',
    desejaProsseguir: '¿Quieres proceder?',

    feedbacks: {
      nenhumaDisciplinaCadastrar: 'Ninguna disciplina registradaNinguna disciplina registrada!',
      nenhumQuadroHorarioCadastrado: 'No se registró la hoja de horas!',
      nenhumaGradeCurricularCadastrada: 'No hay currículum registrado!',
      nenhumOrcamentoVinculadoMatricula: 'Sin presupuesto vinculado a la matrícula!',
      necessarioOrcamentoParaRealizarMatricula: 'Para inscribirse, se requiere un presupuesto vinculado.',
      cadastreDisciplinaGradeCurricular: 'Registrar al menos una asignatura en el plan de estudios',
      cadastreUmQuadroHorariosAcessoDiario: 'Registre la hoja de tiempo para acceder al diario',
      cadastreUmaGradeCurricularAcessoHorarios: 'Registre el plan de estudios para acceder a los horarios',
      informeSomenteCasasDecimais: 'Informar solo lugares decimales',
      contemplaNotasParciaisPeriodo: 'Incluye calificaciones parciales y calificaciones del período.',
      nenhumSistemaAvaliacaoEscolhido: 'No se ha seleccionado ningún sistema de valoración.',
      sistemaAvaliacaoSemModeloAvaliacao: 'Sistema de evaluación sin modelo de evaluación.',
      nenhumaTurmaRelacionadaAnoFaixa: 'No hay clases relacionadas con el año / Faixa',
      nenhumaTurmaCadastrada: 'No hay clases registradas',
      selecionePeloMenosUmRegistro: 'Seleccione al menos un registro',
      selecionePeloMenosUmaData: 'Seleccione al menos una fecha',
      camposObrigatoriosNaoPreenchidos: 'Campos obligatorios no rellenados',
      nenhumItemAdicionado: 'No se agregaron elementos',
      formulaNaoConfigurada: 'Fórmula no configurada',
      nenhumProdutoDoTipoPatrimonioCadastrado: 'Sin productos de capital registrado',
      nenhumaAvaliacaoCadastradaTemplate: 'Ninguna evaluación registrada',
      semAvaliacoesCadastradas: 'Ninguna evaluación registrada',
      nenhumDiarioDeAulaNesteDia: 'No hay diario de clase este día',
      nenhumaOportunidadeEncontrada: 'No se encontraron oportunidades para este filtro.',
      nenhumComentarioOuJustificativa: 'Sin comentarios ni justificación en esta clase..',
      turmaSemGradeCurricular: 'Clase sin plan de estudios registrado!',
      justificativaAtualizadaSucesso: 'Justificación actualizada con éxito',
      max5colunas: 'Máximo 5 columnas seleccionadas',
      disciplinaNaoTemAvaliacaoOuConfiguracoes: 'El sujeto no tiene evaluaciones registradas o faltan ajustes.',
      disciplinaNaoTemTemplateFinalconfigurado: 'El asunto no tiene una plantilla final configurada',
      obrigatorioParaAdicionarDesconto: 'Requerido para agregar descuento',
      naoTemTemplateFinal: 'No tiene configuracion',
      somaDasAvaliacoesNaoTotaliza10Pontos: 'La suma de los pesos de las evaluaciones no totaliza 10.',
      precisaLancarFrequencia: 'Necesita que se libere la frecuencia.',
      ultimoDiarioMes: 'No hay un diario después de este este mes.',
      primeiroDiarioMes: 'No hay un diario antes de este mes.',
      integrantePrecisaTerNotaLancadaParaEditarJustificativa:
        'El miembro debe haber publicado la calificación para editar la justificación.',
      preencherEmailFaltante: 'Por favor complete el e-mail en Datos Básicos',
      cadastroSemDependenteRelacionado: 'El registro actual no tiene ningún dependiente relacionado',
      turmaSemSerieCadastrada: 'La clase no contiene series enumeradas en Datos básicos.',
      horarioComAulaCadastrada: 'Ya tienes clases registradas en este momento',
      horarioComAulaCadastradaTexto:
        'Si desea continuar, las clases registradas en ese momento serán reemplazadas y su contenido se perderá.'
    },

    navegacao: {
      'locais-de-estoque': 'Ubicaciones de existencias',
      pedagogico: 'Pedagógico',
      'preferencias-do-pedagogico': 'Preferencias pedagógicas',
      'situacoes-pedagogicas': 'Situaciones pedagógicas',
      'documentacao-pedagogica': 'Documentación pedagógica',
      turmas: 'Clases',
      todas: 'Todo',
      turnos: 'Turnos',
      'tipos-de-disciplina': 'Tipos de disciplina',
      'areas-de-conhecimento': 'Áreas de conocimiento',
      etapas: 'Etapas',
      habilidades: 'Habilidades',
      'tipos-de-sala': 'Tipos de habitaciones / ambientes ',
      'modalidades-de-turma': 'Modalidades de clase',
      'tipos-de-cursos': 'Tipos de cursos',
      'series-equivalentes': 'Serie equivalente',
      disciplinas: 'Asignaturas',
      'educacao-infantil': 'Educación Infantil',
      'ensino-fundamental': 'Enseñanza fundamental',
      'ensino-medio': 'Escuela secundaria',
      'etapas-personalizadas': 'Pasos personalizados',
      'ano-faixa': 'Año / Faixa',
      'unidades-tematicas': 'Unidades temáticas',
      'competencias-gerais': 'Habilidades generales',
      'competencias-especificas': 'Habilidades especificas',
      'grupos-de-avaliacao': 'Grupos de evaluación',
      'objetos-do-conhecimento': 'Objetos de conocimiento',
      'campos-atuacao': 'Campos de acción',
      'praticas-de-linguagem': 'Prácticas de lenguaje',
      personalizado: 'Personalizado',
      series: 'Series',
      matriculas: 'Inscripción',
      cursos: 'Cursos',
      'sistemas-de-ensino': 'Sistemas educativos',
      'direitos-aprendizagem': 'Derechos de aprendizaje',
      'campos-de-experiencia': 'Campos de experiencia',
      'direitos-de-aprendizagem': 'Derechos de aprendizaje',
      'objetivos-aprendizagem-desenvolvimento': 'Objetivos de aprendizaje y desarrollo',
      'tempo-duracao-aula': 'Tiempo de duración de la clase',
      'tipo-evento': 'Tipo de Evento',
      'horarios-aula': 'Horarios de clases',
      'objetos-conhecimento': 'Objetos de conocimiento',
      'componentes-curriculares': 'Componentes curriculares',
      'campo-atuacao-social': 'Campo de acción social',
      salas: 'Habitaciones / ambientes',
      comercial: 'Relación',
      'preferencias-do-comercial': 'Preferencias de Relación',
      'grades-curriculares': 'Cuadrículas del plan de estudios',
      'planos-financeiros': 'Planes financieros',
      'templates-avaliacao': 'Plantillas de evaluación',
      'sistemas-avaliacao': 'Sistemas de clasificación',
      'dashboard-do-pedagogico': 'Tablero pedagógico',
      'fluxo-automacao-crm': 'Automatización',
      'dashboard-do-relacionamento': 'Panel de Relación',
      'servicos-contratacoes': 'Servicios y Contratos',
      'saidas-servicos-e-itens': 'Productos: servicios y artículos',
      'contratos-e-matriculas': 'Contratos e inscripciones',
      ocorrencias: 'Ocurrencias',
      'tipos-ocorrencia': 'Tipos de ocurrencia',
      'documentos-fiscais': 'Documentos fiscales',
      'modelos-diario': 'Plantillas de diario'
    },

    validacoes: {
      naoPodeIniciarExpressaoComOperador: 'No se puede iniciar la expresión con este operador',
      avaliacaoXNaoExiste: 'Evaluación {{value}} no existe',
      expressaoInvalida: 'Expresión inválida',
      variavelXNaoExiste: 'Variable {{value}} no existe',
      selecionarConfiguracaoDeBolsa: 'Seleccione una configuración de bolsa',
      selecionarConfiguracaoConvenio: 'Seleccione una configuración de acuerdo',
      selecionarConfiguracaoDesconto: 'Seleccione una configuración de descuento',
      bolsaIncluida: 'Bolsa ya incluida',
      convenioIncluido: 'Acuerdo ya incluido',
      descontoIncluido: 'Descuento ya incluido',
      selecioneUmTipoDeCalculo: 'Seleccione un tipo de cálculo'
    }
  },

  cadastro: {
    estabelecimento: 'Establecimiento',

    cliente: {
      atualizadoSucesso: 'Cliente actualizado con éxito',
      matriculaUsoInterno: 'Registro de uso interno',
      registroMatricula: 'Registro registro'
    },

    funcionarios: {
      habilitacoes: {
        titulo: 'Datos de calificación',
        deletadoSucesso: 'Habilitar eliminado correctamente',
        criadoSucesso: 'Habilitación agregada correctamente',
        registro: 'Número de registro',
        novo: 'Nueva licencia',
        adicionar: 'Agregar escenario y temas',

        subHabilitacoes: {
          novo: 'Nueva disciplina',
          adicionar: 'Agregar disciplina',
          adicionadaSucesso: 'Asunto agregado exitosamente',
          removidaSucesso: 'Asunto eliminado correctamente'
        }
      }
    },

    preferencias: {
      habilitacoes: {
        nomeDaSubHabilitacao: 'Nombre de sub-habilitación',
        subHabilitacoes: {
          novo: 'Nueva sub-habilitación',
          nomeDaSubHabilitacao: 'Nombre de la subhabilitación',
          cadastradoSucesso: 'Subcalificación registrada con éxito'
        }
      }
    }
  },

  historicos: {
    historicos: 'Historia',
    novoHistorico: 'Nueva historia',
    resultadoPedagogico: 'Resultado pedagógico',
    adicionaHistorico: 'Agregar historial',
    adicionarDisciplina: 'Agregar disciplina',
    escrevaMensagem: 'Escribe el mensaje ',
    certificadoConclusao: 'Certificado de finalización',
    caracteresRestantes: 'Caracteres restantes',
    mensagemDeTexto: 'Escribe el mensaje',
    atualizadoSucesso: 'Historial actualizado con éxito',
    removidoSucesso: 'Historial eliminado correctamente',
    historicoAdicionadoSucesso: 'Historial agregado exitosamente',
    impresso: { atualizadoSucesso: 'Imprimir actualizado correctamente' }
  },

  comercial: {
    dashboard: {
      portal: 'Portal estudiantil'
    }
  },

  financeiro: {
    vendas: {
      orcamentos: {
        composicao: {
          cadastroErro: 'Condición Monto total diferente del Monto restante del presupuesto!',
          novo: 'Agregar - Servicios y artículos',
          item: 'Clase o artículo',
          condicoes: {
            descontoBolsa: 'Bolsa de descuento'
          }
        }
      },

      saidas: {
        composicao: {
          novo: 'Agregar clase / artículo',
          item: 'Clase o artículo',
          condicoes: {
            cadastradoSucesso: '¡Condición registrada con éxito!',
            atualizadoSucesso: '¡Condición actualizada con éxito!',
            removidoSucesso: '¡Condición eliminada con éxito!'
          }
        }
      }
    },
    estoque: {
      titulo: 'Movimiento de existencias',
      localEstoque: 'Ubicación de existencias',
      nenhumCadastro: 'Ningún movimiento de existencias registrado',
      cadastradoSucesso: '¡Existencias actualizadas con éxito!',
      atualizadoSucesso: '¡Existencias actualizadas con éxito!',
      removidoSucesso: '¡Existencias eliminadas con éxito!',
      totalItens: 'Artículos totales',
      maiorSaidaItem: 'Mayor salida de artículos',
      semSaidaItem: 'Sin salida reciente',
      itemsVencidos: 'Artículos vencidos',
      nomeSituacao: 'Nombre / Situación en existencias',
      valorCompraVenda: 'Precio de compra / Venta',
      localEstoqueFinalidade: 'Ubicación de existencias / Finalidad',
      embCompraVenda: 'Emb. de compra / Venta',
      estoqueMinimo: 'Existencias minimas',
      estoqueMaximo: 'Existencias maximas',
      tipoProduto: 'Tipo de producto',
      buscarPorProduto: 'Buscar por producto',
      finalidade: 'Propósito',
      vencimentoEntreDatas: 'Vencimiento entre fechas',
      vencimentoInicial: 'Vencimiento inicial',
      vencimentoFinal: 'Vencimiento final',
      validadeEstoque: 'Valides y stock',
      validade: 'Validez',
      situacaoEstoque: 'Situación de stock',
      movimentarLocalEstoque: 'Mover ubicación de stock',
      quantidadeMovimentar: 'Cantidad a mover',
      selecionarLocal: 'Seleccionar ubicación',
      valorVenda: 'Cantidad de Venta',
      embalagemCompra: 'Compra de embalaje',
      embalagemVenda: 'Venta de Empaques',
      removerUnidade: 'Quitar unidades',
      voceEstaRemovendoUnidades: 'Está eliminando {{unit}} unidades',
      transferirUnidades: 'Unidades de transferencia',
      voceEstaTransferindoUnidadesDesseLocal: 'Está transfiriendo {{unit}} unidades desde esta ubicación',
      burcarPorProduto: 'Buscar por producto',
      abaixoEstoque: 'Por debajo del stock mínimo',
      acimaEstoque: 'Por encima del stock máximo',
      produtoVencido: 'Producto caducado!',
      vencido: 'Atrasado',
      unidadesTransferidas: 'Unidades a transferir',
      unidadesRestantes: 'Unidades restantes en la ubicación actual'
    },
    preferencias: {
      templatesComerciais: {
        titulo: 'Plantillas de negocios',
        novo: 'Nueva plantilla de negocio',
        cadastradoSucesso: 'Plantilla de negocio registrada correctamente!',
        atualizadoSucesso: '¡Plantilla de negocio actualizada correctamente!',
        removidoSucesso: '¡Plantilla de empresa eliminada correctamente!'
      }
    },
    preferenciasEstoque: {
      localDeEstoque: {
        titulo: 'Ubicación de existencias',
        novo: 'Nueva ubicación de existencias',
        cadastradoSucesso: '¡Ubicación de existencias registrada con éxito!',
        atualizadoSucesso: '¡Ubicación de existencias actualizada con éxito!',
        removidoSucesso: '¡Ubicación de existencias eliminada con éxito!'
      }
    }
  },

  configuracoes: {
    empresa: {
      autorizacao: {
        anoFaixa: {
          cadastradoSucesso: 'Año / pista agregada con éxito!',
          removidoSucesso: 'Año / pista eliminada con éxito!'
        }
      }
    }
  },

  pedagogico: {
    contratosMatriculas: {
      titulo: 'Contratos e inscripciones',
      novo: 'Nuevo contrato / Registro',
      cadastradoSucesso: 'Contrato / inscripción registrada con éxito!',
      atualizadoSucesso: 'Contrato / registro actualizado correctamente!',
      removidoSucesso: 'Contrato / registro eliminado con éxito!',
      confirmaMaisIntegrantes:
        'Ya ha excedido el límite de miembros para esta clase. ¿Aún quieres agregar un nuevo miembro?',

      contasAReceber: {
        titulo: 'Títulos',
        novo: 'Nuevo título',
        nenhumCadastro: 'Ningún título registrado',
        cadastradoSucesso: 'Título registrado con éxito!',
        atualizadoSucesso: 'Título actualizado correctamente!',
        removidoSucesso: 'Título eliminado correctamente!'
      },

      documentos: {
        titulo: 'Documentos',
        novo: 'Nuevo documento',
        nenhumCadastro: 'Ningún documento registrado',
        impressao: 'Impresión de documentos',
        cadastradoSucesso: 'Documento registrado con éxito!',
        atualizadoSucesso: 'Documento actualizado con éxito!',
        removidoSucesso: 'Documento eliminado correctamente!'
      },

      gruposPersonalizados: {
        titulo: 'Grupos personalizados',
        novo: 'Nuevo grupo personalizado',
        cadastradoSucesso: 'Grupo personalizado registrado con éxito!',
        atualizadoSucesso: 'Grupo personalizado actualizado correctamente!',
        removidoSucesso: 'Grupo personalizado eliminado correctamente!'
      },

      orcamentos: {
        titulo: 'Presupuestos',
        novo: 'Nuevo presupuesto de ventas',
        nenhumCadastro: 'No hay presupuesto registrado',
        cadastradoSucesso: 'Presupuesto de ventas registrado con éxito!',
        atualizadoSucesso: 'Presupuesto de ventas actualizado correctamente!',
        removidoSucesso: '¡Cotización de venta eliminada correctamente!'
      },

      turmas: {
        titulo: 'Clases',
        novo: 'Nueva clase',
        selecione: 'Seleccione una clase',
        cadastradoSucesso: '¡Clase registrada con éxito!',
        atualizadoSucesso: '¡Clase actualizada con éxito!',
        removidoSucesso: '¡Clase eliminada con éxito!'
      },

      vendas: {
        titulo: 'Peticiones',
        novo: 'Nueva orden de venta',
        nenhumCadastro: 'No se registraron pedidos de venta',
        cadastradoSucesso: 'Pedido de venta registrado correctamente!',
        atualizadoSucesso: 'Pedido de venta actualizado correctamente!',
        removidoSucesso: 'Pedido de venta eliminado correctamente!'
      }
    },

    relatorios: {
      titulo: 'Documentación pedagógica',
      gerarHistoricoEscolar: 'Generar historia escolar',
      selecioneATurma: 'Seleccionar la clase',
      guiaTransferencia: 'Guía de transferencia',
      motivoTransferencia: 'Motivo de la transferencia',

      fichaIndividual: {
        titulo: 'Archivo individual',
        fichasSeremGeradas: 'Tokens que se generarán',
        gerarFichaIndividual: 'Generar registro individual',
        gerarNFichas: '¿Realmente quieres generar {{number}} tokens?',
        geradoSucesso: '¡Tokens generados con éxito!'
      },

      historicoEscolar: {
        titulo: 'Historia escolar',
        gerarHistoricoEscolarEmLote: 'Generar historia en lote',
        gerarHistoricoEscolar: 'Generar historial',
        gerarEmLote: 'Generar expedientes académicos por lotes',
        historicosSeremGerados: 'Historias a generar',
        geradoSucesso: '¡Historias generadas con éxito!',
        gerarNHistoricos: '¿Realmente desea generar {{number}} expedientes académicos?'
      },

      boletim: {
        titulo: 'Boletín',
        gerarBoletimEscolar: 'Gerar boletín',
        gerarBoletimEmLote: 'Generar boletín por lotes',
        boletinsSeremGerados: 'Boletines a generar',
        geradoSucesso: '¡Boletines generados con éxito!',
        gerarNBoletins: '¿Realmente desea generar {{number}} boletines?'
      }
    },

    ocorrencias: {
      titulo: 'Ocurrencias',
      tituloCard: 'Ocurrencia',
      novo: 'Nueva ocurrencia',
      data: 'Fecha de ocurrencia',
      responsavelOcorrencia: 'Persona responsable del hecho',
      tipo: 'Tipo de ocurrencia',
      principaisDados: 'Datos de ocurrencia principal',
      assinadoResponsavelPedagogico: 'Suceso firmado por el responsable pedagógico',
      assinadasFiltro: 'Acontecimientos firmados',
      criadoPor: 'Criado por',
      adicionarAlunoTurma: 'Agregar estudiante / Clase',
      adicionarAluno: 'Agregar estudiante',
      responsavel: 'Responsable de la ocurrencia',
      adicionarResponsavel: 'Nueva persona responsable del incidente',
      nomeResponsavel: 'Nombre del responsable',
      funcionarioHabilitadoOcorrencia: 'Responsable de la ocurrencia',
      funcionarioHabilitadoSucesso: 'Empleado habilitado con éxito!',
      funcionarioDesabilitadoSucesso: 'Empleado inhabilitado con éxito!',
      funcionarioAtualizadoSucesso: 'Empleado actualizado correctamente!',
      cadastradoSucesso: 'Incidencia registrada correctamente!',
      atualizadoSucesso: '¡Ocurrencia actualizada con éxito!',
      removidoSucesso: '¡Ocurrencia eliminada con éxito!',
      emailEnviadoSucesso: '¡Email enviado con éxito!',
      impressao: {
        titulo: 'Ocurrencia',
        nomeAluno: 'Nombre del alumno:',
        tipoOcorrencia: 'Tipo de ocurrencia:',
        descricao: 'Descripción:',
        orientacao: 'Guia:',
        criadoPor: 'Criado por:',
        responsavelOcorrencia: 'Responsable de la ocurrencia:',
        responsavelPedagogico: 'Responsable pedagógico',
        turma: 'Clase:',
        disciplina: 'tema:',
        anoFaixa: 'Año / Faixa'
      },
      tiposOcorrencia: {
        titulo: 'Tipo de ocurrencia',
        novo: 'Nuevo tipo de ocurrencia',
        adicionarTipo: 'Agregar tipo de ocurrencia',
        cadastradoSucesso: '¡Tipo de incidente registrado correctamente!',
        atualizadoSucesso: '¡El tipo de incidente se actualizó correctamente!',
        removidoSucesso: '¡Tipo de ocurrencia eliminado con éxito!'
      }
    },

    matriculas: {
      titulo: 'Inscripción',
      novo: 'Nuevo registro',
      cadastradoSucesso: 'Registro registrado exitosamente!',
      atualizadoSucesso: '¡Registro actualizado con éxito! ',
      removidoSucesso: '¡Placa de matrícula eliminada con éxito!',
      adicionarSerieItem: 'Agregar serie / Producto',
      itensAnoFaixa: 'Año / Faixa',
      totalDoContrato: 'Total del contrato',
      anoFaixaCondicao: 'Año/Faixa / Condición',
      totalValorParcela: 'Total / Monto de la cuota',
      itensMatricula: 'Elementos de inscripción',
      orcamentoMatriculaCadastradoSucesso: '¡Presupuesto de inscripción registrado con éxito!',
      tipoContratoNumero: 'Tipo de contrato / Número',
      dataInicioFim: 'Fecha de inicio / El fin',
      situacaoContrato: 'Estado del contratoo',
      verParcelas: 'Ver paquetes',
      impressaoContrato: 'Impresión de contrato',
      visualizarImpressao: 'Vista de impresión',
      finalizar: 'Terminar',
      vendedorDoOrcamento: 'Vendedor de presupuesto',
      matriculasCanceladas: 'Inscripciones canceladas',
      matriculasEncerradas: 'Inscripciones cerradas',
      preMatriculas: 'Preinscripción',
      matriculasRescindidas: 'Inscripciones canceladas',
      matriculasTrancadas: 'Inscripción bloqueada',
      matriculasAtivas: 'Inscripciones activas',
      matriculasPendentes: 'Pendiente de inscripción',
      proximo: 'Próximo',
      adicionarTurma: 'Agregar clase',
      turmaExistenteNaMatricula: 'La clase seleccionada ya está incluida en el registro.',
      gerarTitulos: 'Generar títulos',
      nenhumOrcamentoVinculadoMatricula: '¡No hay presupuesto vinculado a la matrícula!',

      itens: {
        adicionarAnoFaixaItem: 'Agregar año / rango: artículo',
        editarAnoFaixaItem: 'Editar año / rango: artículo',
        selecionarAnoFaixaItem: 'Seleccionar año / rango - Artículos',
        selecioneUmAnoFaixaItem: 'Seleccione un año / rango: artículo',
        selecioneUmaTurma: 'Seleccione una clase',
        itens: 'Elementos',
        adicionadoSucesso: 'Composición agregada con éxito',
        atualizadoSucesso: 'Composición actualizada correctamente',
        deletadoSucesso: 'Composición eliminada correctamente',
        subTotalItem: 'Subtotal del artículo',
        subTotalItemCondicoes: 'Subtotal con condiciones',
        gerenciarCondicoes: 'Gestionar condiciones',
        efetivar: 'Efectuar',
        pedidoGeradoSucesso: 'Solicitud generada con éxito!',
        resumoMatricula: 'Resumen de inscripción',
        itemResponsavelFinanceiro: 'Artículo / Oficial financiero',
        totalCondicao: 'Total / Condición',
        itemSemEmbalagem: 'El artículo no tiene embalaje.',
        selecioneUmItemAnoFaixa: 'Seleccione un elemento de año / rango para cargar los planes financieros',
        isentarItem: 'Artículo exento',
        justificativaIsencaoItem: 'Justificación de la exención de artículos',

        condicoes: {
          titulo: 'Condiciones de pago',
          novo: 'Agregar condición',
          atualizadoSucesso: '¡Condiciones de pago actualizadas con éxito!',
          semDados: 'Artículo sin condiciones de pago',
          semDadosDescricao: 'Agregue al menos un término de pago para el artículo.',
          sessionBolsa: 'Bolsa de descuento',
          sessionDesconto: 'Descuento de puntualidad',
          sessionConvenios: 'Descuento de seguro',
          sessionDescontoAvulso: 'Descuento único',
          configuracaoConvenio: 'Configuración del acuerdo',
          configuracaoDesconto: 'Configuración de descuento'
        }
      },

      documentoImpressao: {
        impressaoDocumentos: 'Impresión de documentos',
        modeloDeDocumentos: 'Documento ejemplar',
        documentoSalvoSucesso: 'Documento guardado exitosamente'
      }
    },

    etapas: {
      educacaoInfantil: {
        camposDeExperiencia: {
          titulo: 'Campos de experiencia',
          novo: 'Nuevo campo de experiencia',
          cadastradoSucesso: 'Campo de experiencia registrado con éxito!',
          atualizadoSucesso: 'Campo de experiencia actualizado con éxito!',
          removidoSucesso: 'Campo de experiencia eliminado con éxito!'
        },

        direitosDeAprendizagem: {
          titulo: 'Derechos de aprendizaje',
          novo: 'Nuevo derecho de aprendizaje',
          cadastradoSucesso: 'Derecho de aprendizaje registrado con éxito!',
          atualizadoSucesso: 'Aprendizaje correcto actualizado con éxito!',
          removidoSucesso: 'Learning right eliminado con éxito!'
        },

        objetivosAprendizagemDesenvolvimento: {
          titulo: 'Objetivos de aprendizaje',
          novo: 'Nuevo objetivo de aprendizaje y desarrollo',
          cadastradoSucesso: '¡Objetivo de aprendizaje y desarrollo registrado con éxito! ',
          atualizadoSucesso: '¡Objetivo de aprendizaje y desarrollo actualizado con éxito!',
          removidoSucesso: '¡Objetivo de aprendizaje y desarrollo eliminado con éxito!'
        }
      },

      ensinoFundamental: {
        habilidades: {
          titulo: 'Habilidades de la escuela primaria',
          novo: 'Nueva habilidad de la escuela primaria',
          cadastradoSucesso: '¡Habilidad de la escuela primaria registrada con éxito!',
          atualizadoSucesso: '¡Habilidad de la escuela primaria actualizada con éxito!',
          removidoSucesso: '¡Habilidad de la escuela primaria eliminada con éxito!'
        },

        unidadesTematicas: {
          titulo: 'Unidades Temáticas',
          novo: 'Nueva unidad temática',
          cadastradoSucesso: 'Unidad temática registrada con éxito!',
          atualizadoSucesso: 'Unidad temática actualizada con éxito!',
          removidoSucesso: '¡Unidad temática eliminada con éxito!',
          habilidades: {
            adicionar: 'Agregar nueva habilidad',
            novo: 'Nueva habilidad',
            cadastradoSucesso: '¡Habilidad añadida con éxito!',
            atualizadoSucesso: '¡Habilidad actualizada con éxito!',
            removidoSucesso: '¡Habilidad eliminada con éxito!'
          }
        },

        objetosConhecimento: {
          titulo: 'Objetos de conocimiento',
          novo: 'Nuevo objeto de conocimiento',
          cadastradoSucesso: '¡Objeto de conocimiento registrado con éxito!',
          atualizadoSucesso: 'Objeto de conocimiento actualizado con éxito!',
          removidoSucesso: '¡Objeto de conocimiento eliminado con éxito!',

          habilidades: {
            adicionar: 'Agregar habilidad',
            novo: 'Nueva habilidad',
            cadastradoSucesso: '¡Habilidad añadida con éxito!',
            atualizadoSucesso: '¡Habilidad actualizada con éxito!',
            removidoSucesso: '¡Habilidad eliminada con éxito!'
          }
        },

        praticasLinguagem: {
          titulo: 'Prácticas de lenguaje',
          novo: 'Práctica de nuevos idiomas ',
          cadastradoSucesso: '¡Práctica de idiomas registrada con éxito!',
          atualizadoSucesso: 'Práctica de idiomas actualizada con éxito!',
          removidoSucesso: '¡Práctica del idioma eliminada con éxito!',

          habilidades: {
            adicionar: 'Agregar habilidad',
            novo: 'Nueva habilidad',
            cadastradoSucesso: '¡Habilidad añadida con éxito!',
            atualizadoSucesso: '¡Habilidad actualizada con éxito!',
            removidoSucesso: '¡Habilidad eliminada con éxito!'
          }
        }
      },
      ensinoMedio: {
        camposAtuacao: {
          titulo: 'Campos de acción',
          novo: 'Nuevo campo de actividad',
          cadastradoSucesso: 'Campo de acción registrado con éxito!',
          atualizadoSucesso: '¡Campo de acción actualizado con éxito!',
          removidoSucesso: '¡Campo de juego eliminado con éxito!',
          habilidades: {
            adicionar: 'Agregar habilidad',
            novo: 'Nueva habilidad',
            cadastradoSucesso: '¡Habilidad añadida con éxito!',
            atualizadoSucesso: '¡Habilidad actualizada con éxito!',
            removidoSucesso: '¡Habilidad eliminada con éxito!'
          }
        },

        habilidades: {
          titulo: 'Habilidades de la escuela secundaria',
          novo: 'Nueva habilidad de secundaria ',
          cadastradoSucesso: 'Habilidad de la escuela secundaria registrada con éxito!',
          atualizadoSucesso: '¡Habilidad de la escuela secundaria actualizada con éxito!',
          removidoSucesso: '¡Habilidad de la escuela secundaria eliminada con éxito!',
          anosFaixas: {
            adicionar: 'Agregar año / rango',
            novo: 'Año nuevo / pista',
            cadastradoSucesso: '¡Año / pista añadidos correctamente!',
            atualizadoSucesso: 'Año / pista actualizado con éxito!',
            removidoSucesso: '¡Año / pista eliminados con éxito!'
          },
          series: {
            adicionar: 'Agregar serie',
            novo: 'Nueva serie',
            cadastradoSucesso: '¡Serie añadida con éxito!',
            atualizadoSucesso: '¡Serie actualizada con éxito!',
            removidoSucesso: '¡Serie eliminada con éxito!'
          }
        }
      },

      personalizadas: {
        etapas: {
          titulo: 'Pasos personalizados',
          novo: 'Nuevo paso personalizado',
          cadastradoSucesso: '¡Paso personalizado registrado con éxito!',
          atualizadoSucesso: '¡Paso personalizado actualizado con éxito!',
          removidoSucesso: '¡El paso personalizado se eliminó correctamente!',
          etapaVoltadaAEducacaoInfantil: 'Etapa enfocada a la Educación Infantil'
        }
      }
    },

    preferencias: {
      conteudo: {
        novo: 'Nuevo contenido',
        cadastradoSucesso: 'Contenido registrado correctamente!',
        descricaoDoConteudo: 'Descripción del contenido'
      },

      sistemasAvaliacao: {
        titulo: 'Sistemas de clasificación',
        novo: 'Nuevo sistema de clasificación',
        cadastradoSucesso: '¡Sistema de evaluación registrado con éxito!',
        atualizadoSucesso: '¡Sistema de clasificación actualizado con éxito!',
        removidoSucesso: '¡Sistema de evaluación eliminado con éxito!',
        substituiMenorNotaParcialPelaNotaRecuperacao:
          'Reemplaza el grado parcial más bajo definido como "UsaRec" con el grado de recuperación',
        substituiMediaNotaParcialPelaNotaRecuperacao:
          'Reemplaza el promedio de las calificaciones parciales definidas como UsaRec con la calificación de recuperación ',
        explicacaoUsaRec: `‘ UsaRec ’-> Opción que define las calificaciones que se verán afectadas por la puntuación de recuperación parcial,
        definido en el registro de evaluación.`,
        pesoPeriodo: 'Período de peso {{periodo}}',
        pesoMaximo: 'Peso máximo',
        pontuacaoMaximaAnual: 'No. puntaje máximo anual',
        numeroModulos: 'No de módulos',
        considerarHabilidades: 'Considerar habilidades',
        sistemaDeAvaliacaoFinal: 'Sistema de evaluación final',
        periodoTipoPontuacao: 'Periodo y tipo de puntuación',
        configuracaoAvaliacaoFinal: 'Establecer calificaciones finales',
        configuracaoAvaliacao: 'Configuración de evaluación',
        calculo: 'Cálculo',
        mediaFinal: 'Medios finales',
        subDisciplinas: 'Subdisciplinas',
        mediaFrequencia: 'Frecuencia media',
        mediaMninima: 'Promedio mínimo',
        frequenciaMinima: 'Frecuencia mínima',
        validarNotaMaximaAposExameRecuperacao: 'Validar nota máxima tras examen/recuperación',
        utilizarArredondamento: 'Usar redondeo',
        tornarSistemaDeAvaliacaoPadrao: 'Hacer que el sistema de calificación sea predeterminado',
        replicarCadastroParaTurmas: 'Replicar registro para clases',
        sistemasAvaliacaoReplicadoSucesso: '¡Sistema de evaluación replicado con éxito!',

        conceitos: {
          novo: 'Nuevo concepto',
          atualizadoSucesso: 'Concepto actualizado con éxito!',
          cadastradoSucesso: '¡Concepto registrado con éxito!',
          removidoSucesso: '¡Concepto eliminado con éxito!',
          titulo: 'Concepto',
          editar: 'Editar concepto',
          valorEquivalente: 'Valor equivalente',
          valorMaxDoConceito: 'Puntuación máxima del concepto',
          valorMinDoConceito: 'Grado mínimo de concepto'
        },

        arredondamentos: {
          medias: {
            cadastradoSucesso: '¡Redondeo de promedios registrados con éxito!',
            atualizadoSucesso: '¡Redondeo de promedios actualizado con éxito!'
          },

          mediasParciais: {
            cadastradoSucesso: '¡Redondeo de medias parciales registrado con éxito!',
            atualizadoSucesso: '¡Redondeo medio parcial actualizado correctamente!'
          },

          notas: {
            cadastradoSucesso: '¡Redondeo de notas registrado correctamente!',
            atualizadoSucesso: 'Redondeo de notas actualizado correctamente!'
          },

          recuperacoes: {
            cadastradoSucesso: '¡Redondeo de recuperaciones registradas con éxito!',
            atualizadoSucesso: '¡Redondeo de recuperaciones actualizado con éxito!'
          },

          parecerDescritivo: {
            novo: 'Novo Documento',
            adicionarDocumento: 'Adicionar Documento'
          }
        }
      },

      sistemasEnsino: {
        titulo: 'Sistemas educativos',
        novo: 'Nuevo sistema educativo',
        cadastradoSucesso: '¡Sistema educativo registrado con éxito!',
        atualizadoSucesso: '¡Sistema educativo actualizado con éxito!',
        removidoSucesso: '¡Sistema educativo eliminado con éxito!'
      },

      modelosAvaliacao: {
        titulo: 'Modelos de evaluación',
        modeloDeAvaliacao: 'Modelo de evaluación',
        novo: 'Agregar plantilla de evaluación',
        editar: 'Editar modelo de evaluación',
        cadastradoSucesso: '¡Modelo de evaluación registrada correctamente!',
        atualizadoSucesso: '¡Modelo de evaluación actualizada correctamente!',
        removidoSucesso: '¡El modelo de evaluación se eliminó correctamente!',
        confirmaRemocao:
          'Ya tienes valoraciones registradas en este modelo. Si desea continuar, se perderán las valoraciones registradas en este modelo',

        avaliacoes: {
          novo: 'Nueva evaluación',
          editar: 'Editar reseña',
          salvaSucesso: '¡Calificación guardada con éxito!',
          cadastradoSucesso: '¡Evaluación registrada con éxito!',
          atualizadoSucesso: '¡Clasificación actualizada correctamente!',
          removidoSucesso: 'Calificación eliminada con éxito!',

          periodos: {
            novo: 'Nuevo período',
            editar: 'Editar período ',
            cadastradoSucesso: '¡Periodo registrado correctamente!',
            atualizadoSucesso: '¡Periodo actualizado con éxito!',
            removidoSucesso: '¡Período eliminado con éxito!'
          },

          tiposAvaliacoes: {
            novo: 'Nuevo tipo de evaluación',
            titulo: 'Tipos de evaluación',
            cadastradoSucesso: '¡Tipo de evaluación registrada con éxito!',
            atualizadoSucesso: '¡Tipo de evaluación actualizado correctamente!',
            removidoSucesso: '¡El tipo de calificación se eliminó correctamente!',
            conceitos: {
              titulo: 'Tipo de concepto de evaluación',
              novo: 'Nuevo tipo de evaluación de concepto',
              cadastradoSucesso: '¡Tipo de concepto de evaluación registrado con éxito!',
              atualizadoSucesso: '¡Tipo de concepto de evaluación actualizado con éxito!',
              removidoSucesso: '¡El concepto de tipo de evaluación se ha eliminado correctamente!',
              editar: 'Editar tipo de evaluación de concepto'
            },
            habilidades: {
              titulo: 'Tipo de evaluación de habilidades',
              cadastradoSucesso: '¡Tipo de evaluación de habilidades registrado con éxito!',
              atualizadoSucesso: '¡Tipo de evaluación de habilidades actualizado con éxito!',
              removidoSucesso: '¡El tipo de evaluación de habilidades se eliminó correctamente!'
            }
          }
        },

        formulas: {
          novo: 'Nueva fórmula',
          editar: 'Editar fórmula',
          cadastradoSucesso: '¡Fórmula registrada con éxito!',
          atualizadoSucesso: '¡Fórmula actualizada con éxito!',
          removidoSucesso: '¡Fórmula eliminada con éxito!',
          formulaSituacaoPedagogica: 'Fórmula de situación pedagógica',
          exemploDeFormula: 'Fórmula de ejemplo:',
          comPeso: 'Con peso',
          semPeso: 'Sin peso',
          replicarFormulaParaTodosPeriodos: 'Replica la fórmula para todos los períodos.',

          periodos: {
            novo: 'Nuevo período',
            editar: 'Período de edición',
            cadastradoSucesso: '¡Periodo registrado correctamente!',
            atualizadoSucesso: '¡Periodo actualizado con éxito!',
            removidoSucesso: '¡Período eliminado con éxito!'
          }
        }
      },

      componentesCurriculares: {
        anosFaixas: {
          titulo: 'Años / Pistas',
          novo: 'Año Nuevo / Faja',
          cadastradoSucesso: 'Año / ¡Seguimiento registrado exitosamente!',
          atualizadoSucesso: 'Año / ¡Seguimiento actualizado con éxito!',
          removidoSucesso: 'Año / ¡Seguimiento eliminado con éxito!'
        },

        series: {
          titulo: 'Series',
          novo: 'Nueva serie',
          cadastradoSucesso: '¡Serie añadida con éxito!',
          atualizadoSucesso: '¡Serie actualizada con éxito!',
          removidoSucesso: '¡Serie eliminada con éxito!'
        },

        disciplinas: {
          titulo: 'Asignaturas',
          novo: 'Nueva disciplina',
          cadastradoSucesso: 'Asunto registrado con éxito!',
          atualizadoSucesso: 'Asunto actualizado correctamente!',
          removidoSucesso: '¡Disciplina eliminada con éxito!'
        }
      },

      gradesCurriculares: {
        titulo: 'Cuadrículas del plan de estudios',
        novo: 'Nueva cuadrícula del plan de estudios',
        cadastradoSucesso: '¡La cuadrícula del plan de estudios se registró con éxito!',
        atualizadoSucesso: '¡La cuadrícula del plan de estudios se actualizó correctamente!',
        removidoSucesso: '¡La cuadrícula del plan de estudios se eliminó correctamente!',
        turmaNaoPossuiSerieVinculada: 'La clase no tiene series vinculadas',

        disciplinas: {
          titulo: 'Asignaturas',
          novo: 'Nueva disciplina',
          cadastradoSucesso: 'Asunto registrado con éxito!',
          atualizadoSucesso: 'Asunto actualizado correctamente!',
          removidoSucesso: '¡Disciplina eliminada con éxito!',

          aula: {
            novo: 'Nueva clase',
            cadastradoSucesso: '¡Clase registrada con éxito!',
            atualizadoSucesso: '¡Clase actualizada correctamente!',
            removidoSucesso: '¡Clase eliminada con éxito!',
            numeroDaAula: 'Numero de clase',
            dataDaAula: 'Fecha de clase',

            conteudo: {
              novo: 'Nuevo contenido',
              cadastradoSucesso: 'Contenido registrado correctamente!',
              atualizadoSucesso: '¡Contenido actualizado correctamente!',
              removidoSucesso: 'Contenido eliminado correctamente!',
              selecioneAvaliacaoTemplateParcial: 'Seleccione una evaluación parcial',
              selecioneAvaliacaoTemplateFinal: 'Seleccione una evaluación final',
              avaliacoesTemplateParcial: 'Evaluaciones parciales',
              avaliacoesTemplateFinal: 'Evaluaciones finales',
              avaliacoesDoConteudo: 'Reseñas de contenido',
              avaliacaoRemovidaComSucesso: 'Evaluación eliminada correctamente',
              avaliacaoAdicionadaComSucesso: 'Calificación agregada con éxito'
            },

            recurso: {
              novo: 'Nueva caracteristica',
              cadastradoSucesso: '¡Recurso registrado correctamente!',
              atualizadoSucesso: '¡Característica actualizada con éxito!',
              removidoSucesso: '¡Recurso eliminado con éxito!'
            },

            habilidade: {
              cadastradoSucesso: '¡Habilidad registrada con éxito!',
              removidoSucesso: '¡Habilidad eliminada con éxito!'
            }
          },

          metodologias: {
            tecnicasEnsino: {
              titulo: 'Técnicas de enseñanza ',
              novo: 'Nueva técnica de enseñanza',
              cadastradoSucesso: '¡Técnica de enseñanza registrada con éxito!',
              atualizadoSucesso: '¡Técnica de enseñanza actualizada con éxito!',
              removidoSucesso: '¡Técnica de enseñanza eliminada con éxito!'
            },

            recursosDidaticos: {
              titulo: 'Recursos didácticos',
              novo: 'Nuevo recurso didáctico',
              cadastradoSucesso: '¡Recurso didáctico registrado con éxito!',
              atualizadoSucesso: '¡Recurso didáctico actualizado con éxito!',
              removidoSucesso: '¡Recurso didáctico eliminado con éxito!'
            },

            criteriosAvaliacao: {
              titulo: 'Criterios de evaluación',
              novo: 'Nuevos criterios de evaluación',
              cadastradoSucesso: 'Criterios de evaluación registrados con éxito!',
              atualizadoSucesso: '¡Criterios de evaluación actualizados con éxito!',
              removidoSucesso: '¡Criterios de evaluación eliminados correctamente!'
            },

            atividadesPraticas: {
              titulo: 'Actividades practicas',
              novo: 'Nueva actividad práctica',
              cadastradoSucesso: 'Actividad práctica registrada con éxito!',
              atualizadoSucesso: '¡Actividad de práctica actualizada con éxito!',
              removidoSucesso: '¡Actividad de práctica eliminada con éxito!'
            },

            bibliografiaBasica: {
              titulo: 'Bibliografía básica',
              novo: 'Nueva bibliografía básica',
              cadastradoSucesso: '¡Bibliografía básica registrada con éxito!',
              atualizadoSucesso: '¡Bibliografía básica actualizada con éxito!',
              removidoSucesso: '¡Bibliografía básica eliminada con éxito!'
            },

            bibliografiaComplementar: {
              titulo: 'Bibliografía complementaria',
              novo: 'Nueva bibliografía complementaria',
              cadastradoSucesso: '¡Bibliografía complementaria registrada con éxito!',
              atualizadoSucesso: '¡Bibliografía complementaria actualizada con éxito!',
              removidoSucesso: '¡Bibliografía complementaria eliminada con éxito!'
            }
          },

          planosDeEnsino: {
            cadastradoSucesso: '¡Plan de enseñanza registrado con éxito!',
            atualizadoSucesso: '¡Plan de enseñanza actualizado con éxito!',
            objetivosGerais: 'Objetivos generales',
            bibliografia: {
              novo: 'Nueva bibliografía',
              editar: 'Nueva bibliografía',
              cadastradoSucesso: 'Bibliografía registrada con éxito!',
              atualizadoSucesso: '¡Bibliografía actualizada con éxito!',
              removidoSucesso: '¡Bibliografía eliminada con éxito!',
              novaBibliografia: 'Nueva bibliografía',
              tipoBibliografia: 'Tipo de bibliografía'
            },
            conteudoProgramatico: {
              novo: 'Nuevo plan de estudios',
              editar: 'Editar plan de estudios',
              cadastradoSucesso: '¡Plan de estudios registrado con éxito!',
              atualizadoSucesso: '¡Contenido actualizado correctamente!',
              removidoSucesso: 'Contenido eliminado correctamente.',
              titulo: 'Contenido del programa',
              validacaoPeriodoSelecionado: 'Debes seleccionar un período',
              periodoIncluido: 'Período ya agregado',
              adicionarPeriodo: 'Agregar período'
            },
            ementa: {
              novo: 'Nuevo menu',
              editar: 'Menú de edición',
              cadastradoSuceeso: 'Menú registrado correctamente!',
              atualizadoSucesso: '¡Menú actualizado con éxito!',
              removidoSucesso: '¡Menú eliminado correctamente!',
              titulo: 'menú'
            },
            formaAvaliacao: {
              novo: 'Nueva forma de evaluación',
              editar: 'Editar forma de evaluación',
              cadastradoSucesso: 'Formulario de evaluación registrado correctamente!',
              atualizadoSucesso: '¡Formulario de evaluación actualizado con éxito!',
              removidoSucesso: '¡Formulario de evaluación eliminado correctamente!',
              titulo: 'Formas de valoración'
            }
          },

          suplementar: {
            comentarios: {
              titulo: 'Comentarios',
              novo: 'Nuevo comentario',
              cadastradoSucesso: 'Comentario registrado correctamente!',
              atualizadoSucesso: 'Comentario actualizado con éxito!',
              removidoSucesso: '¡Comentario eliminado con éxito!',
              adicionar: 'Añadir comentarios'
            },

            possibilidadesParaOCurriculo: {
              titulo: 'Posibilidades para el plan de estudios',
              novo: 'Nueva posibilidad',
              cadastradoSucesso: '¡Posibilidad registrada con éxito!',
              atualizadoSucesso: '¡Posibilidad actualizada con éxito!',
              removidoSucesso: '¡Posibilidad eliminada con éxito!',
              adicionar: 'Agrega posibilidades'
            }
          },

          configuracoes: {
            camposDeAtuacao: {
              titulo: 'Campos de acción',
              nome: 'Campo de actuación',
              novo: 'Nuevo campo de acción',
              cadastradoSucesso: 'Campo de acción registrado con éxito!',
              atualizadoSucesso: '¡Campo de acción actualizado con éxito!',
              removidoSucesso: '¡Campo de juego eliminado con éxito!',
              adicionar: 'Agregar campos de actividad'
            },

            objetosDoConhecimento: {
              titulo: 'Objetos de conocimientoS',
              nome: 'Objeto de conocimiento',
              novo: 'Nuevo objeto de conocimiento',
              cadastradoSucesso: '¡Objeto de conocimiento registrado con éxito!',
              atualizadoSucesso: '¡Objeto de conocimiento actualizado con éxito!',
              removidoSucesso: '¡Objeto de conocimiento eliminado con éxito!',
              adicionar: 'Agregar objetos de conocimiento'
            },

            praticasDeLinguagem: {
              titulo: 'Prácticas de lenguaje',
              nome: 'Practica de lenguaje',
              novo: 'Práctica de nuevos idiomas',
              cadastradoSucesso: '¡Práctica de idiomas registrada con éxito!',
              atualizadoSucesso: 'Práctica de idiomas actualizada con éxito!',
              removidoSucesso: '¡Práctica del idioma eliminada con éxito!',
              adicionar: 'Agregar prácticas lingüísticas'
            },

            unidadesTematicas: {
              titulo: 'Unidades Temáticas',
              nome: 'Unidad temática',
              novo: 'Nueva unidad temática',
              cadastradoSucesso: 'Unidad temática registrada con éxito!',
              atualizadoSucesso: 'Unidad temática actualizada correctamente!',
              removidoSucesso: '¡Unidad temática eliminada con éxito!',
              adicionar: 'Agregar unidades temáticas'
            }
          }
        }
      },

      areasDeConhecimento: {
        titulo: 'Áreas de conocimiento',
        novo: 'Nueva área de conocimiento',
        cadastradoSucesso: 'Área de conocimiento registrada con éxito!',
        atualizadoSucesso: '¡Área de conocimiento actualizada con éxito!',
        removidoSucesso: '¡Área de conocimiento eliminada con éxito!'
      },

      competenciasEspecificas: {
        titulo: 'Habilidades especificas',
        novo: 'Nueva competencia específica',
        cadastradoSucesso: '¡Competencia específica registrada con éxito!',
        atualizadoSucesso: '¡Competencia específica actualizada con éxito!',
        removidoSucesso: '¡Competencia específica eliminada con éxito!'
      },

      competenciasGerais: {
        titulo: 'Habilidades generales',
        novo: 'Nueva competencia general',
        cadastradoSucesso: '¡Competencia general registrada con éxito!',
        atualizadoSucesso: '¡Competencia general actualizada con éxito!',
        removidoSucesso: '¡Competencia general eliminada con éxito!'
      },

      gruposDeAvaliacao: {
        titulo: 'Grupos de evaluación',
        novo: 'Nuevo grupo de evaluación',
        cadastradoSucesso: '¡Grupo de evaluación registrado correctamente!',
        atualizadoSucesso: '¡El grupo de evaluación se actualizó correctamente!',
        removidoSucesso: '¡El grupo de evaluación se eliminó correctamente!',
        adicionar: 'Agregar grupos de evaluación'
      },

      cursos: {
        titulo: 'Cursos',
        novo: 'Nuevo curso',
        cadastradoSucesso: '¡Curso registrado con éxito!',
        atualizadoSucesso: '¡Curso actualizado con éxito!',
        removidoSucesso: '¡Curso eliminado correctamente!'
      },

      modalidadesDeTurma: {
        titulo: 'Modalidades de clase',
        novo: 'Nueva modalidad de clase',
        cadastradoSucesso: '¡Modo de clase registrado con éxito!',
        atualizadoSucesso: '¡Modalidad de clase actualizada con éxito!',
        removidoSucesso: '¡El modo de clase se eliminó con éxito!'
      },

      tiposDeSala: {
        titulo: 'Tipos de sala / ambiente',
        novo: 'Nuevo tipo de habitación',
        cadastradoSucesso: '¡Tipo de habitación registrado correctamente!',
        atualizadoSucesso: '¡Tipo de habitación actualizado correctamente!',
        removidoSucesso: '¡El tipo de habitación se eliminó correctamente!'
      },

      tiposDeDisciplina: {
        titulo: 'Tipos de disciplina',
        novo: 'Nuevo tipo de disciplina',
        cadastradoSucesso: '¡Tipo de asunto registrado correctamente!',
        atualizadoSucesso: '¡El tipo de asunto se actualizó correctamente!',
        removidoSucesso: '¡El tipo de disciplina se eliminó correctamente!'
      },

      sistemasDeEnsino: {
        titulo: 'sistemas educativos',
        novo: 'Nuevo sistema educativo',
        cadastradoSucesso: '¡Sistema educativo registrado con éxito!',
        atualizadoSucesso: '¡Sistema educativo actualizado con éxito!',
        removidoSucesso: 'Sistema de enseñanza eliminado con éxito!'
      },

      situacoesPedagogicas: {
        titulo: 'Situaciones pedagógicas',
        novo: 'Nueva situación docente',
        cadastradoSucesso: 'Situación pedagógica registrada con éxito!',
        atualizadoSucesso: 'Situación pedagógica registrada con éxito!',
        removidoSucesso: '¡Situación pedagógica eliminada con éxito!'
      },

      turnos: {
        titulo: 'Turnos',
        novo: 'Nuevo turno',
        cadastradoSucesso: '¡Cambio registrado correctamente!',
        atualizadoSucesso: '¡Turno actualizado con éxito!',
        removidoSucesso: '¡Cambio eliminado con éxito!'
      },

      salas: {
        titulo: 'Habitaciones / ambientes',
        novo: 'Nuevo cuarto / ambiente',
        cadastradoSucesso: 'Habitaciones / medio ambiente registrado con éxito!',
        atualizadoSucesso: 'Habitaciones / entorno actualizado con éxito!',
        removidoSucesso: 'Habitaciones / medio ambiente eliminado con éxito!',
        equipamentos: {
          titulo: 'Equipamientos',
          selecionar: 'Seleccionar equipo',
          novo: 'Nuevo equipamiento',
          editar: 'Editar equipo',
          cadastradoSucesso: '¡Equipo registrado exitosamente!',
          atualizadoSucesso: '¡Equipo actualizado con éxito!',
          removidoSucesso: '¡Equipo eliminado con éxito!'
        }
      },

      horariosAula: {
        titulo: 'Horarios de clases',
        horarioDasAulas: 'Horarios de clases',
        novo: 'Nuevo horario de clases',
        editar: 'Editar horario de clases',
        cadastradoSucesso: '¡Horario de clases registrado correctamente! ',
        atualizadoSucesso: '¡El horario de clases se actualizó correctamente!',
        removidoSucesso: '¡El horario de clases se eliminó correctamente!',
        aulaAtualizadaSucesso: '¡Clase actualizada correctamente!'
      },

      modelosDiario: {
        titulo: 'Plantillas de diario',
        novo: 'Nueva plantilla de diario',
        diarioDeClasse: 'Diario de clase',
        digiteTitulo: 'Ingrese el nombre del modelo aquí',
        default: 'Plantilla de diario sin nombre',
        adicionarRotina: 'Añadir rutina',
        nomeColuna: 'Nombre de la columna',
        colunaDefault: 'Columna sin nombre',
        desejaCriarCopia: '¿Realmente desea crear una copia de esta plantilla?',
        escolherIcone: 'Elegir icono',
        modeloPadrao: 'Plantilla predeterminada',
        dialogFeedback: '¿Realmente desea cambiar la plantilla del diario de clase?',
        relacioneModeloEm: 'Relacione una plantilla de diario con una serie en ',
        modeloDeDiarioSendoReplicadosAteOFinalDoAnoLetivo:
          'El nuevo modelo elegido se replicará para todos los días escolares no ocupados de la clase, esta operación puede tardar unos segundos',
        preferenciasModeloDiario: 'Preferencias Pedagógicas / Plantillas de Diario',
        automatizarFrequenciaFeedback:
          'Al lanzar una rutina, se actualizará la asistencia del alumno en todas las materias de esta clase. ¿Desea continuar?',

        configAtualidazaSucesso: '¡Configuración actualizada con éxito!',
        cadastradoSucesso: '¡Modelo registrado con éxito!',
        atualizadoSucesso: '¡Modelo actualizado con éxito!',
        copiadoSucesso: '¡Plantilla copiada con éxito!'
      }
    },

    turmas: {
      titulo: 'Clases',
      novo: 'Nueva clase',
      cadastradoSucesso: '¡Clase registrada con éxito!',
      atualizadoSucesso: '¡Clase actualizada con éxito!',
      removidoSucesso: '¡Clase eliminada con éxito!',
      dados: 'Datos de clase',
      nomeTurma: 'Nombre de la clase',
      ordemSigla: 'Pedido / Iniciales',
      tagsTurma: 'Etiquetas de clase',
      removerTurma: 'Quitar clase',
      alterarGrade: 'Al cambiar el plan de estudios, se perderá toda la información registrada anteriormente.',
      turmasCanceladas: 'Clases canceladas',
      turmasEmAndamento: 'Clases en curso',
      turmasEmFormacao: 'Clases en formación',
      turmasEncerradas: 'Clases cerradas',
      disciplinaDaTurma: 'Tema de clase',
      alterarSerieRotinaLancada:
        'Al cambiar de serie, se perderá el currículum y todos los registros de lanzamiento de rutina',
      alterarSerieGradeCurricularLancada:
        'Al cambiar la calificación se perderán todos los registros de la grilla del currículo',
      alterarSistemaDeAvaliacaoFundamental:
        'Al cambiar el sistema de evaluación se cambiará toda la información previamente registrada',
      alterarGradeESistemaDeAvaliacaoFundamental:
        'Al cambiar el plan de estudios y el sistema de evaluación, se cambiará toda la información registrada previamente',
      gradePossuiOutroSistema:
        'El plan de estudios seleccionado tiene otro sistema de evaluación registrado, al confirmar se cambiará toda la información previamente registrada.',
      manterSistemaDeAvaliacaoAtual: 'Mantener el sistema de evaluación actual',
      alterarSistemaDeAvaliação: 'Sistema de evaluación de cambios',
      aulasJaCadastradas: 'Clases ya registradas',
      confirmaAlteracaoConfigHorarios:
        '¡Atención! El cambio se reflejará directamente en las clases ya publicadas. ¿Desea continuar?',
      confirmaAlteracaoConfigHorariosAnoLetivo: 'Se modificarán todos los registros incluidos en el diario de clase.',
      confirmaAlteracaoConfigHorariosDatasPosteriores: 'Se cambiarán todos los registros de la fecha actual.',
      confirmaRemocaoConfigHorarios:
        '¡Atención! La eliminación se reflejará directamente en las clases ya publicadas. ¿Desea continuar?',
      confirmaRemocaoConfigHorariosAnoLetivo: 'Se eliminarán todas las entradas incluidas en el diario de clase.',
      confirmaRemocaoConfigHorariosDatasPosteriores: 'Se eliminarán todos los registros de la fecha actual.',
      NaoEPossivelAlterarConfigHorario: 'Atención: No es posible editar debido al cambio de configuración de tiempo.',
      jaExisteConfiguracaoParaTurma: 'Atención, ya existe una configuración de horario registrada para la clase.',
      quantidadeMinimaMenorQue: 'La cantidad mínima debe ser inferior a la capacidad máxima',

      situacoesTurma: {
        emAndamento: 'En proceso',
        emFormacao: 'Capacitación',
        cancelada: 'Cancelado',
        encerrada: 'Cerrado'
      },

      equipePedagogica: {
        novo: 'Nuevo miembro',
        atualizadoSucesso: 'Miembro actualizado con éxito',
        cadastradoSucesso: '¡Miembro registrado correctamente!',
        removidoSucesso: '¡Miembro eliminado correctamente!',
        titulo: 'Equipo Pedagógico',
        editar: 'Editar miembro'
      },

      integrantes: {
        titulo: 'Miembros',
        novo: 'Nuevo miembro',
        adicionar: 'Añadir miembro',
        jaCadastrado: 'Miembro ya agregado',
        cadastradoSucesso: '¡Miembro registrado correctamente!',
        atualizadoSucesso: '¡Miembro actualizado correctamente!',
        removidoSucesso: '¡Miembro eliminado correctamente!'
      },

      horarios: {
        titulo: 'Hoja de horas',
        tipo: 'Tipo de tiempo',
        imprimirQuadroDeHorarios: 'Imprimir hoja de horas',
        cadastradoSucesso: '¡Horario de clases registrado correctamente!',
        atualizadoSucesso: '¡El horario de clases se actualizó correctamente!',
        removidoSucesso: '¡El horario de clases se eliminó correctamente!',
        aulaRemovidaSucesso: '¡Clase eliminada con éxito!',
        aulaAtualizadaSucesso: '¡Clase actualizada correctamente!',
        tipoDeFalta: 'Tipo de ausencia',
        tipoDeFaltaPorDia: 'Por dia',
        tipoDeFaltaPorDisciplina: 'Por disciplina',
        integrantes: 'Miembros',
        atualizarRecorrente: '¿Replicar los cambios para las clases posteriores?',
        removerRecorrente: '¿También eliminar las siguientes clases?',
        gerarRecorrente: 'Clase recurrente',
        aulaRecorrente: 'Clase recurrente',
        aulaUnica: 'Clase única',
        editarAula: 'Editar clase',
        aulaTemConteudo: 'Si cambia el curso, todo el contenido ya publicado se eliminará',
        removerDisciplinaComConteudoLancado:
          'Si elimina el curso, todo el contenido publicado anteriormente se eliminará',
        todosRegistrosPosteriores: 'Actualizar todos los registros posteriores',
        aulaSelecionada: 'Clases seleccionadas',
        selecionarDias: 'Seleccionar días',
        seusHorariosAula: 'Tus horarios de clases',
        horariosTurma: 'Horarios de clases',
        mapaHorarios: 'Mapa de horarios',
        tipoMapaHorarios: 'Tipo de mapa de horarios',
        opcoesEdicao: 'Opciones de edición',
        atualizarTodosRegistrosPosteriores: 'Actualizar todos los registros posteriores',
        calendarioAulas: 'Horario de clases',
        cadastrarAula: 'Clase de registro',
        selecionarOutro: 'Seleccionar otro',
        selecionarUsuario: 'Seleccionar usuario',
        confirmarAula: 'Confirmar clase',
        pesquisarPorAluno: 'Buscar por alumno',
        marcarPresenca: 'Marcar presencia',
        detalhesPresenca: 'Detalles de asistencia',
        xAulasHoje: '{{value}} clases para hoy',
        justificarFalta: 'Justificar la ausencia',
        justificarTodasFaltas: 'Justificar todas las ausencias',
        faltaJustificada: 'Ausencia justificada',
        descrevaJustificativa: 'Describe la justificación',
        removerJustificativa: 'Eliminar justificación',
        presencaParcial: 'Presencia parcial',
        contemXAulasSemPresenca: 'Contiene {{value}} clase(s) sin presencia',
        contemXAulasFaltaJustificada: 'Contiene {{value}} ausencia(s) justificada(s)',
        registrarComportamento: 'Comportamiento de registro',
        notasAvaliacoes: 'Calificaciones y calificaciones',
        horariosAluno: 'Horas de estudiante',
        gerarMapa: 'Generar mapa',
        selecionarTurma: 'Seleccionar clase',
        selecionarProfessor: 'Seleccionar profesor',
        aulaJaAplicadaParaQuadro: 'Clase ya solicitada para este foro',
        aulaJaAplicadaParaQuadroDatasPosteriores: 'Clase ya solicitada para este foro en fechas posteriores'
      },

      diariosAula: {
        aulaNaoPossuiIntegrantes: '¡Esta clase no tiene miembros!',
        titulo: 'Diarios de clase',
        novo: 'Nuevo diario de clase',
        cadastradoSucesso: '¡Diario de clase registrado con éxito!',
        atualizadoSucesso: '¡Diario de clase actualizado con éxito!',
        removidoSucesso: '¡Diario de clase eliminado con éxito!',
        alunoNaoParticipaDestaDisciplina: 'El estudiante no participa en esta disciplina',
        controleFrequencia: 'Control de frecuencia',
        pesquisarAlunos: 'Buscar estudiantes',
        alunoAtualizadoComSucesso: 'Estudiante actualizado correctamente',
        problemaAtualizarAluno: 'Estudiante de actualización de problemas',
        conteudoPendente: 'Contenido pendiente',
        conteudoLancado: 'Contenido publicado',
        aulaConfirmada: 'Clase confirmada',
        presenca: 'Presencia',
        presencaMedianteReposicao: 'Presencia en sustitución',
        presencaJustificada: 'Presencia justificada',
        justificativa: 'Justificación',
        visualizar: 'Para ver',
        falta: 'Ausencia',
        nomeDisciplina: 'Nombre del tema',
        acoesDiarioAula: 'Acciones del diario de clase',
        aulaNaoLancada: 'Clase no lanzada',
        atualizarFrequencia: 'Actualizar frecuencias',
        atualizarConteudo: 'Actualizar contenido',
        naoTemAulasNessePeriodo: 'No hay clases durante este período.',
        semConfiguracaoHorarioAula: 'No hay configuración de horario de clases registrada en este calendario.',
        relacioneUma: ' relacionar uno ',
        configuracaoHorarioAula: 'configuración de tiempo de clase',
        paraHabilitarDiario: 'Para habilitar el diario,',
        aulaInfantil: 'Clase infantil',
        rotinaLancadaSucesso: '¡Rutina infantil lanzada con éxito!',
        frequenciaFaltas: 'Asistencia y ausencias',
        automatizarFrequencia: 'Automatizar frecuencia de clase',
        automatizarFrequenciaEConfirmarAula: 'Automatice la asistencia y confirme la clase',
        cadastrarAulaDiaOuAnoLetivo: '¿Quieres agregar solo para el día seleccionado o hasta el final del año escolar?',
        removerAulaDiaOuAnoLetivo: '¿Quieres eliminar solo por el día seleccionado o hasta el final del año escolar?',
        limiteAulasSemanalExcedido:
          'Ya alcanzó la carga de trabajo semanal configurada para el curso. ¿Aún quieres registrar una nueva clase?',
        limiteAulasTotalExcedido:
          'Ya alcanzó la carga de trabajo total configurada para el curso. ¿Aún quieres registrar una nueva clase?',

        conteudos: {
          titulo: 'Control de contenido',
          cadastradoSucesso: '¡Contenido de la clase registrado con éxito!',
          atualizadoSucesso: '¡El contenido de la lección se actualizó correctamente!'
        }
      },

      notas: {
        titulo: 'Los grados',
        cadastradoSucesso: 'Nota registrada correctamente!',
        atualizadoSucesso: 'Nota actualizada correctamente.',
        removidoSucesso: 'Nota eliminada con éxito!',
        atualizadoErro: '¡Error al actualizar la nota!',
        selecioneUmaDisciplina: 'Seleccione una disciplina',
        colunas: 'columnas',
        filtroDeNotas: 'Filtro de notas',
        notaInicial: 'Nota de apertura',
        notaFinal: 'Nota final',
        relacioneUm: 'Lista uno ',
        naAba: ' en la pestaña ',
        paraHabilitarLancamentoNota: ' para habilitar la publicación de notas',
        daDisciplinaNa: ' en la disciplina en ',
        ao: ' en ',
        relacioneAlunosADisciplina: 'Vincule a los estudiantes a la disciplina en lo ',
        exclusaoDeNotas: 'Borrado de notas',
        excluirNotasDoAluno: 'Eliminar notas de estudiantes',
        excluirNotasAvaliacaoAluno:
          '¿Realmente desea eliminar todas las notas que se refieren a {{nomeAvaliacao}} del estudiante {{nomeAluno}}?'
      },

      pareceres: {
        aprovadoCoord: 'Aprobado Coord.',
        aprovadoCoordenacao: 'Aprobado por la coordinación',
        assinadoResp: 'Firmado Resp.',
        imprimir: 'Imprimir opiniones',
        aprovar: 'Aprobar opiniones pendientes',
        exibirApp: 'Mostrar en la app',
        confirmarTodos: '¿De verdad quieres aprobar todas las opiniones?',
        ocultarApp: 'Ocultar de la app',
        adicionarObservacao: 'Añadir la nota',
        relacioneIntegrantes: ' enumerar nuevos miembros en la pestaña ',
        relacioneUm: ' Lista uno ',
        relacioneUma: ' y lista una ',
        naAba: ' en la pestaña ',
        paraHabilitarPareceresDescritivos: 'Para habilitar opiniones descriptivas',
        paraIncluirAlunosTurma: 'Para agregar estudiantes a la clase,',
        casoDesejeCadastrar:
          'Si desea dar de alta un sistema de evaluación, acceda a (Pedagógico/ Preferencias Pedagógicas/ Sistemas de evaluación)',
        feedbacks: {
          aprovadosSucesso: '¡Opiniones aprobadas con éxito!',
          assinadoSucesso: '¡Opinión aprobadas con éxito!',
          observacaoSalvaSucesso: 'Nota añadida con éxito',
          observacaoRemovidaSucesso: 'Nota eliminada con éxito',
          vinculadoSucesso: '¡Opinión vinculada con éxito!'
        }
      }
    }
  },

  agenda: {
    apresentarEventoNoApp: 'Mostrar el evento en la aplicación',
    solicitarConfirmacaoResponsavel: 'Pedir confirmación al responsable',
    detalhesConvite: 'Detalles de la invitación',
    responsaveisNotificados: 'Responsables notificados',
    responsaveisConfirmados: 'Responsables confirmados',
    responsaveisNegados: 'Responsables negados',
    nomeResponsavel: 'Nombre de la persona responsable',
    pendentesConfirmacao: 'Pendiente de confirmación',
    convitesNegados: 'Invitaciones denegadas',
    convitesAceitos: 'Invitaciones aceptadas',
    detalhesConvidados: 'Detalles de los invitados',
    desejaCancelarEvento: '¿Realmente quieres cancelar el evento?',
    notificarResponsaveis: '¿Notificar a los responsables de la APP?',
    canceladoSucesso: '¡Evento cancelado con éxito!',
    semConvitesNegados: 'No se niega ninguna invitación.',
    semConvitesPendentes: 'No hay invitaciones pendientes.',
    semConvitesConfirmados: 'No hay invitaciones confirmados.',
    eventoCancelado: 'Evento cancelado',
    eventoReplicado: 'Evento replicado',
    notificarResponsaveisDasAlteracoes: '¿Notificar a los responsables de la APP de los cambios?',

    preferencias: {
      tempoDuracaoAtividades: {
        titulo: 'Tiempo de duración de la clase',
        novo: 'Nuevo tiempo',
        cadastradoSucesso: '¡Hora registrada correctamente!',
        atualizadoSucesso: '¡El tiempo actualizado con éxito!',
        removidoSucesso: '¡Hora eliminada con éxito!'
      }
    }
  },

  dashboard: {
    categorias: {
      ultimos12Meses: 'Últimos 12 meses',
      ultimoMes: 'El mes pasado',
      ultimaSemana: 'Ultima semana',
      ultimoDia: 'Último día',
      semContratosVigentes: 'Sin contratos vigentes en los últimos 12 meses',
      contratosVigentes: 'Contratos vigentes (últimos 12 meses)'
    },
    resumoFinanceiro: {
      hoje: 'Resumen financiero diario'
    },
    headerApp: {
      alunosQueAcessaramAppUltimaSemana: 'Estudiantes que accedieron a la app en los últimos 7 días',
      responsaveisQueAcessaramAppUltimaSemana: 'Padres que accedieron a la app en los últimos 7 días',
      alunosSincronizados: 'Estudiantes sincronizados',
      responsaveisSincronizados: 'Padres sincronizados',
      turmasSincronizadas: 'Clases Sincronizadas'
    }
  },

  app: {
    titulo: 'Aplicación',
    liberarAcesso: 'Permitir el acceso a la aplicación',
    gerenciarAcessos: 'Administrar el acceso a la aplicación',
    reenviarEmailAcesso: 'Reenviar correo electrónico de acceso a la aplicación',
    reenviarEmailBaixarApp: 'Reenviar correo electrónico para descargar la aplicación',
    emailsEnviadoSucesso: 'Correos electrónicos enviados con éxito',

    feedbacks: {
      pendenciaInformacoes: 'Información pendiente',
      cliqueParaResolver: 'Haga clic para resolver'
    }
  },

  portalDoAluno: {
    titulo: 'Portal estudiantil',
    liberarAcesso: 'Otorgar acceso al Portal del Estudiante',
    reenviarEmailAcesso: 'Reenviar correo electrónico de acceso al portal'
  },

  catalago: {
    estoque: {
      titulo: 'Stock',
      localEstoque: 'Ubicación Stock',
      estoqueMinimo: 'Stock mínimo',
      estoqueMaximo: 'Stock máximo'
    }
  }
}
